import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import $ from "jquery";
import IconLogoMenewCircle from "./Icons/icon-logo-menew-circle";
import {helpers} from "./helpers";
import ReloadControll from "./reload-controll";

const EstabelecimentoIndisponivel = () => {
    const store = useSelector(store => store);

    useEffect(() => {
        const mask = $('.mask').data('mask');
        $('.mask').mask(mask);
    }, []);

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    return (
        <div className="w-100 h-100 d-flex flex-column">
            <div className="p-4 h-25 d-flex align-items-start justify-content-end">
                <IconLogoMenewCircle fill={'#ff1200'} width={65}/>
            </div>
            <div className="p-2 d-flex flex-column align-items-center justify-content-center">
                <div className="mt-2 mr-1 mb-2 ml-2 elevation-3" style={{
                    width: "195px",
                    height: "195px",
                    backgroundImage: !!store.app.services.estabelecimento.logomarca
                        ? `url(${store.app.services.estabelecimento.logomarca})`
                        : 'url(/images/sem-imagem.png)',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#fff',
                    borderRadius: "10px",
                }}/>

                <div className="p-4 d-flex align-items-center justify-content-center">
                    <span className="text-bold">{store.app.services.estabelecimento.nome_fantasia}</span>
                </div>

                <div className="p-4 d-flex flex-column justify-content-center">
                    <span>
                        O estabelecimento não está disponível
                    </span>
                    <span>no momento. Você pode entrar em contato</span>
                    <span>
                        {`conosco no telefone `}
                        {
                            store.app.services.estabelecimento.telefone < 11
                                ? <span className="text-bold mask"
                                        data-mask="(99) 9999-9999">{store.app.services.estabelecimento.telefone}</span>
                                : <span className="text-bold mask"
                                        data-mask="(99) 9 9999-9999">{store.app.services.estabelecimento.telefone}</span>
                        }

                    </span>
                </div>
            </div>
        </div>
    );
};

export default EstabelecimentoIndisponivel;
