import styled from "styled-components";

export const Button = styled.button(props => ({
    "color": props.color ? props.color : "#fff",
    "background-color": props.backgroundColor ? props.backgroundColor : "#28a745",
    "border-color": props.borderColor ? props.borderColor : "#28a745"
}));

export const DivCrousel = styled.div(props => ({
    background: "linear-gradient(90deg,rgba(0,0,0,0.3), rgba(0,0,0,0) 40%, rgba(0,0,0,0) 40%,rgba(0,0,0, 0.3))",
    "background-color": props.color ? props.color : "#A80000"
}));

export const DivNavBar = styled.div(props => ({
    "background-color": props.color ? props.color : "#A80000"
}));

export const DivNomeEstabelecimento = styled.div(props => ({
    "left": props.left ? props.left : 40,
    "right": props.right ? props.right : 60,
    "overflow": props.overflow ? props.overflow : "hidden",
    "height": props.height ? props.height : 40
}));

export const DivFooterSection1 = styled.div(props => ({
    "background-color": props.color ? props.color : "#A80000"
}));

export const DivScroll = styled.div(props => ({
    "overflow": "auto !important",
    "position": "absolute",
    "right": props.right ? props.right : 0,
    "left": props.left ? props.left : 0,
    "bottom": props.bottom ? props.bottom : 0,
    "top":props.top ? props.top : 0,
}));
