import React, {useEffect, useState} from 'react';
import {helpers} from "../../components/helpers";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

/*Layout*/
import Header from '../../components/layout/header/'
import Footer from '../../components/layout/footer/'

/*Components*/
import CarrouselCategorias from './carousel-categorias';

/*Icons*/
import IconArrowRight from "../../components/Icons/icon-arrow-right";

const Produtos = (props) => {
    const store = useSelector(store => store);
    const history = useHistory();
    const dispatch = useDispatch();
    const {idestabelecimento} = useParams();
    const [state, setState] = useState({data: []});

    useEffect(() => {
        const produtosCategorias = helpers.collection().cloneDeep(store.app.services.produtosCategorias);
        dispatch({type: 'PRODUTOS_DATA', data: produtosCategorias});
    }, [dispatch, store.app.services.produtosCategorias, store.app.modules.carrinho.pedidos.produtosIndividual]);

    useEffect(() => {
        if (!helpers.collection().isEmpty(store.app.modules.produtos.data)) {
            let data = helpers.collection().filter(store.app.modules.produtos.data, {'categoriaCodigo': store.app.modules.produtos.categoriaSelected});
            data = helpers.collection().first(data);
            if (helpers.collection().isEmpty(data)) {
                data = helpers.collection().first(store.app.modules.produtos.data);
            }
            setState(state => ({
                ...state,
                data: helpers.dot.get(data, 'produtos')
            }));
        }
    }, [store.app.modules.produtos.data, store.app.modules.produtos.categoriaSelected]);

    const produtoSelected = (produto) => {
        if (produto.temKit === 'S' || produto.temPizza === 'S' || (produto.pedeComplemento === 'S' && !!produto.cComplemento)) {
            history.push(`/${idestabelecimento}/produto-complemento`, {produto: produto});
            return false;
        }
        history.push(`/${idestabelecimento}/produto`, {produto: produto});
    };

    return (
        <div className="d-flex flex-column h-100">
            <Header title={"Produtos"}/>

            <div className="flex-shrink-0">
                <div className="p-2">
                    <CarrouselCategorias initialSlide={store.app.modules.produtos.initialSlide}/>
                </div>
                <hr className="w-100 m-auto"/>
            </div>

            <div className="p-4 flex-shrink-0 flex-grow-1 scroll position-relative">

                {
                    state.data.map(produto => (
                        <div key={produto.produtoCodigo}>
                            <div className="p-2 d-flex align-items-center justify-content-between"
                                 onClick={e => {
                                     produtoSelected(produto)
                                 }}>
                                <div className="d-flex align-items-center">
                                    <div className="p-2">
                                        <div className="elevation-3" style={{
                                            width: "95px",
                                            height: "95px",
                                            backgroundImage: !!produto.produtoFoto
                                                ? `url(https://www.portalmenew.com.br/wservice/uploads/Temp/${produto.produtoFoto})`
                                                : 'url(/images/sem-imagem.png)',
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: 'repeat',
                                            backgroundColor: '#fff',
                                            borderRadius: "10px"
                                        }}/>
                                    </div>
                                    <div className="p-2 d-flex flex-column">
                                        <div className="p-1 mb-n2 text-bold">
                                            <span>
                                                {helpers.collection().truncate(produto.produtoNome,
                                                    {length: 15, omission: ' ...'})}
                                            </span>
                                        </div>
                                        <div className="p-1 text-sm text-muted">
                                    <span>
                                        {helpers.collection().truncate(produto.produtoDescricao,
                                            {length: 60, omission: ' ...'})}
                                    </span>
                                        </div>
                                        <div className="p-1">
                                            <span style={{
                                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                            }}>
                                                {helpers.converteMoeda(produto.preco)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <IconArrowRight width={35} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                            </div>
                            <hr className="w-100"/>
                        </div>
                    ))
                }

            </div>

            <Footer/>
        </div>
    );
};

export default Produtos;
