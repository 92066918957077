import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {usePageVisibility} from 'react-browser-hooks';
import PreLoad from './components/pre-load';
import {helpers} from "./components/helpers";

/*Import Providers to Storage*/
import {IntlProvider} from "react-intl";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {store, persistor} from "./store";

/*Pages*/
import Loading from "./pages/loading";
import Home from "./pages/home";
import Produtos from "./pages/produtos";
import Produto from "./pages/produtos/produto";
import ProdutoComplemento from "./pages/produtos/produto-complemento";
import Busca from "./pages/busca";
import Pesquisa from "./pages/pesquisa";
import Sacola from "./pages/sacola";
import Checkout from "./pages/checkout";
import Pedidos from "./pages/pedidos";
import PedidosAnteriores from "./pages/pedidos/anteriores";
import PedidosDetalhes from "./pages/pedidos/detalhes";

import Login from "./pages/login";
import RecuperarSenha from "./pages/login/recuperar-senha";
import Cadastro from "./pages/cadastro";
import Perfil from "./pages/perfil";
import EditarPerfil from "./pages/perfil/editar-perfil";
import EnderecosEntrega from "./pages/perfil/enderecos-entrega";
import NovoEndereco from "./pages/perfil/novo-endereco";
import Teste from "./pages/teste";
import Cartoes from "./pages/cartoes";
import NovoCartao from './pages/cartoes/novo-cartao';

import NotFound from "./pages/404";
import Inexistente from "./pages/inexistente";
import AdmRedirect from "./pages/admRedirect";

// import Fim from "./pages/fim";
// import Categorias from "./pages/categorias";
// import Carrinho from "./pages/carrinho";
// import Montagem from "./pages/montagem";
// import Pagamento from "./pages/pagamento";

/*Import CSS or SASS*/
import './assets/js/app';
import './assets/sass/app.scss';
import CustomCss from './components/custom-css';

const App = (props) => {

    let _title = !helpers.isCardapio() ? 'Delivery Menew' : 'Cardápio Menew';

    document.title = _title;

    const visibility = usePageVisibility();

    useEffect(() => {
        document.title = visibility ? _title : '#Ei! volte!';
    }, [visibility, _title]);

    const onContextMenu = e => {
        // e.preventDefault();
        // e.stopPropagation();
        // return false
    };

    const onDoubleClick = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className="mv-toten-app v-100 h-100"
             onContextMenu={onContextMenu}
             onDoubleClick={onDoubleClick}>
            <ToastContainer
                position={"bottom-left"}
                autoClose={6000}
                draggable={true}
            />
            <IntlProvider locale={'pt-BR'} key={'pt-BR'}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <CustomCss/>
                            <PreLoad/>
                            <Switch>
                                <Route path="/adm" component={AdmRedirect}/>
                                <Route path="/adm/*" component={AdmRedirect}/>

                                <Route path="/:idestabelecimento" exact component={Loading}/>
                                <Route path="/:idestabelecimento/indoor/:mesa?" exact component={Loading}/>
                                <Route path="/:idestabelecimento/home" component={Home}/>
                                <Route path="/:idestabelecimento/produtos" component={Produtos}/>
                                <Route path="/:idestabelecimento/produto" component={Produto}/>
                                <Route path="/:idestabelecimento/produto-complemento" component={ProdutoComplemento}/>
                                <Route path="/:idestabelecimento/busca" component={Busca}/>
                                <Route path="/:idestabelecimento/pesquisa" component={Pesquisa}/>
                                <Route path="/:idestabelecimento/sacola" component={Sacola}/>
                                <Route path="/:idestabelecimento/checkout" component={Checkout}/>
                                <Route path="/:idestabelecimento/pedidos" component={Pedidos}/>
                                <Route path="/:idestabelecimento/pedidos-anteriores" component={PedidosAnteriores}/>
                                <Route path="/:idestabelecimento/pedido-detalhes" component={PedidosDetalhes}/>

                                <Route path="/:idestabelecimento/login" component={Login}/>
                                <Route path="/:idestabelecimento/password_recovery/:token" component={RecuperarSenha}/>
                                <Route path="/:idestabelecimento/cadastro" component={Cadastro}/>
                                <Route path="/:idestabelecimento/perfil" component={Perfil}/>
                                <Route path="/:idestabelecimento/cartoes" component={Cartoes} />
                                <Route path="/:idestabelecimento/novo-cartao" component={NovoCartao} />
                                <Route path="/:idestabelecimento/editar-perfil" component={EditarPerfil}/>
                                <Route path="/:idestabelecimento/enderecos-entrega" component={EnderecosEntrega}/>
                                <Route path="/:idestabelecimento/novo-endereco" component={NovoEndereco}/>
                                <Route path="/:idestabelecimento/teste" component={Teste}/>

                                <Route path="/:idestabelecimento/inexistente" component={Inexistente}/>
                                <Route path="/:idestabelecimento/*" component={NotFound}/>

                                {/*<Route path="/:idestabelecimento/fim" component={Fim}/>*/}
                                {/*<Route path="/:idestabelecimento/categorias" component={Categorias}/>*/}
                                {/*<Route path="/:idestabelecimento/carrinho" component={Carrinho}/>*/}
                                {/*<Route path="/:idestabelecimento/montagem" component={Montagem}/>*/}
                                {/*<Route path="/:idestabelecimento/pagamento" component={Pagamento}/>*/}

                            </Switch>
                        </Router>
                    </PersistGate>
                </Provider>
            </IntlProvider>
        </div>
    );
};

export default App;
