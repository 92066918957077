import React from 'react'
import brandLogos from './helpers/brandLogos'

import styles from './style.css'

const BrandLogo = ({
                       brand
                   }) => {
    if (!brandLogos[brand]) {
        return null
    }

    return (
        <img
            src={brandLogos[brand]}
            alt={brand}
            style={{width:40,height:30, margin:5}}
        />
    )
}

export default BrandLogo
