import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../../helpers";
import {useResize} from 'react-browser-hooks';
import $ from 'jquery';
import 'jquery-mask-plugin';

import Card from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss'

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './utils';

const PaymentForm = (setCard) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();

    /*
    const [state, setState] = useState({
        number: !!store.app.modules.user.card.number ? store.app.modules.user.card.number : '',
        name: !!store.app.modules.user.card.name ? store.app.modules.user.card.name : '',
        expiry: !!store.app.modules.user.card.expiry ? store.app.modules.user.card.expiry : '',
        cvc: !!store.app.modules.user.card.cvc ? store.app.modules.user.card.cvc : '',
        issuer: !!store.app.modules.user.card.issuer ? store.app.modules.user.card.issuer : '',
        focused: '',
        formData: null
    });
    */

    const [state, setState] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        formData: '',
        cpf: '',
        cartaoTerceiro: false,
        debito: false,
        credito: false,
        paymentMethod: null
    });

    const {name, number, expiry, cvc, focused, cpf} = state;
    const formRef = useRef(null);

    useEffect(() => {
        $('.use-mask').mask($('.use-mask').attr('mask'));
    }, []);

    const handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            setState({
                ...state,
                issuer
            });
        }
    };

    const handleInputFocus = ({target}) => {
        setState({
            ...state,
            focused: target.name,
        });
    };

    const handleInputChange = ({target}) => {
        $('.use-mask').mask($('.use-mask').attr('mask'));
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }

        setState({
            ...state,
            [target.name]: target.value
        });
    };

    const handleBlur = useCallback(() => {

        const formData = [...formRef.current]
            .filter(d => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});

        setState(state => ({
            ...state,
            formData
        }));

        const data = {
            "card": {
                "index": 0,
                "type": state.paymentMethod,
                "holder_name": formData.name,
                "cvc": formData.cvc,
                "exp_month": helpers.moment(formData.expiry, 'MM/YY', true).format('MM'),
                "exp_year": helpers.moment(formData.expiry, 'MM/YY', true).format('YYYY'),
                "number": formData.number.replace(/\D/g, ""),
                "cartaoTerceiro": state.cartaoTerceiro,
                "debito": state.debito,
                "credito": state.credito
            }
        };
        if (helpers.dot.get(formData, 'cpf')) {
            data.card.cpf = formData.cpf.replace(/\D/g, "");
            data.card.holder_document = formData.cpf.replace(/\D/g, "");
        }

        // const card = formData;
        //dispatch({type: 'USER_DATA', user: {card}});
        dispatch({type: 'PAYMENT', payment: {validateCard: true}});
        dispatch({type: 'PAYMENT', payment: {validateCard: true}});
        //dispatch({type: 'PAYMENT', payment: {card: data}});
        setCard.forwardRef(data.card);

    }, [dispatch, state.cartaoTerceiro, state.credito, state.debito, state.paymentMethod, setCard.forwardRef]);

    useEffect(() => {
        handleBlur();
    }, [handleBlur, state.debito, state.credito]);

    const cartaoTerceiro = (e) => {
        setState({
            ...state,
            cartaoTerceiro: !state.cartaoTerceiro,
        });
    };

    const debito = () => {
        setState({
            ...state,
            debito: !state.debito,
            credito: false,
            paymentMethod: 'debit_card',
        });
    }
    const credito = () => {
        setState({
            ...state,
            debito: false,
            credito: !state.credito,
            paymentMethod: 'credit_card',
        });
    }

    return (
        <div className="m-auto" key="Payment">
            <div>
                <div className="d-flex flex-row align-items-center justify-content-start">
                    <div className="p-2">
                        <div className="icheck-danger d-inline">
                            <input type="radio" name={'paymentMethod'} id="debito"
                                   defaultChecked={state.debito}
                                   onChange={debito}/>
                            <label className="" htmlFor="debito" style={{
                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                            }}>Débito</label>
                        </div>
                    </div>
                    <div className="p-2">
                        <div className="icheck-danger d-inline">
                            <input type="radio" name={'paymentMethod'} id="credito"
                                   defaultChecked={state.credito}
                                   onChange={credito}/>
                            <label className="" htmlFor="credito" style={{
                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                            }}>Crédito</label>
                        </div>
                    </div>
                </div>

                <div
                    className={`d-flex ${useResize().width < 490 ? 'flex-column' : 'align-items-start justify-content-between'} `}>

                    <div className="p-2">
                        <Card
                            number={number}
                            name={name}
                            expiry={expiry}
                            cvc={cvc}
                            focused={focused}
                            locale={{valid: 'válido até'}}
                            placeholders={{name: 'SEU NOME AQUI'}}
                            callback={handleCallback}
                        />
                    </div>

                    <div className="p-2">
                        <form ref={formRef}>
                            <div className="p-1">
                                <input
                                    type="tel"
                                    name="number"
                                    className="form-control border-0 border-radius-10 text-sm"
                                    style={{backgroundColor: '#e6e6e6'}}
                                    placeholder="Número do Cartão"
                                    pattern="[\d| ]{16,22}"
                                    required
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    defaultValue={number}
                                />
                            </div>
                            <div className="p-1">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control border-0 border-radius-10 text-sm"
                                    style={{backgroundColor: '#e6e6e6'}}
                                    placeholder="Nome"
                                    required
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    defaultValue={name}
                                />
                            </div>
                            <div className="p-1">
                                <input
                                    type="tel"
                                    name="cpf"
                                    className="form-control border-0 border-radius-10 text-sm use-mask"
                                    style={{backgroundColor: '#e6e6e6'}}
                                    placeholder="CPF do Titular"
                                    mask={"999.999.999-99"}
                                    required
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    defaultValue={cpf}
                                />
                            </div>
                            <div className="p-1 d-flex align-items-start justify-content-between">
                                <div className="flex-grow-0 mr-1 w-50">
                                    <input
                                        type="tel"
                                        name="expiry"
                                        className="form-control border-0 border-radius-10 text-sm"
                                        style={{backgroundColor: '#e6e6e6'}}
                                        placeholder="Válido até"
                                        pattern="\d\d/[\d| ]{4}"
                                        required
                                        onBlur={handleBlur}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        defaultValue={expiry}
                                    />
                                </div>
                                <div className="flex-grow-0 ml-1 w-50">
                                    <input
                                        type="tel"
                                        name="cvc"
                                        className="form-control border-0 border-radius-10 text-sm"
                                        style={{backgroundColor: '#e6e6e6'}}
                                        placeholder="CVC"
                                        pattern="\d{3,4}"
                                        mask={"999.999.999-99"}
                                        required
                                        onBlur={handleBlur}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        defaultValue={cvc}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PaymentForm;
