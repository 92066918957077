import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {helpers} from "../../components/helpers";

/*layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Forms*/
import {Form} from "@unform/web";
import CardForm from '../../components/Form/newCard';
import * as Yup from "yup";
import Services from "../../services";
import Input from "../../components/Form/input";
import InputCep from "../../components/Form/input-cep";
import {DivFooterSection1} from "../../components/styles";
import PaymentForm from "../../components/Form/payment";

const NovoCartao = (props) => {
        const store = useSelector(store => store);
        const formRef = useRef();
        const dispatch = useDispatch();
        const history = useHistory();

        const [card, setCard] = useState({
            card: !!props.location.state ? props.location.state.card : null,
        });

        const [edit,setEdit] = useState({
            edit: !!props.location.state ? props.location.state.edit : null,
        })

        const [loading, setLoading] = useState(false);

        const [state, setState] = useState({
            number: '',
            name: '',
            expiry: '',
            cvc: '',
            issuer: '',
            focused: '',
            formData: '',
            cpf: '',
        });

        const adicionarOrEdit = async (e) => {
            setLoading(true);
            try {
                await helpers.animatedElement(e, 'bounceIn', 'fast');
                await helpers.awaitForTime(500);
                const formData = card;

                if((!formData.credito && !formData.debito) && !formData.card){
                    helpers.toast.error('Por favor selecione o campo Débito ou Crédito.');
                    setLoading(false);
                    return false
                }
                if(formData.hasOwnProperty(card.customer)){
                    helpers.toast.error('É necessario alterar CPF, Nome do Titular, Validade para editar ou Tipo do cartão.');
                    setLoading(false);
                    return false
                }

                if(formData.card){
                    history.goBack();
                    return false
                }
                if(formData.editCard ){
                    const schema = Yup.object().shape({
                        holder_name: Yup.string().max(64, 'Nome').required('Nome'),
                        holder_document: Yup.string().required('CPF do Titular'),
                        exp_month: Yup.string().required('Data de Validade'),
                        exp_year: Yup.string().required('Data de Validade')
                    });

                    await schema.validate(formData, {abortEarly: false});

                    if(formData.exp_month === 'Data inválida' || formData.exp_year === 'Data inválida'){
                        helpers.toast.error('Por favor verificar a validade do cartão.');
                        setLoading(false);
                        return false;
                    }

                    if (!helpers.toBrazilian.isCPF(formData.holder_document)) {
                        helpers.toast.error('CPF inválido!');
                        setLoading(false)
                        return false;
                    }

                    let data = {
                        "holder_name": formData.holder_name,
                        "exp_month": formData.exp_month,
                        "exp_year": formData.exp_year,
                        "holder_document": formData.holder_document
                    }

                    const responseMundiPaggEditCard = await Services.mundiPaggEditCards({
                        mundipagg: store.app.services.estabelecimento.mundipagg_secret_key,
                        data: data,
                        customers: store.app.modules.user.data.customers_id,
                        cards: formData.cardId
                    });

                    if (responseMundiPaggEditCard.data.errors || responseMundiPaggEditCard.data.message) {
                        helpers.toast.error('Dados do cartão inválidos. Tente novamente ou entre em contato com o suporte técnico');
                        setLoading(false);
                        return false;
                    } else {
                        if (formData.credito === true) {
                            responseMundiPaggEditCard.data.tipo = 'Crédito'
                        }

                        if (formData.debito === true) {
                            responseMundiPaggEditCard.data.tipo = 'Débito'
                        }

                        if (formData.cardFavorite === true) {
                            var index = store.app.modules.user.cards.findIndex(cartao => cartao.cardFavorite === true);

                            if (index >= 0) {
                                await delete store.app.modules.user.cards[index].cardFavorite;
                            }

                        }

                        responseMundiPaggEditCard.data.cardFavorite = formData.cardFavorite;

                        if(formData.cardFavorite === true ||  (store.app.modules.user.cardFavorite.length > 0 && store.app.modules.user.cardFavorite[0].id === responseMundiPaggEditCard.data.id && store.app.modules.user.cardFavorite[0].tipo === responseMundiPaggEditCard.data.tipo)) {
                            await dispatch({
                                type: 'USER_DATA',
                                user: {
                                    cardFavorite: [responseMundiPaggEditCard.data]
                                }
                            });
                        }
                        var addCard = [];

                        var pegarIndex = store.app.modules.user.cards.findIndex(
                            val => val.id === responseMundiPaggEditCard.data.id && val.tipo === responseMundiPaggEditCard.data.tipo
                        )

                        store.app.modules.user.cards[pegarIndex] = responseMundiPaggEditCard.data
                        addCard = store.app.modules.user.cards;
                    }

                    const envioPortal = await Services.saveCardsPortal({
                        hash: store.app.modules.user.login.data.hash,
                        cpf: store.app.modules.user.data.cpf.replace(/\D/g, ""),
                        cards_json: addCard
                    })

                    dispatch({
                        type: 'USER_DATA',
                        user: {
                            cards: addCard
                        }
                    });

                    helpers.toast.success('Cartão atualizado com sucesso!');
                    dispatch({type:'UPDATE'})
                    history.goBack();
                }else{
                    const schema = Yup.object().shape({
                        number: Yup.string().min(13, 'Número do cartão').required('Número do cartão'),
                        holder_name: Yup.string().max(64, 'Nome').required('Nome'),
                        holder_document: Yup.string().min(11, 'CPF inválido').required('CPF do Titular'),
                        exp_month: Yup.string().required('Data de Validade'),
                        exp_year: Yup.string().required('Data de Validade'),
                        cvc: Yup.string().required('CVC do cartão')
                    });

                    await schema.validate(formData, {abortEarly: false});

                    if(formData.exp_month === 'Data inválida' || formData.exp_year === 'Data inválida'){
                        helpers.toast.error('Por favor verificar a validade do cartão.');
                        setLoading(false);
                        return false;
                    }

                    if (!helpers.toBrazilian.isCPF(formData.holder_document)) {
                        helpers.toast.error('CPF inválido!');
                        setLoading(false)
                        return false;
                    }

                    let data;
                    if (formData.debito === true) {
                        data = {
                            "number": formData.number,
                            "holder_name": formData.holder_name,
                            "holder_document": formData.holder_document,
                            "exp_month": formData.exp_month,
                            "exp_year": formData.exp_year,
                            "cvv": formData.cvc,
                            "brand": helpers.getBandeiraCartao(formData.number)
                        }
                    } else {
                        data = {
                            "number": formData.number,
                            "holder_name": formData.holder_name,
                            "holder_document": formData.holder_document,
                            "exp_month": formData.exp_month,
                            "exp_year": formData.exp_year,
                            "cvv": formData.cvc,
                            "brand": helpers.getBandeiraCartao(formData.number),
                            "options": {
                                "verify_card": true
                            }
                        }
                    }


                    const responseMundiPaggAddCard = await Services.mundiPaggAddCards({
                        mundipagg: store.app.services.estabelecimento.mundipagg_secret_key,
                        data: data,
                        customers: store.app.modules.user.data.customers_id
                    });

                    if (responseMundiPaggAddCard.data.errors || responseMundiPaggAddCard.data.message) {
                        helpers.toast.error('Dados do cartão inválidos. Tente novamente ou entre em contato com o suporte técnico');
                        setLoading(false);
                        return false;
                    } else {
                        if (formData.credito === true) {
                            responseMundiPaggAddCard.data.tipo = 'Crédito'
                        }

                        if (formData.debito === true) {
                            responseMundiPaggAddCard.data.tipo = 'Débito'
                        }

                        if (formData.cardFavorite === true) {
                            var index = store.app.modules.user.cards.findIndex(cartao => cartao.cardFavorite === true);

                            if (index >= 0) {
                                delete store.app.modules.user.cards[index].cardFavorite;
                            }

                        }

                        responseMundiPaggAddCard.data.cardFavorite = formData.cardFavorite;
                        if(formData.cardFavorite === true) {
                            dispatch({
                                type: 'USER_DATA',
                                user: {
                                    cardFavorite: [responseMundiPaggAddCard.data]
                                }
                            });
                        }
                        var addCard = [];
                        if (!store.app.modules.user.cards || store.app.modules.user.cards.length === 0) {
                            responseMundiPaggAddCard.data.cardFavorite = true;
                            addCard = [responseMundiPaggAddCard.data];

                            dispatch({
                                type: 'USER_DATA',
                                user: {
                                    cards: addCard
                                }
                            });

                            dispatch({
                                type: 'USER_DATA',
                                user: {
                                    cardFavorite: [responseMundiPaggAddCard.data]
                                }
                            });

                        } else {
                            var existeOuNao = store.app.modules.user.cards.findIndex(
                                val => val.id === responseMundiPaggAddCard.data.id && val.tipo === responseMundiPaggAddCard.data.tipo
                            )
                            if (existeOuNao < 0) {
                                store.app.modules.user.cards.push(responseMundiPaggAddCard.data);
                                addCard = store.app.modules.user.cards;
                            }else{
                                helpers.toast.error('Um cartão com esse número já foi adicionado.');
                                setLoading(false);
                                return false;
                            }

                        }

                        const envioPortal = await Services.saveCardsPortal({
                            hash: store.app.modules.user.login.data.hash,
                            cpf: store.app.modules.user.data.cpf,
                            cards_json: addCard
                        })


                        helpers.toast.success('Cartão cadastrado com sucesso!');
                        dispatch({type:'UPDATE'})
                        setLoading(false);
                        history.goBack();
                    }
                }
            } catch (errors) {
                if (errors instanceof Yup.ValidationError) {
                    var stringMessage = '';
                    errors.inner.forEach(error => {

                        if (errors.inner.indexOf(error) === errors.inner.length - 1)
                        {
                           if(stringMessage.indexOf(error.message) < 0) {
                               stringMessage += error.message;
                           }
                        }else{
                            if(stringMessage.indexOf(error.message) < 0){
                                stringMessage += error.message + ', ';
                            }
                        }
                    })
                    helpers.toast.error('Por favor verifique os campos ' + stringMessage + '.');
                    setLoading(false);
                    return false;
                }
            }
        };

        return (
            <div className="h-100 d-flex flex-column justify-content-between">
                <Header title={!edit.edit ? "Novo Cartão" : "Editar Cartão"}/>

                <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column justify-content-center">
                            <Form ref={formRef} initialData={card}>
                                <CardForm forwardRef={setCard} props={card}/>
                            </Form>

                    <div className="p-2 flex-grow-0">
                        <div className="p-2 d-flex align-items-center justify-content-around">
                            <div
                                className="p-2 w-75 text-bold text-md btn btn-block border-radius-20
                             d-flex align-items-center justify-content-around" style={{
                                backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                color: '#fff',
                            }}
                                onClick={adicionarOrEdit}>
                                <div className="p-2">Salvar Cartão</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="w-100 h-100">

                    {
                        !!loading
                            ?
                            <div
                                className="overlay-custom d-flex justify-content-center align-items-center flex-column text-white">
                                <div className="p-1">
                                    <i className="fas fa-2x fa-sync-alt fa-spin"/>
                                </div>

                                <div className="p-1">
                                    <span>Salvando cartão</span>
                                </div>
                                <div className="p-1">
                                    <span>Aguarde...</span>
                                </div>
                            </div>
                            :
                            <div/>
                    }
                </div>

                <Footer/>

            </div>
        );
    }
;

export default NovoCartao;
