import React from 'react';

const IconRefresh = ({
                         style = {},
                         fill = "#ff1200",
                         width = "100%",
                         className = "",
                         x = "0px", y = "0px",
                         viewBox = "0 0 42.22 38.31",
                         xmlns = "http://www.w3.org/2000/svg",
                         xmlnsXlink = "http://www.w3.org/1999/xlink",
                     }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path
            d="M35.37,20.63c-.59.76-1.14,1.49-1.7,2.19a1.32,1.32,0,0,1-1.92.33,1.39,1.39,0,0,1-.21-2l3.31-4.38a2.28,
            2.28,0,0,1,3.71,0c1.13,1.46,2.23,2.94,3.34,4.42a1.35,1.35,0,0,1-.21,1.95,1.32,1.32,0,0,
            1-1.89-.26c-.52-.64-1-1.3-1.5-1.94-.17.06-.16.19-.17.29A19.13,19.13,0,0,1,22.59,38a18.48,18.48,0,0,
            1-10.77-1.13,18.83,18.83,0,0,1-9.18-8A18.87,18.87,0,0,1,.12,17,18.61,18.61,0,0,1,3.51,8.1a18.85,18.85,0,0,1,
            8.05-6.52,18.72,18.72,0,0,1,13.71-.53,1.33,1.33,0,0,1,1,1.79,1.39,1.39,0,0,1-1.93.74,15.5,15.5,0,0,
            0-9.14-.36c-6.26,1.7-10.36,5.72-12,12A15.57,15.57,0,0,0,6.06,29.05a15.79,15.79,0,0,0,11.42,6.44,15.9,
            15.9,0,0,0,12.84-4.36,16,16,0,0,0,5.07-9.91A.93.93,0,0,0,35.37,20.63Z"/>

    </svg>
);

export default IconRefresh;
