import React, {useRef} from 'react';
import Slider from 'react-slick';
import {useDispatch, useSelector} from "react-redux";

const CarrouselCategorias = (props) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const slider = useRef({
        className: 'test'
    });

    const settings = {
        initialSlide: 0,
        accessibility: false,
        adaptiveHeight: false,
        centerMode: true,
        className: "custom-slider",
        centerPadding: "10px",
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        // autoplay: true,
        // autoplaySpeed: 3000,
        // pauseOnHover: true,
        // rtl: true,
        beforeChange: (current, next) => {
        },
        afterChange: (current, next) => {
        },
        ...props,
    };

    const handleClick = (e, categoriaCodigo) => {
        dispatch({
            type: 'PRODUTOS',
            data: {categoriaSelected: categoriaCodigo}
        });
    };

    return (
        <Slider ref={slider} {...settings}>
            {
                store.app.services.produtosCategorias.map(categoria => (
                    <div key={categoria.categoriaCodigo} onClick={e => {
                        handleClick(e, categoria.categoriaCodigo)
                    }}
                         className="p-2 text-center d-flex align-items-center justify-content-center">
                        <span className="text-sm"
                              style={{
                                  color: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                              }}>{categoria.categoriaNome}</span>
                    </div>
                ))
            }
        </Slider>
    );
};

export default CarrouselCategorias;
