import React from 'react';

const IconCheck = ({
                       style = {},
                       fill = "#ff1200",
                       width = "100%",
                       className = "",
                       x = "0px", y = "0px",
                       viewBox = "0 0 36.91 28.99",
                       xmlns = "http://www.w3.org/2000/svg",
                       xmlnsXlink = "http://www.w3.org/1999/xlink",
                   }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path
            d="M3.45,12.17a3.91,3.91,0,0,1,2.73,1.24c1.66,1.69,3.41,3.3,5,5.05.94,1,1.49.88,2.41,0C19.16,12.76,
                    24.81,7.17,30.41,1.53A4,4,0,0,1,34,0a3.32,3.32,0,0,1,2.68,2.2,3.22,3.22,0,0,1-.71,3.56c-1,1.09-2.07,
                    2.1-3.11,3.14C26.4,15.37,19.94,21.79,13.55,28.27c-1,1-1.45,1-2.38,0-3.29-3.42-6.69-6.73-10-10.1A3.3,
                    3.3,0,0,1,.3,14.31,3.42,3.42,0,0,1,3.45,12.17Z"/>

    </svg>
);

export default IconCheck;
