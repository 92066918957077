import React, {useState} from 'react';
import {useLocation} from "react-router";
import logo from "../../assets/images/deliverymenew-logo.svg";
import logoDeliveryAzul from '../../assets/images/delivery-azul-logo.png';
import {helpers} from "../../components/helpers";
import marca from "../../assets/images/deliverydacerto-logo.svg";

const NotFound = (props) => {
    const location = useLocation();

    const [state] = useState({
        deliveryAzul: helpers.isDeliveryAzul() ? logoDeliveryAzul : logo,
        deliveryAzulTamanhoLogo: helpers.isDeliveryAzul() ? '300px' : '150px',
        deliveryAzulTextRed: helpers.isDeliveryAzul() ? 'text-red-delivery-azul' : 'text-danger',
        deliveryAzulEsconderMarca: helpers.isDeliveryAzul() ? '' : <img width={"100px"} src={marca} alt="marca"/>,
    });

    return (
        <div className="d-flex flex-column align-items-center scroll mt-3">

            <div className="p-2 flex-grow-0 d-flex align-items-center justify-content-center">
                <img className="img-fluid" width={state.deliveryAzulTamanhoLogo} src={state.deliveryAzul} alt="logo"/>
            </div>

            <div className="p-2 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center">

                {/*<div className="p-4 error-page">*/}
                {/*    <h2 className={`headline ${state.deliveryAzulTextRed}`}>404</h2>*/}
                {/*</div>*/}
                <div className="p-2 error-content">
                    <h3>
                        <i className="fas fa-exclamation-triangle text-danger"/> Oops!
                    </h3>
                </div>
                <div className="p-2">
                    <h3>Página não encontrada.</h3>
                </div>
                {/*<div className="p-2">*/}
                {/*    <small className="text-muted">*/}
                {/*        Não há correspondência para <code>{location.pathname}</code>*/}
                {/*    </small>*/}
                {/*</div>*/}
                <div className="p-4 flex-shrink-1 m-auto">
                <span className={state.deliveryAzulTextWhite}>
                    Esta loja não existe ou ainda não foi cadastrada.
                </span>
                </div>

            </div>

            {/*<div className="p-2 flex-grow-1 d-flex align-items-center justify-content-center">*/}
            {/*    {state.deliveryAzulEsconderMarca}*/}
            {/*</div>*/}

            {/*<div className="w-100 flex-grow-0 d-flex align-items-start justify-content-end mb-3 mr-3">*/}
            {/*    <small className="text-muted">{`v${helpers.packageJson.version}`}</small>*/}
            {/*</div>*/}

        </div>
);
};

export default NotFound;
