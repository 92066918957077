import React, {useEffect, useRef} from 'react';
import {useField} from '@unform/core';
import $ from 'jquery';
import 'jquery-mask-plugin';

const Input = ({name, ...rest}) => {
    const inputRef = useRef(null);
    const {fieldName, registerField, defaultValue, error} = useField(name);
    const invalid = error ? 'is-invalid' : '';

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    useEffect(() => {
        if (!!rest.mask) {
            $(inputRef.current).mask(rest.mask);
        }
    }, [name, rest.mask]);

    return (
        <>
            <input ref={inputRef} defaultValue={defaultValue}
                   {...rest} className={rest.className + " " + invalid}/>
            {error &&
            <span className="p-1 error invalid-feedback">{error}</span>}
        </>
    );
};

export default Input;
