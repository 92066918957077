/**
 * Arquivo de Helper para tratamento de dados
 */
import {toast} from 'react-toastify';
import query from 'querystring'
import Bowser from 'bowser';
import dot from 'dot-prop-immutable';
import lodash from 'lodash';
import merge from 'merge-objects';
import moment from 'moment';
import packageJson from '../../package.json';
import * as toBrazilian from 'brazilian-values';
import 'moment/locale/pt-br';

moment.locale('pt-br');
const browser = Bowser.getParser(window.navigator.userAgent);

/**
 * Collections from lodash
 */
export const collection = () => {
    return lodash;
};

/**
 * Mergind objects
 * @param object1
 * @param object2
 */
export const merging = (object1, object2) => {
    return merge(object1, object2);
};

const calculoTotaisByMetodo = (metodo, complemento, temPizza, pedeComplemento) => {
    let total = 0;
    /**
     * Método 0 ou 1 - (Média)
     * Soma os preços dos itens selecionados e divide proporcionalmente entre eles
     */
    let totalMedia = 0;
    const produtosMedia = collection().filter(complemento.produtos, ((p) => {
        return p.qtdSelected > 0 && (
            (temPizza === 'S' && metodo === 0) ||
            (temPizza === 'S' && metodo === 1) ||
            (pedeComplemento === 'S' && metodo === 1)
        );
    }));
    if (produtosMedia.length > 0) {
        let totalqtdSelected = 0;
        produtosMedia.map(produto => {
            totalMedia += produto.preco * produto.qtdSelected;
            totalqtdSelected += produto.qtdSelected;
            return true;
        });
        totalMedia = totalMedia / totalqtdSelected;
    }
    total += totalMedia;

    /**
     * Método 2 - (Maior)
     * Coloca o maior preço dos itens selecionados
     */
    let totalMaior = 0;
    const produtosMaior = collection().filter(complemento.produtos, ((p) => {
        return p.qtdSelected > 0 && metodo === 2;
    }));
    if (produtosMaior.length > 0) {
        const produtoMaiorPreco = collection().last(collection().sortBy(produtosMaior, ['preco']));
        totalMaior += produtoMaiorPreco.preco * produtoMaiorPreco.qtdSelected;
    }
    total += totalMaior;

    /**
     * Método 3 - (Média tipo 2)
     * Soma o valor dos dois(2) itens mais caros e divide por (2).
     */
    let totalMedia2 = 0;
    const produtosMedia2 = collection().filter(complemento.produtos, ((p) => {
        return p.qtdSelected > 0 && metodo === 3;
    }));

    if (produtosMedia2.length > 0) {
        const produtosMaiorPreco = collection().takeRight(collection().sortBy(produtosMedia2, ['preco']), 2);
        produtosMaiorPreco.map(produto => {
            totalMedia2 += produto.preco * produto.qtdSelected;
            return true;
        });
    }
    total += totalMedia2 / 2;

    /**
     * Método 4 - (Fixo)
     * Soma o valor dos dois(2) itens mais caros e divide por (2).
     */
    let totalFixo = 0;
    const produtosPrecoFixo = collection().filter(complemento.produtos, ((p) => {
        return p.qtdSelected > 0 && (
            (temPizza === 'S' && metodo === 4) ||
            (pedeComplemento === 'S' && metodo === 4) ||
            (pedeComplemento === 'S' && metodo === 0) ||
            (pedeComplemento === 'S' && metodo === null)
        );
    }));
    if (produtosPrecoFixo.length > 0) {
        produtosPrecoFixo.map(produto => {
            totalFixo += produto.preco * produto.qtdSelected;
            return true;
        });
    }
    total += totalFixo;
    return total;
};

const calculoTotaisByMetodoLansec = (categoriaProduto, metodo, complemento) => {
    
  let total = 0;

  /**
   * Método undefined
   * Calcula o valor pelo preço e qtdSelected
   */
  if (metodo === undefined) {
    complemento.produtos.map((complementoProduto) => {
      if (complementoProduto.qtdSelected > 0) {
        total +=
          complementoProduto.preco *
          complementoProduto.qtdSelected *
          categoriaProduto.qtdSelected;
      }
      return true;
    });
  }

  /**
   * Método null - Metodo Zero
   * Não pega nenhum valor
   */
  if (metodo === null) {
    total += 0;
  }

  /**
   * Método 1 - Método Média
   * Soma os preços dos itens selecionados e divide proporcionalmente entre eles
   */
  let totalMedia = 0;
  const produtosMedia = collection().filter(complemento.produtos, (p) => {
    return p.qtdSelected > 0 && complemento.lansec_metodo === 1;
  });
  if (produtosMedia.length > 0) {
    let totalqtdSelected = 0;
    produtosMedia.map((produto) => {
      totalMedia += produto.preco * produto.qtdSelected;
      totalqtdSelected += produto.qtdSelected;
      return true;
    });
    totalMedia = totalMedia / totalqtdSelected;
  }
  total += totalMedia;

  /**
   * Método 2 - (Maior)
   * Coloca o maior preço dos itens selecionados
   */
  let totalMaior = 0;
  const produtosMaior = collection().filter(complemento.produtos, (p) => {
    return p.qtdSelected > 0 && metodo === 2;
  });
  if (produtosMaior.length > 0) {
    const produtoMaiorPreco = collection().last(
      collection().sortBy(produtosMaior, ["preco"])
    );
    totalMaior += produtoMaiorPreco.preco * produtoMaiorPreco.qtdSelected;
  }
  total += totalMaior;

  /**
   * Método 4 - Método Adic
   * Soma os valores de todos os itens selecionados.
   */
  let totalProd = 0;
  const produtosMedia2 = collection().filter(complemento.produtos, (p) => {
    return p.qtdSelected > 0 && metodo === 4;
  });

  if (produtosMedia2.length > 0) {
    const produtosMaiorPreco = collection().takeRight(
      collection().sortBy(produtosMedia2, ["preco"]),
      2
    );
    produtosMaiorPreco.map((produto) => {
      totalProd += produto.preco * produto.qtdSelected;
      return true;
    });
  }
  total += totalProd;
  return total;
};

/**
 * Retorna o valor total do produto
 * Considerando a regra de "método".
 * @param store
 * @param moeda
 * @returns {*}
 */
export const calcTotalProduto = (categoriaProduto) => {

    let total = 0;
    let temPizza = 'N';
    let temLansec = null;
    let pedeComplemento = 'N';
    let metodo = null;

    total += categoriaProduto.qtdSelected * categoriaProduto.preco;

    if (!!categoriaProduto.complementos) {
        temPizza = categoriaProduto.temPizza;
        pedeComplemento = categoriaProduto.pedeComplemento;
        metodo = categoriaProduto.metodo;
        temLansec = categoriaProduto.temLansec;
        categoriaProduto.complementos.map(complemento => {
            if(temLansec === 'N' || temLansec === null) {
                if ((temPizza === 'S' && complemento.adicionais === false) || pedeComplemento === 'S') {
                    total += calculoTotaisByMetodo(metodo, complemento, temPizza, pedeComplemento) * categoriaProduto.qtdSelected;
                } else {
                    complemento.produtos.map(complementoProduto => {
                        if (complementoProduto.qtdSelected > 0) {
                            total += complementoProduto.preco * complementoProduto.qtdSelected * categoriaProduto.qtdSelected;
                        }
                        return true;
                    });
                }
            }else{
                if(categoriaProduto.qtdSelected > 0) {
                    metodo = complemento.lansec_metodo;
                    total += calculoTotaisByMetodoLansec(categoriaProduto, metodo, complemento);
                }
            }
            return true;
        });
    }

    return total;
};

/**
 * Retorna o valor total do pedido
 * Considerando a regra de "método".
 * @param store
 * @param moeda
 * @returns {*}
 */
export const getTotalPedido = (store, moeda = true, indexProd) => {

    const pedidosIndividual = store.app.modules.carrinho.pedidos.produtosIndividual;
    const pedidosMontagem = store.app.modules.carrinho.pedidos.produtosMontagem;
    let total = 0;

    const getTotalByPedidos = (pedidos) => {

        let total = 0;

        pedidos.map(pedido => {

            pedido.produtos.map(categoriaProduto => {

                total += calcTotalProduto(categoriaProduto);

                return true;

            });

            return true;
        });

        return total;
    };

    total = getTotalByPedidos(pedidosIndividual) + getTotalByPedidos(pedidosMontagem);
    return !moeda ? parseFloat(total.toFixed(2)) : converteMoeda(total);
};

/**
 * Retorna a quantidade total de produtos escolhidos
 * @param store
 * @returns {*}
 */
export const getQuantidadeItens = (store = {}) => {
    const pedidosIndividual = store.app.modules.carrinho.pedidos.produtosIndividual;
    const pedidosMontagem = store.app.modules.carrinho.pedidos.produtosMontagem;
    let qtd = 0;

    const getQuantidadeByPedidos = (pedidos) => {
        let qtd = 0;
        pedidos.map(pedido => {
            pedido.produtos.map(produto => {
                qtd += produto.qtdSelected;
                return true;
            });
            return true;
        });
        return qtd;
    };

    qtd = getQuantidadeByPedidos(pedidosIndividual) + getQuantidadeByPedidos(pedidosMontagem);

    return qtd;
};

/**
 * Retorna o valor total dos complementos calculados com os "metodos" caso exista
 * @param produto
 * @returns {string}
 */
export const getTotalComplementos = (produto, moeda = true) => {
    let total = produto.preco;
    const temPizza = produto.temPizza;
    const pedeComplemento = produto.pedeComplemento;
    const metodo = produto.metodo;
    if (produto.temKit === 'S') {
        produto.complementos.map(complemento => {
            complemento.produtos.map(complementoProduto => {
                if (complementoProduto.qtdSelected > 0) {
                    total += complementoProduto.preco * complementoProduto.qtdSelected;
                }
                return true;
            });
            return true;
        });
    } else {
        produto.complementos.map(complemento => {
            if ((produto.temPizza === 'S' && complemento.adicionais === false) || (produto.pedeComplemento === 'S' && !!produto.cComplemento)) {
                total += calculoTotaisByMetodo(metodo, complemento, temPizza, pedeComplemento);
            } else {
                complemento.produtos.map(complementoProduto => {
                    if (complementoProduto.qtdSelected > 0) {
                        total += complementoProduto.preco * complementoProduto.qtdSelected;
                    }
                    return true;
                });
            }
            return true;
        });
    }
    total = total * produto.qtdSelected;

    return !moeda ? parseFloat(total.toFixed(2)) : converteMoeda(total);
};

/**
 * Retorna a soma de todos os produtos para a tela de montagem
 * Considerando a regra de "método".
 * @param store
 * @returns {string}
 */
export const getTotalCombo = (store) => {
    let total = 0;
    let temPizza = 'N';
    let pedeComplemento = 'N';
    let temLansec = null;
    let metodo = null;

    store.app.modules.montagem.data.map(categoria => {
        categoria.produtos.map(categoriaProduto => {

            total += categoriaProduto.preco * categoriaProduto.qtdSelected;
            temPizza = categoriaProduto.temPizza;
            pedeComplemento = categoriaProduto.pedeComplemento;
            metodo = categoriaProduto.metodo;
            temLansec = categoriaProduto.temLansec;
            categoriaProduto.complementos.map(complemento => {

                if(temLansec === 'N' || temLansec === null) {
                    if ((temPizza === 'S' && complemento.adicionais === false) || pedeComplemento === 'S') {
                        total += calculoTotaisByMetodo(metodo, complemento, temPizza, pedeComplemento) * categoriaProduto.qtdSelected;
                    } else {
                        complemento.produtos.map(complementoProduto => {
                            if (complementoProduto.qtdSelected > 0) {
                                total += complementoProduto.preco * complementoProduto.qtdSelected * categoriaProduto.qtdSelected;
                            }
                            return true;
                        });
                    }
                }else{
                    metodo = complemento.lansec_metodo;
                    total += calculoTotaisByMetodoLansec(categoriaProduto, metodo, complemento);
                }
                return true;
            });

            return true;
        });
        return true;
    });
    return converteMoeda(total);
};

export const getTotalProduto = (produto, moeda = true) => {
    let total = 0;
    if (!!produto) {
        total = produto.preco * produto.qtdSelected;
    }
    return !moeda ? total : converteMoeda(total);
};

export const getQuantidadeTotalCombo = (store) => {
    return store.app.modules.montagem.data.map(categoria => {
        return categoria.produtos.map(produto => {
            return produto.qtdSelected;
        });
    });
};

export const setPlusTotalCombo = (store, dispatch) => {
    const data = [...store.app.modules.montagem.data];
    data.map(categoria => {
        return categoria.produtos.map(produto => {
            return ++produto.qtdSelected;
        });
    });
    dispatch({type: 'MONTAGEM_DATA', data});
};

export const setMinusTotalCombo = (store, dispatch) => {
    const data = [...store.app.modules.montagem.data];
    data.map(categoria => {
        return categoria.produtos.map(produto => {
            return produto.qtdSelected > 1 ? --produto.qtdSelected : produto.qtdSelected;
        });
    });
    dispatch({type: 'MONTAGEM_DATA', data});
};

export const totalComboChange = (value, store, dispatch) => {
    const data = [...store.app.modules.montagem.data];
    data.map(categoria => {
        return categoria.produtos.map(produto => {
            return produto.qtdSelected = value;
        });
    });
    dispatch({type: 'MONTAGEM_DATA', data});
};

/**
 * Retorna o número formatado em real
 * @param numero
 * @returns {string}
 */
export const converteMoeda = (numero, prefix = true) => {
    if (collection().isString(numero)) {
        numero = parseFloat(numero.replace(',', '.'));
    }
    numero = (!!numero) ? numero : 0;
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    if (prefix) {
        options.style = 'currency';
        options.currency = 'BRL';
    }

    return numero.toLocaleString('pt-BR', options);
};

/**
 * Adiciona e remove classes de um elemento para animação
 * @param event
 * @param classNames
 */
export const animatedElement = async (event, ...classNames) => {
    const classeList = event.currentTarget.classList;
    if (classeList.contains('animated')) {
        classeList.remove('animated');
        classNames.map(className => {
            classeList.remove(className);
            return true;
        });
    }
    await awaitForTime(200);
    classeList.add('animated');
    classNames.map(className => {
        classeList.add(className);
        return true;
    });
};

/**
 * Adiciona classes de um elemento
 * @param event
 * @param classNames
 */
export const addClassesElement = (event, ...classNames) => {
    const classeList = event.target.classList;
    classNames.map(className => {
        classeList.add(className);
        return true;
    });
};

/**
 * Remove classes de um elemento
 * @param event
 * @param classNames
 */
export const removeClassesElement = (event, ...classNames) => {
    const classeList = event.target.classList;
    classNames.map(className => {
        classeList.remove(className);
        return true;
    });
};

/**
 * Cria uma promise para aguardar um tempo determinado
 * é um pause para ser utilizado com o "await"
 * @param time
 * @returns {Promise<unknown>}
 */
export const awaitForTime = (time = 1000) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};

let timer = null;
export const waitSomeToRun = (INTERVAL = 1000, callback) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
        if (callback) {
            callback();
        }
    }, INTERVAL);
};

export const isCardapio = () => {
    const path = window.location.href;
    if (path.indexOf('cardapio.menew') !== -1 || path.indexOf('cardapiotest.menew') !== -1) {
        return true;
    }
    return false;
};

export const isDeliveryAzul = () => {
    const path = window.location.href;
    if (path.indexOf('azul') !== -1 || path.indexOf('azultest') !== -1) {
        return true;
    }
    return false;
};

export const isIndoor = (store) => {
    if (!!lodash.get(store, 'app.services.indoor.mesa')) {
        return true;
    }
    return false;
};

export const isProduction = () => {
    return !(window.location.href.indexOf('localhost') > 0 || window.location.href.indexOf('test') > 0);
};


/**
 * Retorna o menor preco dos complementos de um produto dos tipos: KIT, PIZZA e PEDE_COMPLEMENTO
 * @param produto
 * @param store
 * @returns {string}
 */
export const getMenorPrecoProdutoComplemento = (produto, store) => {
    if (produto.preco === 0) {
        let maiorPreco = 0;
        const produtosCategorias = collection().cloneDeep(store.app.services.produtosCategorias);

        /*Recupera os complementos da pizza selecionada*/
        if (produto.temPizza === 'S') {
            const pizzaCloned = collection().cloneDeep(store.app.services.pizza);
            const pizzas = pizzaCloned.filter(pizza => {
                return pizza.codigo === produto.produtoCodigo;
            });

            pizzas.map(pizza => {
                const filterSabores = collection().filter(produtosCategorias, {categoriaCodigo: pizza.sabores});
                maiorPreco = collection().minBy(dot.get(filterSabores, '0.produtos'), 'preco');
                if (!maiorPreco) {
                    maiorPreco = {preco: 0};
                }
                return true;
            });
        }

        /*Recupera os Complementos produto "pedeComplemento" selecionado*/
        if (produto.pedeComplemento === 'S' && !!produto.cComplemento) {
            const complementos = collection().filter(produtosCategorias, {categoriaCodigo: produto.cComplemento});
            maiorPreco = collection().minBy(dot.get(complementos, '0.produtos'), 'preco');
            if (!maiorPreco) {
                maiorPreco = {preco: 0};
            }
        }

        /*Recupera os kits do produto selecionado*/
        if (produto.temKit === 'S') {
            const kitsCloned = collection().cloneDeep(store.app.services.kits);
            const kits = kitsCloned.filter(kit => {
                return kit.codigo === produto.produtoCodigo;
            });

            let maiorPrecoKit = 0;
            kits.map(kit => {
                for (let i = 1; i <= 12; i++) {
                    const filter = collection().filter(produtosCategorias, {categoriaCodigo: kit['categ' + i]});
                    if (filter.length > 0) {
                        const kit = collection().minBy(dot.get(filter, '0.produtos'), 'preco');
                        if (kit.preco > maiorPrecoKit) {
                            maiorPrecoKit = kit.preco;
                            maiorPreco = kit;
                        }
                    }
                }
                return true;
            });
        }

        return `A partir de ${converteMoeda(maiorPreco.preco)} `;
    }
    return `A partir de ${converteMoeda(produto.preco)} `;
};


/**
 * Retorna a bandeira a partir do numero do cartão
 * @param numero
 * @returns {string}
 */
export const getBandeiraCartao = (numero) => {
    var cards = {
        Visa      : /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard : /^5[1-5][0-9]{14}/,
        Diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex      : /^3[47][0-9]{13}/,
        Discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        JCB        : /^(?:2131|1800|35\d{3})\d{11}/,
        Aura      : /^(5078\d{2})(\d{2})(\d{11})$/
    };

    for (var flag in cards) {
        if(cards[flag].test(numero)) {
            return flag;
        }
    }

    return false;
};

const reloadControl = () => {
    if (!window.appControll.loaded
        || moment().diff(window.appControll.sessionTime, 'minutes') >= window.appControll.sessionTimeLimit) {
        return true;
    }
    return false
};

/**
 * Interface para helpers
 * @type {{getTotalPedido: (function(*=): *[])}}
 */
export const helpers = {
    packageJson,
    toast,
    dot,
    moment,
    query,
    browser,
    collection,
    merge: merging,
    converteMoeda,
    animatedElement,
    addClassesElement,
    removeClassesElement,
    awaitForTime,
    waitSomeToRun,
    isCardapio,
    isDeliveryAzul,
    isIndoor,
    toBrazilian,
    reloadControl,
    isProduction,
    getBandeiraCartao,
    carrinho: {
        getTotalPedido,
        getQuantidadeItens,
    },
    montagem: {
        getTotalCombo,
        getQuantidadeTotalCombo,
        setPlusTotalCombo,
        setMinusTotalCombo,
        totalComboChange,
        getTotalComplementos,
    },
    produto: {
        getTotalProduto,
        calcTotalProduto,
        getMenorPrecoProdutoComplemento,
    }
};
