import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LogoMenew from "./logo-menew-circle";

const LogoDinamica = (props) => {
    const [state, setState] = useState({
        ...props,
        content: null
    });

    useEffect(() => {
        if (state.src && state.svg) {
            const fetchData = async () => {
                const response = await axios.get(state.src);
                setState(state => ({content: response.data}));
            };
            fetchData();
        }
    }, [state]);

    return (
        <div>
            {!props.src ? <LogoMenew fill={props.fill}/> : state.content ?
                <div dangerouslySetInnerHTML={{__html: state.content}}/> :
                <img src={props.src} alt=""/>}
        </div>
    )
};

export default LogoDinamica
