import React, {useState} from 'react';

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Components*/
import Passo1 from './passo1';
import Passo2 from './passo2';

const Login = () => {
    const [passo, setPasso] = useState(1);

    const changePassos = (p) => {
        setPasso(p);
    };

    return (
        <div className="h-100 d-flex flex-column justify-content-between scroll">

            <Header title={"Cadastro"}/>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column justify-content-center">

                <div className="p-2 flex-grow-1 d-flex align-items-end justify-content-center">
                    <span className="text-bold">CRIAR MINHA CONTA</span>
                </div>

                <div className="p-2 flex-grow-1 d-flex align-items-start justify-content-center">
                    {
                        (passo === 1)
                            ? <Passo1 passo={changePassos}/>
                            : <Passo2 passo={changePassos}/>
                    }
                </div>

            </div>

            <Footer/>
        </div>

    );
};

export default Login;
