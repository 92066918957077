import React from 'react';
import {useHistory, useParams} from "react-router";
import {useDispatch, useSelector} from 'react-redux';

/*Icons*/
import IconArrowLeft from '../../Icons/icon-arrow-left';
import IconLogoMenewCircle from '../../Icons/icon-logo-menew-circle';

const Header = (props) => {
    const store = useSelector(store => store);
    const history = useHistory();
    const dispatch = useDispatch();
    const {idestabelecimento} = useParams();

    return (
        <div className="flex-shrink-0 flex-grow-0 text-white" style={{
            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
        }}>
            <div className="p-2 d-flex align-items-center justify-content-between">
                {
                    !props.recuperarSenha
                        ?
                        <div className="p-2"
                             onClick={event => {
                                 !!props.history
                                     ? history.push(`/${idestabelecimento}/${props.history}`)
                                     : history.goBack();
                                 dispatch({
                                     type: 'ANOTHERS', anothers: {
                                         anchorInicio: true
                                     }
                                 });
                             }}>
                            <IconArrowLeft width={30}/>
                        </div>
                        : <div/>
                }
                <div className="p-2">
                    <span className="text-lg text-bold">{props.title ? props.title : 'Title'}</span>
                </div>
                <div className="pt-1 pb-1 pl-1 pr-2">
                    <IconLogoMenewCircle width={38}/>
                </div>
            </div>
        </div>
    );
};

export default Header;
