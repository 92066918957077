import styled from 'styled-components';
import LogoMenewSvg from '../../components/logo-menew-circle';
import LogoDinamica from '../../components/logo-dinamica';
import MarcaSvg from '../../components/marca';

export const Marca = styled(MarcaSvg)(props => ({
    fill: props.fill ? props.fill : '',
    width: props.width ? props.width : 910,
    height: props.height ? props.height : 348
}));

export const LogoMenew = styled(LogoMenewSvg)(props => ({
    fill: props.fill ? props.fill : '#A80000'
}));

export const Logo = styled(LogoDinamica)(props => ({
    fill: props.fill ? props.fill : '#A80000'
}));

export const Body = styled.div(props => ({
    background: props.background ? props.background : '#f36051'
}));

export const CepContent = styled.div(props => ({
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : '100%',
    position: props.position ? props.position : 'absolute',
    'z-index': props.zIndex ? props.zIndex : '1',
}));

export const CepBox = styled.div(props => ({
    width: props.width ? props.width : 'auto',
    height: props.height ? props.height : 'auto',
    position: props.position ? props.position : 'absolute',
    top: props.top ? props.top : '25%',
    color: props.color ? props.color : '#fff',
    'background-color': 'rgba(0, 0, 0, 0.8)',
    'z-index': '1'
}));

export const Title = styled.h4(props => ({
    color: props.color ? props.color : '#212529'
}));

export const Button = styled.button(props => ({
    color: props.color ? props.color : '#ff695a',
    background: props.background ? props.background : '#ffd45b',
    border: '#ffd45b',
    padding: '0.5rem 15px 0.5rem 15px',
    'border-radius': 20,
    'font-size': 16
}));
