import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {useSelector} from 'react-redux';

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import {helpers} from "../../components/helpers";


const PedidosAnteriores = () => {
    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const [status] = useState({
        0: 'Pendente',
        1: 'Em preparo',
        3: 'Em preparo',
        4: 'Rejeitado',
        5: 'Despachado',
        8: 'Erro pagamento',
        11: 'Cancelado',
        12: 'Não despachado',
    });

    const getPedidosAnteriores = () => {
        return helpers.collection().filter(store.app.services.historicoPedidos.pedidos,
            pedido => pedido.status_integracao === 4 || pedido.status_integracao === 5 || pedido.status_integracao === 11);
    };

    return (
        <div className="h-100 d-flex flex-column justify-content-between">

            <Header title={"Pedidos"}/>

            <div className="flex-shrink-0 flex-grow-1 d-flex flex-column">

                <div className="p-4 flex-shrink-0 flex-grow-0 d-flex align-items-center">
                    <div className="mr-3 elevation-3" style={{
                        width: "80px",
                        height: "80px",
                        backgroundImage: !!store.app.services.estabelecimento.logomarca
                            ? `url(${store.app.services.estabelecimento.logomarca})`
                            : 'url(/images/sem-imagem.png)',
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        transition: "width 1s, height 1s"
                    }}/>
                    <div className="d-flex flex-column">
                        <span className="text-lg text-bold">{store.app.services.estabelecimento.descricao}</span>
                        <span className="text-sm mt-1">
                            <span style={{
                                float: "left",
                                width: "12px",
                                height: "12px",
                                backgroundColor: store.app.services.estabelecimento.em_funcionamento ? "#0F0" : "#F00",
                                marginTop: "2px",
                                marginRight: "2px",
                                border: "1px solid #fff",
                                borderRadius: "10px"
                            }}/>
                            {(store.app.services.estabelecimento.em_funcionamento) ? <span>Aberto</span> :
                                <span>Fechado</span>}
                            </span>
                    </div>
                </div>

                <div className="flex-shrink-0 flex-grow-0 p-2 d-flex align-items-start justify-content-start">
                    <div className="p-3 flex-fill d-flex align-items-center justify-content-center border-bottom"
                         onClick={e => {
                             history.push(`/${idestabelecimento}/pedidos`)
                         }}>
                        <span className="text-muted text-bold">Em andamento</span>
                    </div>
                    <div className="p-3 flex-fill d-flex align-items-center justify-content-center"
                         style={{
                             color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                             borderBottom: '1px solid',
                         }}>
                        <span className="text-bold">Anteriores</span>
                    </div>
                </div>

            </div>

            <div className="p-2 flex-grow-1 scroll position-relative">

                {
                    helpers.collection().map(
                        helpers.collection().groupBy(getPedidosAnteriores(), 'estabelecimento_nome'),
                        (dados, estabelecimento) => (
                            <div key={estabelecimento}>
                                {
                                    helpers.collection().map(
                                        helpers.collection().groupBy(dados, 'status_data_formatado'),
                                        (pedidos, data) => (
                                            <div className="d-flex flex-column mb-3" key={data}>
                                                <div className="p-2 w-75">
                                                    <div className="p-1 border-radius-10 text-center"
                                                         style={{
                                                             backgroundColor: '#eaeaea'
                                                         }}>
                                                        <span className="text-bold">
                                                            {`
                                                                ${helpers.collection().capitalize(helpers.moment(data).format('dddd'))}, 
                                                                ${helpers.moment(data).format('DD')} 
                                                                de
                                                                ${helpers.collection().camelCase(helpers.moment(data).format('MMMM'))}
                                                                de
                                                                ${helpers.moment(data).format('YYYY')}
                                                            `}
                                                        </span>
                                                    </div>
                                                </div>

                                                {
                                                    helpers.collection().map(pedidos, (pedido, key) => (
                                                        <div className="p-2" key={key}>
                                                            <div
                                                                className="p-4 border border-radius-10 d-flex align-items-center justify-content-between">
                                                                <div className="d-flex flex-column">
                                                                    <span className="text-bold text-lg">
                                                                        {pedido.estabelecimento_nome}
                                                                    </span>
                                                                    <span className="text-xs text-muted">
                                                                        {`Pedido nº ${pedido.idpedido} ${status[pedido.status_integracao]}`}
                                                                    </span>
                                                                    <div className='mt-3'>
                                                                        {
                                                                            pedido.itens.map((produto, key) => (
                                                                                <div key={key}>
                                                                                    <span className="text-normal">
                                                                                        {produto.nome_produto}
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="p-1"
                                                                     onClick={async e => {
                                                                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                                                                         await helpers.awaitForTime(500);
                                                                         history.push(`/${idestabelecimento}/pedido-detalhes`, {produto: pedido});
                                                                     }}>
                                                                    <span className="" style={{
                                                                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                    }}>Ver detalhes</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        )
                                    )
                                }
                            </div>
                        )
                    )

                }

            </div>


            <Footer/>

        </div>
    );
};

export default PedidosAnteriores;
