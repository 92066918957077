import React, {useState} from 'react';
import {useSelector} from 'react-redux';

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import {helpers} from "../../components/helpers";

const PedidosDetalhes = (props) => {
    const store = useSelector(store => store);
    const [status] = useState({
        0: 'Pendente',
        1: 'Em preparo',
        3: 'Em preparo',
        4: 'Rejeitado',
        5: 'Despachado',
        8: 'Erro pagamento',
        11: 'Cancelado',
        12: 'Não despachado',
    });
    const [produto] = useState({...props.location.state.produto});

    return (
        <div className="h-100 d-flex flex-column justify-content-between scroll">

            <Header title={"Detalhes do Pedido"}/>

            <div className="p-4 flex-shrink-0 flex-grow-1 d-flex flex-column">

                <div className="p-2 flex-shrink-0 flex-grow-0 d-flex align-items-center">
                    <div className="mr-3 elevation-3" style={{
                        width: "80px",
                        height: "80px",
                        backgroundImage: !!store.app.services.estabelecimento.logomarca
                            ? `url(${store.app.services.estabelecimento.logomarca})`
                            : 'url(/images/sem-imagem.png)',
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        transition: "width 1s, height 1s"
                    }}/>
                    <div className="d-flex flex-column">
                        <span className="text-lg text-bold">{store.app.services.estabelecimento.descricao}</span>
                        <span className="text-sm mt-1">
                            <span style={{
                                float: "left",
                                width: "12px",
                                height: "12px",
                                backgroundColor: store.app.services.estabelecimento.em_funcionamento ? "#0F0" : "#F00",
                                marginTop: "2px",
                                marginRight: "2px",
                                border: "1px solid #fff",
                                borderRadius: "10px"
                            }}/>
                            {(store.app.services.estabelecimento.em_funcionamento) ? <span>Aberto</span> :
                                <span>Fechado</span>}
                            </span>
                    </div>
                </div>

                <div className="p-2 flex-shrink-0 flex-grow-0 d-flex flex-column">
                    <div className="btn btn-block btn-sm text-white border-radius-10" style={{
                        backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                    }}>
                        <span className="text-md">{`Pedido ${status[produto.status_integracao]}`}</span>
                    </div>
                </div>

                <div className="p-2 flex-shrink-0 flex-grow-1">
                    {
                        produto.itens.map((item, key) => (
                            <div key={key}>
                                <div className="mt-3 d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="text-bold text-md">{item.nome_produto}</span>
                                        <span>
                                            <span className="text-bold text-xs">{`${item.quantidade}X `}</span>
                                            <span
                                                className="text-bold text-md">{helpers.converteMoeda(item.valor)}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="p-4 mt-n3 ml-n3 w-75">
                                        {
                                            item.montagem.map((itemMontagem, keyMontagem) => (
                                                <div key={keyMontagem} className="d-flex justify-content-between border-bottom">
                                                <span className="text-muted text-sm">
                                                    {itemMontagem.nome_produto}
                                                </span>
                                                    <span className="text-bold text-xs">
                                                    {`${itemMontagem.quantidade}X `} <span
                                                        className="text-bold text-sm">{helpers.converteMoeda(itemMontagem.valor)}</span>
                                                </span>
                                                </div>
                                            ))
                                        }
                                        <span className="text-muted text-sm">{item.observacao}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column align-items-start justify-content-center">
                        <span className="text-md">Subtotal:</span>
                        <span className="text-md">Descontos:</span>
                        <span className="text-md">Entrega:</span>
                        <span className="text-lg text-bold">Total:</span>
                    </div>
                    <div className="d-flex flex-column align-items-end justify-content-center">
                        <span className="text-md">{helpers.converteMoeda(produto.valor_produtos)}</span>
                        <span className="text-md">{helpers.converteMoeda(produto.valor_desconto)}</span>
                        <span className="text-md">{helpers.converteMoeda(produto.valor_taxa)}</span>
                        <span className="text-lg text-bold">{helpers.converteMoeda(produto.valor_total)}</span>
                    </div>
                </div>

                <div className="p-2 flex-shrink-0 flex-grow-1">
                    <div className="d-flex flex-column">
                        {
                            produto.entrega_noestabelecimento === 0
                                ?
                                <>
                                    <span>Endereço de Entrega</span>
                                    <span className="text-xs text-muted">
                                        {`
                                            ${produto.entrega_logradouro},
                                            ${produto.entrega_numero}
                                        `}
                                    </span>
                                    <span className="text-xs text-muted">
                                        {`
                                            ${produto.entrega_bairronome},
                                            ${produto.entrega_cidade},
                                            ${produto.entrega_estado} -
                                            ${produto.entrega_cep}
                                        `}
                                    </span>
                                </>
                                :
                                <>
                                    <span>Endereço para Retirada do pedido</span>
                                    <span className="text-xs text-muted">
                                        {`
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.logradouro', '')},
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.numero', '')}
                                        `}
                                    </span>
                                    <span className="text-xs text-muted">
                                        {`
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.bairronome', '')},
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.cidade', '')},
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.estado', '')} -
                                            ${helpers.dot.get(store, 'app.services.estabelecimento.cep', '')}
                                        `}
                                    </span>
                                </>
                        }
                    </div>

                    <hr className="w-100"/>

                    <div className="d-flex flex-column">
                        <span>Forma de Pagamento</span>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex flex-column align-items-start justify-content-center">
                                <span className="text-xs text-muted">Numero do Pedido</span>
                                <span className="text-xs text-muted">Data do Pedido</span>
                                <span className="text-xs text-muted">Forma de pagamento</span>
                            </div>
                            <div className="d-flex flex-column align-items-end justify-content-center">
                                <span className="text-xs text-muted">{produto.idpedido}</span>
                                <span className="text-xs text-muted">
                                    {`
                                        ${helpers.collection().capitalize(helpers.moment(produto.status_data_formatado).format('dddd'))},
                                        ${helpers.moment(produto.status_data_formatado).format('DD')}
                                        de
                                        ${helpers.collection().camelCase(helpers.moment(produto.status_data_formatado).format('MMMM'))}
                                        de
                                        ${helpers.moment(produto.status_data_formatado).format('YYYY')}
                                    `}
                                </span>
                                <span className="text-xs text-muted">
                                    {
                                        helpers.dot.get(produto, 'pagamentos.0.forma_nome')
                                            ?
                                            <span className="text-xs text-muted">
                                        {`
                                            ${helpers.dot.get(produto, 'pagamentos.0.forma_nome')}
                                             ${
                                            helpers.dot.get(produto, 'pagamentos.0.bandeira')
                                                ? ` - (${helpers.dot.get(produto, 'pagamentos.0.bandeira')})`
                                                : ''
                                        }
                                        `}
                                    </span>
                                            : ''
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer/>

        </div>

    );
};

export default PedidosDetalhes;
