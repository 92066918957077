import React, {useEffect, useRef, useState} from 'react';
import ReactInputMask from 'react-input-mask';
import {useField} from '@unform/core';
import {helpers} from "../../components/helpers";

const InputCep = ({name, buttonAttributes = {}, ...rest}) => {
    const inputRef = useRef(null);
    const {fieldName, registerField, defaultValue, error} = useField(name);
    const invalid = error ? 'is-invalid' : '';

    const [state] = useState({
        deliveryAzulBotaoBuscarCep: helpers.isDeliveryAzul() ? 'btn-danger-buscar-cep-delivery-azul' : ''
    });

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <div className="input-group input-group-sm">
            <ReactInputMask ref={inputRef} defaultValue={defaultValue}
                            {...rest} className={rest.className + " " + invalid}/>
            <span className="input-group-append">
                <button type={(!!buttonAttributes.type) ? buttonAttributes.type : 'submit'}
                        onClick={() => {
                            if (!!buttonAttributes.onClick) {
                                buttonAttributes.onClick()
                            }
                        }}
                        className={`btn btn-danger ${state.deliveryAzulBotaoBuscarCep} btn-flat`}>Buscar</button>
            </span>
            {error &&
            <span id="exampleInputEmail1-error" className="error invalid-feedback">{error}</span>}
        </div>
    );
};

export default InputCep;
