import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {Progress} from "react-sweet-progress";
import Services from "../../services";

const Loading = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {idestabelecimento, mesa} = useParams();

    const [state, setState] = useState({
        ...props,
        loading: {
            loaded: false,
            message: '',
            percent: 0,
            status: 'default',
            messageerror: ''
        }
    });

    const loading = (options) => {
        setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                percent: options.percent ? options.percent : state.loading.percent,
                message: options.message ? options.message : state.loading.message,
                loaded: options.loaded ? options.loaded : state.loading.loaded,
                status: options.status ? options.status : state.loading.status,
                messageerror: options.messageerror ? options.messageerror : state.loading.messageerror,
            }
        }));
    };

    const services = useCallback(async () => {
        try {
            dispatch({type: 'LIMPAR'});
            dispatch({type: 'ESTABELECIMENTO_SERVICE', response: []});

            if (!!mesa) {
                dispatch({type: 'INDOOR', indoor: {mesa: mesa}})
            }

            loading({percent: 20, message: 'Carregando Dados do Estabelecimento'});

            setInterval(async () => {
                const estabelecimento = await Services.getEstabelecimento(idestabelecimento);
                dispatch({type: 'ESTABELECIMENTO_SERVICE', response: estabelecimento.data});
                const formasPagamento = await Services.getFormasPagamantos(estabelecimento.data);
                dispatch({type: 'FORMAS_PAGAMENTO_SERVICE', response: {estabelecimento: estabelecimento.data, formasPagamento: formasPagamento.data}});
            }, 60000);

            const estabelecimento = await Services.getEstabelecimento(idestabelecimento);
            if (!estabelecimento.data) {
                history.push(`/${idestabelecimento}/*`);
                return false;
            }
            dispatch({type: 'ESTABELECIMENTO_SERVICE', response: estabelecimento.data});
            const formasPagamento = await Services.getFormasPagamantos(estabelecimento.data);
            dispatch({type: 'FORMAS_PAGAMENTO_SERVICE', response: {estabelecimento: estabelecimento.data, formasPagamento: formasPagamento.data}});

            if (!estabelecimento.data) {
                history.push(`/${idestabelecimento}/inexistente`);
                return false;
            }

            loading({percent: 40, message: 'Carregando Lista de Produtos'});
            const produtos = await Services.listProdutosCategorias(estabelecimento.data);
            dispatch({
                type: 'PRODUTOSCATEGORIAS_SERVICE',
                response: produtos.data
            });

            loading({percent: 60});
            const kits = await Services.listKits(estabelecimento.data);
            dispatch({type: 'KITS_SERVICE', response: kits.data});

            loading({percent: 80});
            const pizza = await Services.listPizza(estabelecimento.data);
            dispatch({type: 'PIZZA_SERVICE', response: pizza.data});

            loading({percent: 90});
            const lansec = await Services.listLansec(estabelecimento.data);
            dispatch({type: 'LANSEC_SERVICE', response: lansec.data})

            loading({percent: 100, message: 'Concluído', status: 'success'});
            setTimeout(() => {
                dispatch({type: 'LOADED_SERVICE', loaded: true});
                window.appControll.loaded = true;
                history.push(`/${idestabelecimento}/home`);
            }, 2000);

        } catch (error) {
            loading({status: 'error', messageerror: 'Falha no carregamento'});
        }
    }, [dispatch, history, idestabelecimento, mesa]);

    useEffect(() => {
        services();
    }, [services]);

    return (
        <div className="w-100 h-100">

            <div className={'d-flex flex-column align-items-center justify-content-center v-100 h-100'}>
                <div className="p-2">
                    <Progress
                        percent={state.loading.percent}
                        status={state.loading.status}
                        theme={
                            {
                                error: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'pink',
                                    color: 'red'
                                },
                                default: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'lightblue',
                                    color: 'blue'
                                },
                                active: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'yellow',
                                    color: 'orange'
                                },
                                success: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'lime',
                                    color: 'green'
                                }
                            }
                        }
                        type={'circle'}
                        width={200}
                        style={{margin: '0 0 0 10px'}}
                    />
                </div>
                <div className="p-2">
                    <span><strong>{state.loading.message}</strong></span>
                </div>
                <div className="p-2">
                    <span><strong>{state.loading.messageerror}</strong></span>
                </div>
            </div>

        </div>
    );
};

export default Loading;
