import Mastercard from '../images/mastercard.svg'
import Visa from '../images/visa.svg'
import Hipercard from '../images/hipercard.svg'
import Elo from '../images/elo.svg'
import Amex from '../images/amex.svg'
import Diners from '../images/diners.svg'
import Discover from '../images/discover.svg'
import Jcb from '../images/jcb.svg'

export default {
    Mastercard,
    Visa,
    Hipercard,
    Elo,
    Amex,
    Diners,
    Discover,
    Jcb
}
