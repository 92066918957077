import React, {useCallback, useEffect, useRef, useState} from 'react';
import Services from "../../services";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {helpers} from "../../components/helpers";

/*Layout*/
import Header from "../../components/layout/header";

/*Form*/
import {Form} from "@unform/web";
import Input from "../../components/Form/input";
import * as Yup from 'yup';

/*Icons*/
import deliverymenewlogo from '../../assets/images/deliverymenew-logo.svg'

const RecuperarSenha = () => {
    const {idestabelecimento, token} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const [validToken, setValidToken] = useState(true);

    const checkToken = useCallback(async () => {
        const tokenResponse = await Services.usuarioCheckTokenResetPassword({
            token: token
        });
        helpers.collection().first(tokenResponse.data) === 1 ? setValidToken(true) : setValidToken(false);
    }, [token]);

    useEffect(() => {
        checkToken();
    }, [checkToken]);

    const handleSubmit = async (e) => {
        try {
            await helpers.animatedElement(e, 'bounceIn', 'fast');
            await helpers.awaitForTime(500);
            dispatch({type: 'PRE_LOAD', preLoad: true});
            const formData = formRef.current.getData();
            const schema = Yup.object().shape({
                nova_senha: Yup.string().required('Digite sua senha'),
                confirme: Yup.string().required('Confirme sua senha').oneOf([Yup.ref('nova_senha'), null], 'Senhas divergentes')
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            const reset = await Services.usuarioResetPassword({
                token: token,
                "nova_senha": formData.nova_senha
            });
            dispatch({type: 'PRE_LOAD', preLoad: false});

            if (helpers.collection().first(reset.data) === 1) {
                helpers.toast.success('Senha alterada com sucesso');
                history.push(`/${idestabelecimento}/login`)
            } else {
                helpers.toast.error('Sua senha ão foi modificada, por favor tente novamente ou entre em contato com o suporte técnico!');
            }

            formRef.current.setErrors({});
        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                dispatch({type: 'PRE_LOAD', preLoad: false});
                helpers.toast.error('Por favor verifique os dados e tente novamente');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    return (
        <div className="h-100 d-flex flex-column justify-content-between scroll scroll-position-initial">

            <Header recuperarSenha={true} title={"Recuperar Senha"}/>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column justify-content-center">

                <div className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img className="img-fluid" src={deliverymenewlogo} alt="deliverymenew-logo"
                         width={"200px"}/>
                </div>

                {
                    validToken
                        ?
                        <>
                            <div
                                className="p-2 flex-shrink-0 d-flex flex-column align-items-center justify-content-center">
                                <Form ref={formRef}
                                      className="w-75">
                                    <div className="p-1">
                                        <Input type="password" name={"nova_senha"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="Nova Senha"
                                               style={{backgroundColor: '#e6e6e6'}}/>
                                    </div>
                                    <div className="p-1">
                                        <Input type="password" name={"confirme"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="Confirme sua senha"
                                               style={{backgroundColor: '#e6e6e6'}}/>
                                    </div>
                                    <button type="submit" className="d-none"/>
                                </Form>
                            </div>

                            <div
                                className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                                <div className="p-1 w-50">
                                    <button type="button" className="btn btn-block menew__btn-danger btn-lg"
                                            onClick={handleSubmit}>
                                        <strong>Continuar</strong>
                                    </button>
                                </div>
                            </div>
                        </>
                        :
                        <div
                            className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column align-items-center justify-content-start">
                            <div className="p-1">
                                <span className="text-lg text-bold">{'Link inválido'}</span>
                            </div>
                            <div className="p-1">
                                <button type="button" className="btn btn-block menew__btn-danger btn-lg"
                                        onClick={e => {
                                            history.push(`/${idestabelecimento}/login`);
                                        }}>
                                    <strong>Voltar para login</strong>
                                </button>
                            </div>
                        </div>

                }

            </div>

        </div>
    );
};

export default RecuperarSenha;
