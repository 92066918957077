import axios from 'axios';
import {helpers} from "../components/helpers";
import query from "querystring";

let urlBase = ''

if (window.location.hostname == 'localhost'){
    urlBase = `http://localhost:8080`;
}

if (window.location.hostname == 'delivery-menew.dev.menew.cloud'){
    urlBase = `https://delivery-menew-api.dev.menew.cloud`;
}

if (window.location.hostname == 'delivery-menew.hom.menew.cloud'){
    urlBase = `https://delivery-menew-api.hom.menew.cloud`;
}

if (window.location.hostname == 'delivery-menew.prod.menew.cloud' || window.location.hostname == 'delivery.menew.com.br' || window.location.hostname == 'cardapio.menew.com.br'){
    urlBase = `https://delivery-menew-api.prod.menew.cloud`;
}

const Services = {
    getEstabelecimento: async (slug) => {
        try {
            return await axios.post(`${urlBase}/api/estabelecimento/get`, {
                slug: slug,
                plataforma: helpers.isCardapio() ? 'cardapio' : 'delivery'
            });
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10001)');
        }
    },
    getFormasPagamantos: async (estabelecimento) => {
        try {
            return await axios.post(`${urlBase}/api/formas_pagamento/get`, {'estabelecimento_id': estabelecimento.id});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10001)');
        }
    },
    listKits: async (estabelecimento) => {
        try {
            return await axios.post(`${urlBase}/api/kits/find`, {'estabelecimento_id': estabelecimento.id});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10002)');
        }
    },
    listPizza: async (estabelecimento) => {
        try {
            return await axios.post(`${urlBase}/api/pizza/find`, {'estabelecimento_id': estabelecimento.id});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10003)');
        }
    },
    listProdutosCategorias: async (estabelecimento) => {
        try {
            return await axios.post(`${urlBase}/api/produtos_categoria_delivery/find`, {'estabelecimento_id': estabelecimento.id});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10004)');
        }
    },
    pedidoSendPortal: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/pedido/send`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10006)');
        }
    },
    getCep: async (cep) => {
        try {
            return await axios.get('https://apps.widenet.com.br/busca-cep/api/cep/' + cep + '.json');
            // return await axios.post('/api/get_cep', {cep: cep});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10005)');
        }
    },
    mundiPagg: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/mundi_pag`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10007)');
        }
    },
    googleGeoLocation: async (data) => {
        // const params = query.stringify({
        //     address: `${data.address} || ${data.logradouro}, ${data.number} || ${data.numero},
        //      ${data.district} || ${data.bairro}, ${data.city} || ${data.cidade},
        //       ${data.state} || ${data.estado}, Brasil, ${data.code} || ${data.cep}`,
        // });

        const params = `CEP ${data.cep}, ${data.estado}, ${data.cidade}, ${data.bairro}, ${data.logradouro}`

        const response = await axios.post(`${urlBase}/api/google_geo_location`, {params:params});
        const addressComponents = {
            "street_number": 'numero',
            "postal_code": 'cep',
            "sublocality_level_1": 'bairro',
            "administrative_area_level_2": 'cidade',
            "administrative_area_level_1": 'estado',
            "country": 'pais'
        };

        const address = {};
        response.data.map(result => {
            // result.address_components.map(component => {
            //     component.types.map(type => {
            //         if (type in addressComponents) {
            //             address[addressComponents[type]] = component.long_name;
            //         }
            //         return true;
            //     });
            //     return true;
            // });
            address.postal_code = result.postal_code;
            address.sublocality_level_1 = result.sublocality_level_1;
            address.administrative_area_level_2 = result.administrative_area_level_2;
            address.administrative_area_level_1 = result.administrative_area_level_1;
            address.country = result.country;
            address.street_number = result.street_number;
            address.formattedAddress = result.formatted_address;
            address.location = {
                lat: result.lat,
                lng: result.lng
            };
            return true;
        });

        return address;
    },
    calcTaxaEntrega: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/delivery_estabelecimento/calc_taxaEntrega`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10008)');
        }
    },
    login: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/login`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10009)');
        }
    },
    usuarioUpdate: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/update`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10010)');
        }
    },
    usuarioEnderecoUpdate: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/endereco/update`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10011)');
        }
    },
    usuarioEnderecoGet: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/endereco/get`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10012)');
        }
    },
    usuarioPedidosGet: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/pedidos/get`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10013)');
        }
    },
    usuarioPasswordRecovery: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/passwordRecovery`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10014)');
        }
    },
    usuarioCheckTokenResetPassword: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/checkTokenResetPassword`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10015)');
        }
    },
    usuarioResetPassword: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/usuario/resetPassword`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10016)');
        }
    },
    responderPesquisa: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/pesquisa/responder`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10016)');
        }
    },
    checkPesquisa: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/pesquisa/check`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10016)');
        }
    },
    cuponsGet: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/cuponsGet`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10017)');
        }
    },
    mundiPaggAddCustomers: async (data) => {
        try{
            return await axios.post(`${urlBase}/api/mundiPagg_add_customers`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10018)');
        }
    },
    mundiPaggGetCustomers: async (data) => {
        try{
            return await axios.post(`${urlBase}/api/mundiPagg_get_customers`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10019)');
        }
    },
    mundiPaggGetAllCards: async (data) => {
        try{
            return await axios.post(`${urlBase}/api/mundiPagg_getAll_cards`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10020)');
        }
    },
    mundiPaggGetCards: async (data) => {
        try{
            return await axios.post(`${urlBase}/api/mundiPagg_get_cards`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10021)');
        }
    },
    mundiPaggAddCards: async (data) =>{
        try{
            return await axios.post( `${urlBase}/api/mundiPagg_add_cards`, {data:data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10022)');
        }
    },
    saveCardsPortal: async (data) => {
        try{
            return await axios.post( `${urlBase}/api/save_cards_portal`, {data:data});
        }catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10023)');
        }
    },
    mundiPaggRemoveCards: async (data) => {
        try{
            return await axios.post( `${urlBase}/api/mundiPagg_remove_cards`,{data:data});
        }catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10024)');
        }
    },
    mundiPaggEditCards: async (data) => {
        try{
            return await axios.post(`${urlBase}/api/mundiPagg_edit_cards`,{data:data});
        }catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10025)');
        }
    },
    listLansec: async (estabelecimento) => {
        try {
            return await axios.post(`${urlBase}/api/lansec/find`, {'estabelecimento_id': estabelecimento.id});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10003)');
        }
    },
    gerarCodigoPesquisa: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/code_pesquisa`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10017)');
        }
    },
    validarCodigoPesquisa: async (data) => {
        try {
            return await axios.post(`${urlBase}/api/validate_code_pesquisa`, {data: data});
        } catch (error) {
            console.log(error.response);
            helpers.toast.warn('Tente novamente ou entre em contato com o suporte técnico. (Cód: 10017)');
        }
    }
};

export default Services;
