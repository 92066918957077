import React from 'react';

const IconEdit = ({
                  style = {},
                  fill = "#ff1200",
                  width = "100%",
                  className = "",
                  x = "0px", y = "0px",
                  viewBox = "0 0 100 100",
                  xmlns = "http://www.w3.org/2000/svg",
                  xmlnsXlink = "http://www.w3.org/1999/xlink",
              }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M18,94c-2.66,0-5.31,0-8,0-.66,0-1-.07-1-.87,0-5.38,0-10.76,0-16.14a1.57,1.57,0,0,1,.55-1.25q25.53-25.5,
              51-51c.53-.53.8-.48,1.3,0Q70.12,33,78.35,41.14c.53.52.5.79,0,1.3q-25.53,25.5-51,51a1.76,1.76,0,0,
              1-1.37.53C23.28,94,20.63,94,18,94Z"/>
        <path d="M94,24.74a4.68,4.68,0,0,1-1.32,3.32c-2.72,2.75-5.47,5.47-8.19,8.23-.41.41-.64.38-1,0Q75.14,27.93,66.8,
              19.62c-.4-.4-.44-.63,0-1.05,2.75-2.71,5.45-5.49,8.23-8.18a4.58,4.58,0,0,1,6.57.07c3.7,3.65,7.36,7.34,
              11,11A4.77,4.77,0,0,1,94,24.74Z"/>

    </svg>
);

export default IconEdit;
