import React from 'react';

const IconBag = ({
                     style = {},
                     fill = "#fff",
                     width = "100%",
                     className = "",
                     x = "0px", y = "0px",
                     viewBox = "0 0 100 100",
                     xmlns = "http://www.w3.org/2000/svg",
                     xmlnsXlink = "http://www.w3.org/1999/xlink",
                 }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M-13.7,44.4c-6.1-5.9-12.3-12-18.3-17.8l-6-5.9c-0.8-0.8-1.6-1.5-2.3-2.3c-1.3-1.3-2.6-2.6-3.9-3.9
	c-3.3-3.1-8.3-3.1-11.6,0.2l-9.8,9.5c-6.9,6.7-13.9,13.3-20.8,20c-1.6,1.5-3,3.1-3.6,5.4l0,2.9l0.2,0.2c0,0,0,0,0,0
	c0.8,2.9,2.5,4.8,5.1,5.6c0,7.1,0,14.3,0,21.4l0,3.2c0,5.5,3.9,9.4,9.4,9.4c2,0,4.1,0,6.1,0c0.6,0,1.2,0,1.8-0.1
	c4.6-0.9,7.5-4.5,7.5-9.2l0-5.4c0-2.6,0-5.2,0-7.8c0-3.5,2.5-6.3,5.9-6.6c2.3-0.2,4.6-0.2,6.7,0c3.6,0.2,6.4,3.3,6.3,7
	c-0.1,2.9-0.1,5.9,0,8.7c0,1.3,0,2.7,0,4c0,5.4,3.9,9.4,9.3,9.4c0.8,0,1.6,0,2.5,0c1.2,0,2.4,0,3.6,0c0.1,0,0.2,0,0.2,0
	c2.4,0,4.8-1,6.5-2.7c1.8-1.8,2.8-4.2,2.7-6.8c-0.1-5.1-0.1-10.3-0.1-15.4c0-3,0-6,0-8.9c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0
	c2.9-0.9,4.7-3,5.2-6C-10.5,49.3-11.4,46.7-13.7,44.4z M-22.8,73.4l0,3.1c0,1.6,0,3.1,0,4.7l0,2.2c0,1.3-0.6,2-1.9,2
	c-1.8,0-3.7,0-5.5,0l-0.7,0c-0.7,0-1.2-0.3-1.4-0.6c-0.4-0.4-0.6-1-0.6-1.5c0.1-3.2,0.1-6.5,0-9.7l0-3.3c0-7.7-6-13.9-13.7-14
	l-1.4,0c-0.9,0-1.8-0.1-2.7-0.1c-1.4,0-2.7,0.1-4.1,0.3c-6.6,1-11.6,6.9-11.7,13.6l0,0.7c0,4.1-0.1,8.4,0,12.6
	c0,0.7-0.2,1.2-0.5,1.6c-0.4,0.4-0.9,0.6-1.6,0.6c0,0,0,0,0,0l-0.9,0c-1.8,0-3.6,0-5.5,0c-0.6,0-1.2-0.2-1.6-0.6
	c-0.3-0.3-0.6-0.8-0.6-1.5c0.1-3.1,0.1-6.2,0-9.2c0-0.8,0-1.7,0-2.5l0.1-14.2c0-3-2-5.4-4.9-5.9c-0.3,0-0.4-0.2-0.7-0.6
	C-72.1,40.7-61.6,30.5-51,20.3l0.3-0.3c0.4-0.4,0.5-0.5,0.6-0.5c0.4,0,0.6,0.1,0.8,0.3C-38.7,30-28.1,40.2-17.5,50.4
	c0.1,0.1,0.2,0.2,0,0.8c-0.1,0.4-0.2,0.4-0.4,0.5c-3,0.5-5,3-5,6C-22.8,62.9-22.8,68.1-22.8,73.4z"/>
        <g>
            <path d="M172.4,45.4c0-11.7-9.6-21.3-21.3-21.3c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c7.6,0,13.8,6.2,13.8,13.8
		c0,2.1,1.7,3.7,3.7,3.7C170.7,49.1,172.4,47.5,172.4,45.4z"/>
            <path d="M196.8,85.2l-20-20c4.3-5.5,6.8-12.4,6.8-19.8c0-17.9-14.6-32.5-32.5-32.5c-17.9,0-32.5,14.6-32.5,32.5
		s14.6,32.5,32.5,32.5c7.8,0,14.9-2.7,20.5-7.3l19.9,19.9c0.7,0.7,1.7,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1
		C198.2,89,198.2,86.7,196.8,85.2z M126.1,45.4c0-13.8,11.2-25,25-25s25,11.2,25,25s-11.2,25-25,25S126.1,59.2,126.1,45.4z"/>
        </g>
        <g>
            <path d="M23.6,90c-1.4-0.6-2.8-1.1-3.8-2.3c-1.8-1.9-2.3-4.1-2.1-6.6C19.3,66,21,51,22.7,35.9c0.5-4.7,3.5-7.2,8.2-7.2
		c1.6,0,3.1-0.1,4.7,0c1.4,0.1,1.7-0.4,1.6-1.7c-0.1-1.5,0-3,0.1-4.5C37.8,15.4,44,9.8,51.2,10c7.2,0.2,13.1,6.1,13.3,13.2
		c0,1.4,0.1,2.7,0,4.1c-0.1,1.1,0.2,1.6,1.4,1.5c1.2-0.1,2.4,0,3.6,0c4.6,0,7.5,2.6,8.1,7.2c0.7,5.7,1.3,11.5,1.9,17.2
		c1,8.9,1.9,17.8,2.9,26.7c0.6,5.6-0.5,7.8-5.7,10.1C59,90,41.3,90,23.6,90z M50.2,85c7.9,0,15.9,0,23.8,0c3.3,0,4-0.9,3.6-4.2
		c-1-9.1-2-18.1-3-27.2c-0.6-5.8-1.3-11.6-1.9-17.4c-0.2-1.7-1.1-2.5-2.8-2.5c-1.4,0-2.8,0-4.2,0c-0.3,0-0.7-0.1-1,0.3
		c-0.5,0.8,0.2,4,0.9,4.5c2.4,1.7,3.4,4.4,2.5,7.1c-0.8,2.5-3.2,4.3-5.9,4.3c-2.7,0-5.2-1.8-6-4.4c-0.8-2.7,0.1-5.4,2.6-7.1
		c0.7-0.5,1.3-3.9,0.8-4.5c-0.1-0.2-0.5-0.2-0.7-0.2c-5.2,0-10.4,0-15.6,0c-0.3,0-0.7-0.1-0.9,0.4c-0.5,1.3,0.2,3.9,1.3,4.8
		c2.8,2.3,3,6.4,0.5,9c-2.4,2.5-6.5,2.6-9,0.1c-2.5-2.6-2.5-6.9,0.4-9c1.8-1.2,1.8-2.6,1.8-4.4c0-0.8-0.2-1.1-1.1-1.1
		c-1.8,0.1-3.6,0-5.5,0c-1.9,0-2.8,0.8-3,2.7c-0.2,1.4-0.3,2.9-0.4,4.4c-1.5,13.6-3,27.1-4.6,40.7C22.4,84,23.3,85,25.9,85
		C34,85,42.1,85,50.2,85z M50.9,28.7c2.5,0,5,0,7.5,0c1,0,1.3-0.3,1.2-1.2c-0.1-1.4,0-2.8,0-4.2c-0.2-4.5-4-8.2-8.6-8.3
		c-4.5-0.1-8.4,3.4-8.7,7.9c-0.1,1.5,0,3-0.1,4.5c-0.1,1.1,0.4,1.3,1.3,1.3C46,28.7,48.4,28.7,50.9,28.7z M40.8,43.7
		c-0.1-0.7-0.5-1.2-1.2-1.2c-0.8,0-1.3,0.4-1.3,1.2c0,0.7,0.4,1.2,1.1,1.2C40.2,45,40.6,44.5,40.8,43.7z M61.9,42.5
		c-0.6,0.2-1.1,0.5-1.1,1.2c0,0.8,0.5,1.3,1.3,1.2c0.7,0,1.2-0.5,1.2-1.2C63.3,42.9,62.8,42.5,61.9,42.5z"/>
        </g>

    </svg>
);

export default IconBag;
