import React, { useEffect, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { helpers } from "../../components/helpers";
import Services from "../../services";
import $ from 'jquery';
import 'jquery-mask-plugin';

/*Layout*/
import Header from '../../components/layout/header/'
import Footer from '../../components/layout/footer/'
// import Input from "../../components/Form/inputPesquisa";
import Swal from 'sweetalert2';

/*Images*/
import ReloadControll from "../../components/reload-controll";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";
import IconFechar from "../../components/Icons/icon-fechar";


function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

if (!localStorage.getItem('menew_uuid')) {
    localStorage.setItem('menew_uuid', uuidv4());
}
var uuid = localStorage.getItem('menew_uuid');

const Pesquisa = (props) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const [isAnswered, setIsAnswered] = useState(null);
    const [numberPhone, setNumberPhone] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [codigoValidacao, setCodigoValidacao] = useState('');
    const [vezesCodigoSMS, setVezesCodigoSMS] = useState(0);

    const inputRef = useRef(null);

    $('input[name=telefone]').mask('(00) 0 0000-0000', {
        placeholder: '(00) 0 0000-0000'
    });

    useEffect(() => {
    }, [isAnswered]);

    if (helpers.reloadControl()) {
        return (
            <ReloadControll />
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel />
        );
    }

    const perguntaChange = (e) => {
        var myForm = document.getElementById('form');
        var formData = new FormData(myForm),
            result = {};

        for (var entry of formData.entries()) {
            result[entry[0]] = entry[1];
        }

        dispatch({
            type: 'PESQUISA',
            pesquisa: { ...result }
        });

    };

    function FormElement(props) {

        let { pergunta } = props;

        if (pergunta.tipo === 'e') {
            return <select className="form-control" name={"pergunta_" + pergunta.id} onChange={perguntaChange}
                defaultValue={store.app.modules.pesquisa[`pergunta_${pergunta.id}`]}>
                <option value=""></option>
                {
                    store.app.services.estabelecimento.grupos[0].estabelecimentos.map((estabelecimento, idx) => {
                        return <option key={idx + '-' + estabelecimento.id}
                            value={estabelecimento.id}>{estabelecimento.descricao}</option>;
                    })
                }
            </select>;
        }
        if (pergunta.tipo === 'o') {
            return <select className="form-control" name={"pergunta_" + pergunta.id} onChange={perguntaChange}
                defaultValue={store.app.modules.pesquisa[`pergunta_${pergunta.id}`]}>
                <option value=""></option>
                {
                    pergunta.opcoes.map((opcao, idx) => {
                        return <option key={idx + '-' + opcao.id} value={opcao.value}>{opcao.label}</option>;
                    })
                }
            </select>;
        } else {
            return <span>Sem opções</span>;
        }
    }

    var sending = false;
    const FrmSubmit = async (e) => {
        if (!sending) {
            sending = true;
            var myForm = document.getElementById('form');
            var formData = new FormData(myForm),
                result = {};
            for (var entry of formData.entries()) {
                if(entry[1]==""){
                    helpers.toast.error('Você precisa preencher todo o formulário.');
                    return false;
                }
                result[entry[0]] = entry[1];
            }
            let numeroTelefone;
            if(numberPhone !== ''){
                numeroTelefone = numberPhone;
            }else{
                numeroTelefone = telefone.replace(/[^\d]+/g,'')
            }

            let resposta = {
                uuid: `${numeroTelefone}`,
                pesquisa: store.app.services.estabelecimento.grupos[0].pesquisas[0],
                cartao: result,
                grupo: store.app.services.estabelecimento.grupos[0].id,
                estabelecimento: store.app.services.estabelecimento.id,
                usuario_email: email
            };
            await Services.responderPesquisa(resposta);
            helpers.toast.success('Informações enviadas. Obrigado!');
            setCookie('telefoneUsuario', numeroTelefone, 30)
            setTimeout(function () {
                window.location.href = "/" + store.app.services.estabelecimento.slug + "/home"
            }, 3000);
            sending = false;
        }
        return false;
    };

    const ShowModalSMS = async (e) => {
        sending = true;
        var myForm = document.getElementById('form');
        var formData = new FormData(myForm),
            result = {};
        for (var entry of formData.entries()) {
            if(entry[1]==""){
                helpers.toast.error('Você precisa preencher todo o formulário.');
                return false;
            }
        }

        var myForm = document.getElementById('form2');
        var formData = new FormData(myForm),
            result = {};
        for (var entry of formData.entries()) {
            if(entry[1]==""){
                helpers.toast.error('Você precisa preencher todo o formulário.');
                return false;
            }
        }
        let numeroTelefone;
        if(numberPhone !== ''){
            numeroTelefone = numberPhone;
        }else{
            numeroTelefone = telefone.replace(/[^\d]+/g,'')
        }
        let dataCheck = {
            uuid: `${numeroTelefone}`,
            pesquisa: store.app.services.estabelecimento.grupos[0].pesquisas[0],
            grupo: store.app.services.estabelecimento.grupos[0].id,
            estabelecimento: store.app.services.estabelecimento.id
        };
        let response2 = Services.checkPesquisa(dataCheck);

        response2.then(async function (result) {
            result = (result.data[0]);
            if (result.length == 0 || result.notfound == true) {
                setIsAnswered(false);

                if(numberPhone !== '') {
                    sending = false;
                    FrmSubmit();
                }else{
                    $('#modalSMS').modal('show')
                    if(vezesCodigoSMS <= 5) {
                        const retorno = await Services.gerarCodigoPesquisa({numeroTelefone: `+55${numeroTelefone}`});

                        var vezes = vezesCodigoSMS;
                        setVezesCodigoSMS(vezes+1)
                    }
                }
                sending = false;
            } else {
                setIsAnswered(true);
            }
        })
    }

    const validarCodigo = async (e) => {
        sending = true;
        let numeroTelefone;
        if(numberPhone !== ''){
            numeroTelefone = numberPhone;
        }else{
            numeroTelefone = telefone.replace(/[^\d]+/g,'')
        }
        const retorno = await Services.validarCodigoPesquisa({
            numeroTelefone: `+55${numeroTelefone}`,
            codigoDigitado: `${codigoValidacao}`
        });

        if (retorno.data === 'Código é válido.') {
            sending = false;
            FrmSubmit();
        } else {
            sending = false;
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success m-1'
                },
                buttonsStyling: false
            })
            await swalWithBootstrapButtons.fire({
                title: 'Código inválido!',
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'OK',
            }).then(async (result) => {
            })
        }
    }

    const reenviarCodigo = async (e) => {
        sending = true;
        const numeroTelefone = telefone.replace(/[^\d]+/g,'')
        if(vezesCodigoSMS <= 5) {
            console.log(vezesCodigoSMS)
            const retorno = await Services.gerarCodigoPesquisa({numeroTelefone: `+55${numeroTelefone}`});

            var vezes = vezesCodigoSMS;
            setVezesCodigoSMS(vezes+1)
        }

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success m-1'
            },
            buttonsStyling: false
        })
        await swalWithBootstrapButtons.fire({
            title: 'Código reenviado!',
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
        }).then(async (result) => {
        })
        sending = false;
    }

    const closePopup = () => {
        // document.getElementById("popupPremioCantaloupe").style.display = "none";
        localStorage.setItem('showPopupPesquisa', 0);

        $('#modalSMS').modal('hide')
        return false;
    };

    const setCookie = (name, value, days) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    const getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    const numeroTelefone = getCookie('telefoneUsuario');
    if (isAnswered === null) {
        let dataCheck = {
            uuid: `${numeroTelefone}`,
            pesquisa: store.app.services.estabelecimento.grupos[0].pesquisas[0],
            grupo: store.app.services.estabelecimento.grupos[0].id,
            estabelecimento: store.app.services.estabelecimento.id
        };
        let response2 = Services.checkPesquisa(dataCheck);

        response2.then(function (result) {
            result = (result.data[0]);
            if (result.length == 0 || result.notfound == true) {
                setIsAnswered(false);
            } else {
                setIsAnswered(true);
            }
        })
    };

    if(numeroTelefone !== null && numberPhone === ''){
        setNumberPhone(numeroTelefone);
    }

    return (

        <div className="d-flex flex-column h-100">

            <Header title={"Pesquisa"} />

            <div className="pt-4 pl-4 pr-4 pb-0 flex-grow-1 scroll position-relative">

                {(isAnswered === false) &&

                <div className="card pt-4">
                    <center><img style={{maxHeight: "100px", maxWidth: "100px"}}
                                 src={"https://www.portalmenew.com.br/wservice/uploads/Temp/" + store.app.services.estabelecimento.grupos[0].pesquisas[0].img}
                                 alt={'pesquisa'}/></center>
                    <h3 className="text-center mt-3">{store.app.services.estabelecimento.grupos[0].pesquisas[0].nome}</h3>
                    <p className="m-4">
                        Oi. Bem-vindo à votação do RW. <br/>
                        Queremos a sua opinião e o seu voto, viu? Afinal, o RW foi feito para você. <br/><br/>
                        Antes de votar, entenda melhor o Prêmio Cantaloupe: <br/><br/>
                        Importante: o voto é pessoal e só vale votar em um menu por vez. E, claro, você tem que
                        experimentar primeiro para depois votar. <br/><br/>
                        Se você está almoçando ou jantando com alguém, os dois podem votar, tá? Afinal, cada um tem a
                        sua opinião. <br/><br/>
                        Tudo certo? Então <a style={{textDecoration: "underline", color: "#007bff"}}
                                             onClick={() => window.location.href = "/" + store.app.services.estabelecimento.slug + "/pesquisa#form"}>clica
                        aqui</a> e vote já!
                    </p>
                    <hr/>
                    <div className="card-body">
                        <form id="form" method="POST">
                            {
                                helpers.collection().cloneDeep(store.app.services.estabelecimento.grupos[0].pesquisas[0].perguntas).map(pergunta => {
                                    return <div className="mb-4" key={pergunta.id}>
                                        <label>{pergunta.nome}</label>
                                        <FormElement pergunta={pergunta}/>
                                    </div>
                                })
                            }

                            {/*<h4 className="text-left mb-3">{'Dados do cliente: '}</h4>*/}
                            {/*<div className="mb-4">*/}
                            {/*    <label>{'Telefone'}</label>*/}
                            {/*    <input type="text" value={telefone} onChange={(event) => {*/}
                            {/*        setTelefone(event.target.value)*/}
                            {/*    }} name={"telefone"} ref={inputRef} style={{backgroundColor: '#ffffff'}}*/}
                            {/*           className={"p-3 form-control border-1 border-radius-10 text-sm white"}/>*/}
                            {/*</div>*/}
                            {/*<div className="mb-4">*/}
                            {/*    <label>{'Email'}</label>*/}
                            {/*    <input type="text" name={"email"} value={email} onChange={(event) => {*/}
                            {/*        setEmail(event.target.value)*/}
                            {/*    }} className="p-3 form-control border-1 border-radius-10 text-sm"*/}
                            {/*     style={{backgroundColor: '#ffffff'}}/>*/}
                            {/*</div>*/}
                            {/*<button onClick={ShowModalSMS} disabled={sending} type="button" className="btn btn-info float-right">Enviar*/}
                            {/*</button>*/}
                        </form>
                    </div>

                    <div className="card-body">
                        <form id="form2" method="POST">
                            <>
                                {
                                    numberPhone === ''

                                        ?
                                        <>
                                            <h4 className="text-left mb-3">{'Dados do cliente: '}</h4>
                                            <div className="mb-4">
                                                <label>{'Telefone'}</label>
                                                <input type="text" value={telefone} onChange={(event) => {
                                                    setTelefone(event.target.value)
                                                }} name={"telefone"} ref={inputRef} style={{backgroundColor: '#ffffff'}}
                                                       className={"p-3 form-control border-1 border-radius-10 text-sm white"}/>
                                            </div>
                                            <div className="mb-4">
                                                <label>{'Email'}</label>
                                                <input type="text" name={"email"} value={email} onChange={(event) => {
                                                    setEmail(event.target.value)
                                                }} className="p-3 form-control border-1 border-radius-10 text-sm"
                                                       style={{backgroundColor: '#ffffff'}}/>
                                            </div>
                                        </>

                                        :
                                        null
                                }
                            </>
                            <button onClick={ShowModalSMS} disabled={sending} type="button"
                                    className="btn btn-info float-right">Enviar
                            </button>

                        </form>
                    </div>
                </div>
                }

                {(isAnswered === true) &&
                <div className="card pt-4">
                    <center><img style={{maxHeight: "100px", maxWidth: "100px"}}
                                 src={"https://www.portalmenew.com.br/wservice/uploads/Temp/" + store.app.services.estabelecimento.grupos[0].pesquisas[0].img}
                                 alt={'pesquisa'}/></center>
                    <h3 className="text-center mt-3">{store.app.services.estabelecimento.grupos[0].pesquisas[0].nome}</h3>
                    <h6 className="mt-2 mb-3 ml-3 mr-3 text-center">Você já respondeu esta pesquisa para este
                        estabelecimento.</h6>
                </div>
                }

            </div>

            <Footer/>
            <div className="modal fade" id="modalSMS" data-backdrop="static" role="dialog" data-keyboard="false"
                 aria-labelledby="modalSMSLabel">
                <div className="modal-dialog" role="document" data-keyboard="false">
                    <div className="modal-content" style={{ marginTop: 70}} data-keyboard="false">
                        <div className="modal-body">
                            <div id="popupPremioCantaloupe" >
                                <div>
                            <span onClick={closePopup}><IconFechar style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                            }} className="ml-n3" width={25} fill={'#ff2200'}
                            /></span>
                                    <div width="100%" height="100%">
                                        <center><img style={{maxHeight: "100px", maxWidth: "100px"}}
                                                     src={"https://www.portalmenew.com.br/wservice/uploads/Temp/" + store.app.services.estabelecimento.grupos[0].pesquisas[0].img}
                                                     alt={'pesquisa'}/></center>
                                        <h3 className="text-center mt-3">{store.app.services.estabelecimento.grupos[0].pesquisas[0].nome}</h3>
                                        <p className="m-4">
                                            Acabamos de enviar um código por SMS, por favor digitar aqui. <br/>
                                        </p>
                                        <hr/>
                                        <div className="card-body">
                                            <p>
                                                Caso não tenha chegado, <a
                                                style={{textDecoration: "underline", color: "#007bff"}}
                                                onClick={reenviarCodigo}>aperte aqui.</a> <br/>
                                            </p>
                                            <div className="mb-4">
                                                <label>{'Código enviado por SMS'}</label>
                                                <input type="text" name={"codigoSMS"}
                                                       value={codigoValidacao} onChange={(event) => {
                                                    setCodigoValidacao(event.target.value)
                                                }}
                                                       className="p-3 form-control border-1 border-radius-10 text-sm"
                                                       style={{backgroundColor: '#ffffff'}}/>

                                                <button onClick={validarCodigo} disabled={sending} type="button"
                                                        className="btn btn-info float-right mt-2">Confirmar
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default Pesquisa;
