import React from 'react';
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {useSelector} from 'react-redux';
import {helpers} from "../../components/helpers";

/*layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Icons*/
import IconEndereco from "../../components/Icons/icon-endereco";

const EnderecosEntrega = () => {
    const store = useSelector(store => store);
    const history = useHistory();
    const {idestabelecimento} = useParams();

    const enderecoPrincipal = [store.app.modules.user.data.endereco];
    const enderecos = helpers.collection().filter(store.app.modules.user.data.enderecos, (end) => {
        return end.hash !== store.app.modules.user.data.endereco.hash
    });

    const ends = (endereco) => {
        return <div key={endereco.hash} className="p-2 mb-3 d-flex align-items-start justify-content-between"
                    onClick={null}>
            <div className="p-2 d-flex align-items-center justify-content-center">
                <div className="flex-fill">
                    <IconEndereco width={45}
                                  fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                </div>
                <div className="flex-fill ml-4">
                    <span>
                        {`
                            ${endereco.logradouro || ''}, ${endereco.numero || ''} -
                            ${endereco.bairro || ''}, ${endereco.cidade || ''}
                            ${endereco.estado || ''}, ${endereco.cep || ''}
                        `}
                    </span>
                </div>
            </div>
            <div className="p-2 text-center d-flex align-items-center justify-content-center">
                <span className="" style={{
                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                }}
                      onClick={async e => {
                          await helpers.animatedElement(e, 'bounceIn', 'fast');
                          await helpers.awaitForTime(500);
                          history.push(`/${idestabelecimento}/novo-endereco`, {endereco: endereco});
                      }}>Editar</span>
            </div>
        </div>
    };

    return (
        <div className="h-100 d-flex flex-column justify-content-between">
            <Header title={"Endereços de Entrega"}/>

            <div className="p-2 mt-2 flex-shrink-0 flex-grow-0 d-flex flex-column">
                <div className="p-2 ml-4">
                    <span className="text-bold">Endereço de Entrega Atual</span>
                </div>

                {enderecoPrincipal.map(endereco => (ends(endereco)))}

                <hr className="w-100 m-auto"/>
            </div>

            <div className="p-2 flex-grow-1 scroll position-relative">
            
                <div className="d-flex align-items-center justify-content-center">

                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="p-2 ml-4">
                            <span className="text-bold">Outros endereços</span>
                        </div>

                        {enderecos.map(endereco => (ends(endereco)))}

                    </div>
                </div>

                <div className="p-2 flex-grow-1 d-flex align-items-center justify-content-center"
                     onClick={async e => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.push(`/${idestabelecimento}/novo-endereco`)
                     }}>
                    <span className="" style={{
                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                    }}> + Adicionar novo endereço</span>
                </div>

            </div>

            <Footer/>

        </div>
    );
};

export default EnderecosEntrega;
