import React from 'react';

const IconEndereco = ({
                      style = {},
                      fill = "#ff1200",
                      width = "100%",
                      className = "",
                      x = "0px", y = "0px",
                      viewBox = "0 0 100 100",
                      xmlns = "http://www.w3.org/2000/svg",
                      xmlnsXlink = "http://www.w3.org/1999/xlink",
                  }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M-13.7-64.4c-6.1-5.9-12.3-12-18.3-17.8l-6-5.9c-0.8-0.8-1.6-1.5-2.3-2.3c-1.3-1.3-2.6-2.6-3.9-3.9
	c-3.3-3.1-8.3-3.1-11.6,0.2l-9.8,9.5c-6.9,6.7-13.9,13.3-20.8,20c-1.6,1.5-3,3.1-3.6,5.4v2.9l0.2,0.2l0,0c0.8,2.9,2.5,4.8,5.1,5.6
	c0,7.1,0,14.3,0,21.4v3.2c0,5.5,3.9,9.4,9.4,9.4c2,0,4.1,0,6.1,0c0.6,0,1.2,0,1.8-0.1c4.6-0.9,7.5-4.5,7.5-9.2v-5.4
	c0-2.6,0-5.2,0-7.8c0-3.5,2.5-6.3,5.9-6.6c2.3-0.2,4.6-0.2,6.7,0c3.6,0.2,6.4,3.3,6.3,7c-0.1,2.9-0.1,5.9,0,8.7c0,1.3,0,2.7,0,4
	c0,5.4,3.9,9.4,9.3,9.4c0.8,0,1.6,0,2.5,0c1.2,0,2.4,0,3.6,0c0.1,0,0.2,0,0.2,0c2.4,0,4.8-1,6.5-2.7c1.8-1.8,2.8-4.2,2.7-6.8
	c-0.1-5.1-0.1-10.3-0.1-15.4c0-3,0-6,0-8.9v-0.1c0,0,0,0,0.1,0c2.9-0.9,4.7-3,5.2-6C-10.5-59.5-11.4-62.1-13.7-64.4z M-22.8-35.4
	v3.1c0,1.6,0,3.1,0,4.7v2.2c0,1.3-0.6,2-1.9,2c-1.8,0-3.7,0-5.5,0h-0.7c-0.7,0-1.2-0.3-1.4-0.6c-0.4-0.4-0.6-1-0.6-1.5
	c0.1-3.2,0.1-6.5,0-9.7v-3.3c0-7.7-6-13.9-13.7-14H-48c-0.9,0-1.8-0.1-2.7-0.1c-1.4,0-2.7,0.1-4.1,0.3c-6.6,1-11.6,6.9-11.7,13.6
	v0.7c0,4.1-0.1,8.4,0,12.6c0,0.7-0.2,1.2-0.5,1.6c-0.4,0.4-0.9,0.6-1.6,0.6l0,0h-0.9c-1.8,0-3.6,0-5.5,0c-0.6,0-1.2-0.2-1.6-0.6
	c-0.3-0.3-0.6-0.8-0.6-1.5c0.1-3.1,0.1-6.2,0-9.2c0-0.8,0-1.7,0-2.5l0.1-14.2c0-3-2-5.4-4.9-5.9c-0.3,0-0.4-0.2-0.7-0.6
	C-72.1-68.1-61.6-78.3-51-88.5l0.3-0.3c0.4-0.4,0.5-0.5,0.6-0.5c0.4,0,0.6,0.1,0.8,0.3c10.6,10.2,21.2,20.4,31.8,30.6
	c0.1,0.1,0.2,0.2,0,0.8c-0.1,0.4-0.2,0.4-0.4,0.5c-3,0.5-5,3-5,6C-22.8-45.9-22.8-40.7-22.8-35.4z"/>
        <g>
            <path d="M172.4-63.4c0-11.7-9.6-21.3-21.3-21.3c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c7.6,0,13.8,6.2,13.8,13.8
		c0,2.1,1.7,3.7,3.7,3.7C170.7-59.7,172.4-61.3,172.4-63.4z"/>
            <path d="M196.8-23.6l-20-20c4.3-5.5,6.8-12.4,6.8-19.8c0-17.9-14.6-32.5-32.5-32.5s-32.5,14.6-32.5,32.5
		s14.6,32.5,32.5,32.5c7.8,0,14.9-2.7,20.5-7.3l19.9,19.9c0.7,0.7,1.7,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1
		C198.2-19.8,198.2-22.1,196.8-23.6z M126.1-63.4c0-13.8,11.2-25,25-25s25,11.2,25,25s-11.2,25-25,25S126.1-49.6,126.1-63.4z"/>
        </g>
        <g>
            <path d="M183.8-18.8c-1.4-0.6-2.8-1.1-3.8-2.3c-1.8-1.9-2.3-4.1-2.1-6.6c1.7-15.1,3.3-30.1,5-45.2
		c0.5-4.7,3.5-7.2,8.2-7.2c1.6,0,3.1-0.1,4.7,0c1.4,0.1,1.7-0.4,1.6-1.7c-0.1-1.5,0-3,0.1-4.5c0.5-7.1,6.7-12.7,13.9-12.5
		s13.1,6.1,13.3,13.2c0,1.4,0.1,2.7,0,4.1c-0.1,1.1,0.2,1.6,1.4,1.5s2.4,0,3.6,0c4.6,0,7.5,2.6,8.1,7.2c0.7,5.7,1.3,11.5,1.9,17.2
		c1,8.9,1.9,17.8,2.9,26.7c0.6,5.6-0.5,7.8-5.7,10.1C219.2-18.8,201.5-18.8,183.8-18.8z M210.4-23.8c7.9,0,15.9,0,23.8,0
		c3.3,0,4-0.9,3.6-4.2c-1-9.1-2-18.1-3-27.2c-0.6-5.8-1.3-11.6-1.9-17.4c-0.2-1.7-1.1-2.5-2.8-2.5c-1.4,0-2.8,0-4.2,0
		c-0.3,0-0.7-0.1-1,0.3c-0.5,0.8,0.2,4,0.9,4.5c2.4,1.7,3.4,4.4,2.5,7.1c-0.8,2.5-3.2,4.3-5.9,4.3s-5.2-1.8-6-4.4
		c-0.8-2.7,0.1-5.4,2.6-7.1c0.7-0.5,1.3-3.9,0.8-4.5c-0.1-0.2-0.5-0.2-0.7-0.2c-5.2,0-10.4,0-15.6,0c-0.3,0-0.7-0.1-0.9,0.4
		c-0.5,1.3,0.2,3.9,1.3,4.8c2.8,2.3,3,6.4,0.5,9c-2.4,2.5-6.5,2.6-9,0.1c-2.5-2.6-2.5-6.9,0.4-9c1.8-1.2,1.8-2.6,1.8-4.4
		c0-0.8-0.2-1.1-1.1-1.1c-1.8,0.1-3.6,0-5.5,0s-2.8,0.8-3,2.7c-0.2,1.4-0.3,2.9-0.4,4.4c-1.5,13.6-3,27.1-4.6,40.7
		c-0.3,2.6,0.6,3.5,3.2,3.6C194.2-23.8,202.3-23.8,210.4-23.8z M211.1-80.1c2.5,0,5,0,7.5,0c1,0,1.3-0.3,1.2-1.2
		c-0.1-1.4,0-2.8,0-4.2c-0.2-4.5-4-8.2-8.6-8.3c-4.5-0.1-8.4,3.4-8.7,7.9c-0.1,1.5,0,3-0.1,4.5c-0.1,1.1,0.4,1.3,1.3,1.3
		C206.2-80.1,208.6-80.1,211.1-80.1z M201-65.1c-0.1-0.7-0.5-1.2-1.2-1.2c-0.8,0-1.3,0.4-1.3,1.2c0,0.7,0.4,1.2,1.1,1.2
		C200.4-63.8,200.8-64.3,201-65.1z M222.1-66.3c-0.6,0.2-1.1,0.5-1.1,1.2c0,0.8,0.5,1.3,1.3,1.2c0.7,0,1.2-0.5,1.2-1.2
		C223.5-65.9,223-66.3,222.1-66.3z"/>
        </g>
        <g>
            <path d="M35.6-23.8c1.6,0,3.2-0.6,4.4-1.8l28.8-28.8c2.4-2.4,2.4-6.4,0-8.8L40-92c-2.4-2.4-6.4-2.4-8.8,0
		s-2.4,6.4,0,8.8l24.5,24.4L31.3-34.4c-2.4,2.4-2.4,6.4,0,8.8C32.5-24.4,34.1-23.8,35.6-23.8z"/>
        </g>
        <g>
            <g>
                <path d="M-120.3,49.9c-10.1-10-7.7-23.3-0.4-30c7.8-7.3,20.1-7.2,27.8,0.2c7.1,6.8,9.3,19.8-0.6,29.7
			c1.7,0.8,3.3,1.7,5,2.3c5.9,2.4,8.4,7.2,9.8,13.1c1.1,4.5-0.5,7.5-3.9,10.3c-15.5,12.9-37.8,11.6-51.5-3.1c-0.5-0.5-0.9-1-1-1.7
			c-0.1-5.5,0.8-10.7,4.7-15c1.3-1.5,3-2.5,4.8-3.4C-123.9,51.6-122.1,50.8-120.3,49.9z M-107.2,80.7c9.3-0.1,17.1-3.5,23.6-9.7
			c0.5-0.5,1.1-0.9,1.1-1.7c0-4.3-1.4-11-7.2-13.4c-2.1-0.9-4.2-1.9-6.3-3c-1-0.5-1.8-0.6-2.8-0.1c-4.2,1.9-8.8,2.8-13.1,1
			c-4.1-1.7-7.2-0.7-10.6,1.3c-0.2,0.1-0.5,0.3-0.7,0.4c-6,2.2-7.7,7.2-8.2,12.9c-0.1,1.1,0.3,1.8,1,2.5
			C-123.7,77.2-116,80.5-107.2,80.7z M-90.8,34.7c0-8.9-7.1-16.2-16-16.2c-9.1,0-16.3,7.1-16.3,16.1c0,9,7.2,16.3,16.2,16.3
			C-98,50.8-90.8,43.5-90.8,34.7z"/>
                <path d="M-106.9,85.1c-10.1,0-20.1-4.1-27.6-12.2c-0.5-0.6-1.1-1.1-1.1-2.1c-0.1-6.7,1.4-11.5,4.8-15.4
			c1.3-1.4,2.9-2.5,5-3.5c1.3-0.6,2.5-1.2,3.8-1.8l0.9-0.4c-5.4-5.6-6.5-11.5-6.4-15.5c0.1-5.6,2.4-11,6.5-14.7
			c8.1-7.5,20.6-7.4,28.5,0.2c3.9,3.8,6.2,9.1,6.2,14.6c0,3.9-1,9.8-6.4,15.3c0.3,0.2,0.6,0.3,0.9,0.5c1.1,0.6,2.2,1.1,3.4,1.6
			c6.8,2.8,9,8.7,10.1,13.5c1,4.4-0.2,7.6-4.1,10.8C-89.7,82.1-98.3,85.1-106.9,85.1z M-106.9,15c-4.8,0-9.7,1.8-13.5,5.3
			c-3.8,3.5-6,8.7-6.1,14c-0.1,3.9,1,9.8,6.6,15.3l0.5,0.5l-2.2,1c-1.3,0.6-2.5,1.2-3.8,1.8c-2,0.9-3.5,2-4.7,3.3
			c-3.3,3.7-4.7,8.2-4.6,14.7c0,0.5,0.3,0.8,0.8,1.4c13.6,14.6,35.5,16,50.9,3.1c3.6-3,4.7-5.8,3.8-9.8c-1.6-6.8-4.5-10.7-9.5-12.8
			c-1.2-0.5-2.3-1-3.4-1.6c-0.5-0.3-1-0.5-1.6-0.8l-0.6-0.3l0.5-0.5c5.5-5.5,6.6-11.2,6.6-15.1c-0.1-5.3-2.2-10.3-5.9-13.9
			C-97.1,16.8-102,15-106.9,15z M-107.2,81.2L-107.2,81.2c-8.9-0.2-16.7-3.5-23.4-9.9c-0.9-0.9-1.3-1.8-1.2-2.9
			c0.4-4.9,1.8-10.8,8.5-13.3c0.2-0.1,0.4-0.2,0.6-0.3c3-1.8,6.5-3.2,11-1.3c3.6,1.5,7.8,1.2,12.7-1c1.2-0.5,2.1-0.5,3.2,0.1
			c1.8,1,4,2.1,6.2,3c5.6,2.3,7.5,8.7,7.5,13.9c0,0.9-0.6,1.4-1,1.8l-0.2,0.2C-90.1,77.9-97.9,81.1-107.2,81.2L-107.2,81.2z
			 M-115.8,53.5c-2.2,0-4.2,0.8-6.3,2.1c-0.3,0.2-0.5,0.3-0.8,0.4c-4.9,1.8-7.3,5.6-7.9,12.5c-0.1,0.8,0.2,1.4,0.9,2.1
			c6.5,6.2,14.2,9.4,22.8,9.6c9-0.1,16.6-3.2,23.3-9.5l0.2-0.2c0.4-0.4,0.7-0.7,0.7-1.1c0-4.9-1.7-10.8-6.9-12.9
			c-2.3-0.9-4.5-2-6.3-3c-0.9-0.5-1.5-0.5-2.4-0.1c-5.1,2.3-9.7,2.6-13.5,1C-113.4,53.8-114.6,53.5-115.8,53.5z M-106.9,51.3
			c-4.5,0-8.7-1.7-11.8-4.9c-3.2-3.2-4.9-7.4-4.9-11.9c0-4.4,1.7-8.6,4.9-11.7c3.2-3.1,7.4-4.9,11.9-4.9c0,0,0.1,0,0.1,0
			c9.1,0,16.5,7.6,16.5,16.7C-90.3,43.8-97.7,51.3-106.9,51.3C-106.9,51.3-106.9,51.3-106.9,51.3z M-106.8,18.9
			c-4.2,0-8.2,1.6-11.2,4.6c-2.9,2.9-4.6,6.8-4.6,11c0,4.2,1.6,8.2,4.6,11.2c2.9,3,6.9,4.6,11.1,4.6c0,0,0,0,0,0
			c8.6,0,15.6-7,15.6-15.6h0.5h-0.5C-91.2,26-98.2,19-106.8,18.9C-106.8,18.9-106.8,18.9-106.8,18.9z"/>
            </g>
        </g>
        <g>
            <path d="M152.4,39.4c0.2-0.9,0.2-1.8,0.5-2.7c1.9-6.9,6.3-11.2,13.3-12.6c5.9-1.2,11,0.6,15.3,4.8c0.3,0.3,0.7,0.6,1,1
		c0.3,0.4,0.5,0.4,0.9,0c1-1,2-2,3.1-2.8c5.8-4.3,14.1-4.3,19.9,0c6.3,4.7,8.7,12.7,5.8,20c-0.9,2.2-2.2,4.2-3.9,5.9
		c-7.8,7.8-15.5,15.5-23.3,23.3c-1.3,1.3-2.9,1.3-4.2,0c-7.8-7.8-15.5-15.5-23.3-23.3c-2.9-2.9-4.6-6.4-5.1-10.5
		c0-0.2-0.1-0.3-0.1-0.5C152.4,41.1,152.4,40.3,152.4,39.4z M169,28.9c-3.9,0.1-7.3,1.9-9.6,5.5c-3,4.8-2.3,10.6,1.8,14.6
		c7.1,7.1,14.2,14.2,21.3,21.3c0.3,0.3,0.4,0.3,0.7,0c7.1-7.1,14.3-14.3,21.4-21.4c2.7-2.8,3.9-6.1,3.4-10
		c-1.1-8.7-11.4-13.1-18.4-8c-1.7,1.3-3.1,2.9-4.7,4.4c-1.3,1.3-2.9,1.3-4.2,0c-1-1-2.1-2.1-3.1-3.1C175.5,30.2,172.7,29,169,28.9z"
            />
        </g>
        <g>
            <path d="M76.1,42.1c0.1,3.7-1.1,7.7-2.6,11.7c-4,10.3-9.7,19.6-16.7,28c-1.4,1.6-2.9,3.2-4.6,4.4
		c-1.1,0.8-2.2,0.8-3.4,0c-2.3-1.6-4.1-3.7-5.8-5.8c-7.2-9.1-13.1-18.9-16.8-29.9c-4.7-14,2.4-27.7,14.2-33.4c8.9-4.3,20-2.2,27.4,5
		C73.1,27.6,76.1,34.4,76.1,42.1z M29.7,41.1c0,4.7,1.2,8.6,2.9,12.5c4.1,9.8,9.9,18.6,16.5,26.9c1.3,1.6,1.4,1.6,2.7,0
		c7-8.8,13.1-18.1,17.2-28.6c2.1-5.3,2.9-10.7,1.4-16.4c-4-14.5-19.8-19.4-30.9-12.3C32.9,27.4,30,33.8,29.7,41.1z"/>
            <path d="M62.6,41.7c0.1,6-5.7,11.8-11.9,11.9c-6.4,0.1-12.3-5.5-12.4-11.7c-0.1-6.1,5.6-11.9,11.8-12
		C56.7,29.7,62.5,35.2,62.6,41.7z M50.4,34.8c-4.3,0-7.1,2.7-7.1,6.9c0,4.1,2.8,6.8,7.1,6.8c4.3,0,7.2-2.8,7.2-6.8
		C57.6,37.5,54.7,34.8,50.4,34.8z"/>
        </g>

    </svg>
);

export default IconEndereco;
