import React from 'react';

const IconLixeira = ({
                          style = {},
                          fill = "#ff1200",
                          width = "100%",
                          height = "100%",
                          className = "",
                          x = "0px", y = "0px",
                          viewBox="0 0 24 24",
                          xmlns="http://www.w3.org/2000/svg",
                          xmlnsXlink = "http://www.w3.org/1999/xlink",
                      }) => (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 4.5V5.5C20 5.77614 19.7761 6 19.5 6H4.5C4.22386 6 4 5.77614 4 5.5V4.5C4 4.22386 4.22386 4 4.5 4H9V3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V4H19.5C19.7761 4 20 4.22386 20 4.5ZM5.87 20.14C5.94365 21.1896 6.81787 22.0026 7.87 22H16.15C17.2021 22.0026 18.0764 21.1896 18.15 20.14L19 8H5L5.87 20.14Z" fill={fill}/>
    </svg>
);

export default IconLixeira;
