import React from 'react';

const IconInicio = ({
                   style = {},
                   fill = "#fff",
                   width = "100%",
                   className = "",
                   x = "0px", y = "0px",
                   viewBox = "0 0 100 100",
                   xmlns = "http://www.w3.org/2000/svg",
                   xmlnsXlink = "http://www.w3.org/1999/xlink",
               }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path className="st0" d="M87,42c-6.1-5.9-12.3-12-18.3-17.8l-6-5.9c-0.8-0.8-1.6-1.5-2.3-2.3c-1.3-1.3-2.6-2.6-3.9-3.9
	c-3.3-3.1-8.3-3.1-11.6,0.2L35,21.8c-6.9,6.7-13.9,13.3-20.8,20c-1.6,1.5-3,3.1-3.6,5.4l0,2.9l0.2,0.2c0,0,0,0,0,0
	c0.8,2.9,2.5,4.8,5.1,5.6c0,7.1,0,14.3,0,21.4l0,3.2c0,5.5,3.9,9.4,9.4,9.4c2,0,4.1,0,6.1,0c0.6,0,1.2,0,1.8-0.1
	c4.6-0.9,7.5-4.5,7.5-9.2l0-5.4c0-2.6,0-5.2,0-7.8c0-3.5,2.5-6.3,5.9-6.6c2.3-0.2,4.6-0.2,6.7,0c3.6,0.2,6.4,3.3,6.3,7
	c-0.1,2.9-0.1,5.9,0,8.7c0,1.3,0,2.7,0,4c0,5.4,3.9,9.4,9.3,9.4c0.8,0,1.6,0,2.5,0c1.2,0,2.4,0,3.6,0c0.1,0,0.2,0,0.2,0
	c2.4,0,4.8-1,6.5-2.7c1.8-1.8,2.8-4.2,2.7-6.8c-0.1-5.1-0.1-10.3-0.1-15.4c0-3,0-6,0-8.9c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0
	c2.9-0.9,4.7-3,5.2-6C90.2,46.9,89.3,44.3,87,42z M77.9,71l0,3.1c0,1.6,0,3.1,0,4.7l0,2.2c0,1.3-0.6,2-1.9,2c-1.8,0-3.7,0-5.5,0
	l-0.7,0c-0.7,0-1.2-0.3-1.4-0.6c-0.4-0.4-0.6-1-0.6-1.5c0.1-3.2,0.1-6.5,0-9.7l0-3.3c0-7.7-6-13.9-13.7-14l-1.4,0
	c-0.9,0-1.8-0.1-2.7-0.1c-1.4,0-2.7,0.1-4.1,0.3c-6.6,1-11.6,6.9-11.7,13.6l0,0.7c0,4.1-0.1,8.4,0,12.6c0,0.7-0.2,1.2-0.5,1.6
	c-0.4,0.4-0.9,0.6-1.6,0.6c0,0,0,0,0,0l-0.9,0c-1.8,0-3.6,0-5.5,0c-0.6,0-1.2-0.2-1.6-0.6c-0.3-0.3-0.6-0.8-0.6-1.5
	c0.1-3.1,0.1-6.2,0-9.2c0-0.8,0-1.7,0-2.5L23.5,55c0-3-2-5.4-4.9-5.9c-0.3,0-0.4-0.2-0.7-0.6c10.6-10.2,21.2-20.3,31.8-30.5l0.3-0.3
	c0.4-0.4,0.5-0.5,0.6-0.5c0.4,0,0.6,0.1,0.8,0.3C61.9,27.6,72.5,37.8,83.1,48c0.1,0.1,0.2,0.2,0,0.8c-0.1,0.4-0.2,0.4-0.4,0.5
	c-3,0.5-5,3-5,6C77.8,60.5,77.9,65.7,77.9,71z"/>

    </svg>
);

export default IconInicio;
