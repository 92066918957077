import React from 'react';
import {useSelector} from "react-redux";

const CustomCss = () => {
    const store = useSelector(store => store);
    const css = `
        .icheck-danger > input:first-child:checked + label::before, .icheck-danger > input:first-child:checked + input[type="hidden"] + label::before {
            background-color: ${!!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : "#ff2200"} !important;
            border-color: ${!!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : "#ff2200"} !important;
        }
        .icheck-danger > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before, .icheck-danger > input:first-child:not(:checked):not(:disabled):hover + label::before {
            border-color: ${!!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : "#ff2200"} !important;
        }
    `;
    return (
        <>
            <style>{css}</style>
        </>
    );
};

export default CustomCss;
