import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../../components/helpers";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Images*/
import IconArrowRight from '../../components/Icons/icon-arrow-right';
import IconPerfil from '../../components/Icons/icon-perfil';
import IconEnderecos from '../../components/Icons/icon-endereco';
import IconHistorico from '../../components/Icons/icon-historico';
// import IconFavoritos from '../../components/Icons/icon-favorito';
import IconPagmentoCard from '../../components/Icons/icon-pagmento-card';
// import IconConfig from '../../components/Icons/icon-config';
// import IconSeguranca from '../../components/Icons/icon-seguranca';
// import IconAjuda from '../../components/Icons/icon-ajuda';

const Perfil = () => {
    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const logout = async (e) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        await helpers.awaitForTime(500);
        dispatch({
            type: 'USER_DATA',
            user: {
                data: {},
                address: {},
                login: {
                    authenticated: false, data: []
                }
            }
        });
    };

    useEffect(() => {
        if (!store.app.modules.user.login.authenticated) {
            history.push(`/${idestabelecimento}/login`);
        }
    }, [store.app.modules.user.login.authenticated]);


    if (helpers.isCardapio()) {
        history.push(`/${idestabelecimento}/categorias`);
        return false;
    }

    return (
        <div className="h-100 d-flex flex-column scroll">

            <Header title={'Perfil'}/>

            <div className="flex-grow-0 p-2">
                <div className="d-flex align-items-center">
                    <IconPerfil width={"90px"} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    <div className="p-1 d-flex flex-column align-items-start ">
                        <span className="h5 text-bold">{store.app.modules.user.data.nome}</span>
                        <small onClick={async (e) => {
                            await helpers.animatedElement(e, 'bounceIn', 'fast');
                            await helpers.awaitForTime(500);
                            history.push(`/${idestabelecimento}/editar-perfil`)
                        }}>
                            <span className="text-muted text-bold mt-n1">Editar Perfil</span>
                        </small>
                        <small onClick={logout}>
                            <span className="text-muted text-bold mt-n1">Sair</span>
                        </small>
                    </div>
                </div>
            </div>

            <hr className="width-90"/>

            <div className="flex-grow-1 p-2 font-weight-lighter">

                {/*<div className="p-2 d-flex align-items-start justify-content-between">*/}
                {/*    <div className="p-2">*/}
                {/*        <IconFavoritos width={"45px"} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>*/}
                {/*        <span className="ml-4">Favoritos</span>*/}
                {/*    </div>*/}
                {/*    <div className="p-2">*/}
                {/*        <IconArrowRight width={"35px"} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="p-2 d-flex align-items-start justify-content-between"
                     onClick={async (e) => {
                         history.push(`/${idestabelecimento}/enderecos-entrega`);
                     }}>
                    <div className="p-2">
                        <IconEnderecos width={45} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        <span className="ml-4">Endereços de entrega</span>
                    </div>
                    <div className="p-2">
                        <IconArrowRight width={35} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    </div>
                </div>
                {/*<div className="p-2 d-flex align-items-start justify-content-between">*/}
                {/*    <div className="p-2">*/}
                {/*        <IconPagmentoCard width={"45px"} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>*/}
                {/*        <span className="ml-4">Formas de pagamento</span>*/}
                {/*    </div>*/}
                {/*    <div className="p-2">*/}
                {/*        <IconArrowRight width={"35px"} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="p-2 d-flex align-items-start justify-content-between"
                     onClick={async (e) => {
                         helpers.addClassesElement(e, 'bg-gray-light');
                         await helpers.awaitForTime(200);
                         history.push(`/${idestabelecimento}/pedidos`);
                     }}>

                    <div className="p-2">
                        <IconHistorico width={45} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        <span className="ml-4">Pedidos</span>
                    </div>

                    <div className="p-2">
                        <IconArrowRight width={35} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    </div>
                </div>

                <div className="p-2 d-flex align-items-start justify-content-between"
                     onClick={async (e) => {
                         helpers.addClassesElement(e, 'bg-gray-light');
                         await helpers.awaitForTime(200);
                         history.push(`/${idestabelecimento}/cartoes`);
                     }}>

                    <div className="p-2">
                        <IconPagmentoCard width={45} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        <span className="ml-4">Meus Cartões</span>
                    </div>

                    <div className="p-2">
                        <IconArrowRight width={35} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    </div>
                </div>


                {/*<div className="p-2 mt-4 d-flex align-items-start justify-content-between">*/}
                {/*    <div className="p-2">*/}
                {/*        <IconConfig width={"45px"} fill={"gray"}/>*/}
                {/*        <span className="ml-4">Configurações</span>*/}
                {/*    </div>*/}
                {/*    <div className="p-2">*/}
                {/*        <IconArrowRight width={35} fill={"gray"}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="p-2 d-flex align-items-start justify-content-between">*/}
                {/*    <div className="p-2">*/}
                {/*        <IconSeguranca width={"45px"} fill={"gray"}/>*/}
                {/*        <span className="ml-4">Segurança</span>*/}
                {/*    </div>*/}
                {/*    <div className="p-2">*/}
                {/*        <IconArrowRight width={35} fill={"gray"}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="p-2 d-flex align-items-start justify-content-between">*/}
                {/*    <div className="p-2">*/}
                {/*        <IconAjuda width={"45px"} fill={"gray"}/>*/}
                {/*        <span className="ml-4">Ajuda</span>*/}
                {/*    </div>*/}
                {/*    <div className="p-2">*/}
                {/*        <IconArrowRight width={35} fill={"gray"}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>

            <Footer/>

        </div>

    );
};

export default Perfil;
