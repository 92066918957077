import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../components/helpers";
import {useHistory, useParams} from "react-router-dom";
import Services from "../services";
import {Progress} from "react-sweet-progress";
import moment from "moment";

const ReloadControll = (props) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const history = useHistory();
    const {idestabelecimento, mesa} = useParams();

    const [state, setState] = useState({
        ...props,
        loading: {
            loaded: false,
            message: '',
            percent: 0,
            status: 'default',
            messageerror: ''
        }
    });

    const loading = (options) => {
        setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                percent: options.percent ? options.percent : state.loading.percent,
                message: options.message ? options.message : state.loading.message,
                loaded: options.loaded ? options.loaded : state.loading.loaded,
                status: options.status ? options.status : state.loading.status,
                messageerror: options.messageerror ? options.messageerror : state.loading.messageerror,
            }
        }));
    };

    const services = useCallback(async () => {
        try {

            dispatch({type: 'PRE_LOAD', preLoad: false});

            if (!!mesa) {
                dispatch({type: 'INDOOR', indoor: {mesa: mesa}})
            }

            loading({percent: 20, message: 'Carregando Dados do Estabelecimento'});
            setInterval(async () => {
                const estabelecimento = await Services.getEstabelecimento(idestabelecimento);
                dispatch({type: 'ESTABELECIMENTO_SERVICE', response: estabelecimento.data});
                const formasPagamento = await Services.getFormasPagamantos(estabelecimento.data);
                dispatch({
                    type: 'FORMAS_PAGAMENTO_SERVICE',
                    response: {estabelecimento: estabelecimento.data, formasPagamento: formasPagamento.data}
                });
            }, 60000);

            // Caso a limpeza do carrinho cada vez que a aba é fechada incomode os clientes
            // ou não funcione  corretamente, é só deletar o código abaixo.
            // --------------------------------------------------------
            dispatch({type: 'PEDIDO_PRODUTOS_CLEAR', pedidos: {
                produtosIndividual: []
            }});
            dispatch({type: 'PEDIDO_PRODUTOS_CLEAR', pedidos: {
                produtosMontagem: []
            }});
            // --------------------------------------------------------

            setInterval(async () => {
                const produtos = await Services.listProdutosCategorias(estabelecimento.data);
                dispatch({
                    type: 'PRODUTOSCATEGORIAS_SERVICE',
                    response: produtos.data
                });
            }, 600000);

            const estabelecimento = await Services.getEstabelecimento(idestabelecimento);
            dispatch({type: 'ESTABELECIMENTO_SERVICE', response: estabelecimento.data});
            const formasPagamento = await Services.getFormasPagamantos(estabelecimento.data);
            dispatch({
                type: 'FORMAS_PAGAMENTO_SERVICE',
                response: {estabelecimento: estabelecimento.data, formasPagamento: formasPagamento.data}
            });

            loading({percent: 40, message: 'Carregando Lista de Produtos'});
            const produtos = await Services.listProdutosCategorias(estabelecimento.data);
            dispatch({
                type: 'PRODUTOSCATEGORIAS_SERVICE',
                response: produtos.data
            });

            loading({percent: 60});
            const kits = await Services.listKits(estabelecimento.data);
            dispatch({type: 'KITS_SERVICE', response: kits.data});

            loading({percent: 80});
            const pizza = await Services.listPizza(estabelecimento.data);
            dispatch({type: 'PIZZA_SERVICE', response: pizza.data});
            window.appControll.loaded = true;
            window.appControll.sessionTime = moment();

            loading({percent: 100, message: 'Concluído', status: 'success'});

            dispatch({type: 'LOADED_SERVICE', loaded: true});

            setTimeout(() => {
                if (!!props.verificaPedirSemCadastro) {
                    if (estabelecimento.data.mvdelivery_pedir_sem_cadastro === 0) {
                        dispatch({
                            type: 'USER_DATA',
                            user: {
                                semCadastro: false
                            }
                        });
                        history.push(`/${idestabelecimento}/login`);
                    }
                }
            }, 200);
        } catch (error) {
            loading({status: 'error', messageerror: 'Falha no carregamento'});
        }
    }, [dispatch, idestabelecimento, mesa]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            services();
        }
        return () => mounted = false;
    }, [services]);

    return (
        <div className="w-100 h-100">

            <div className={'d-flex flex-column align-items-center justify-content-center v-100 h-100'}>
                <div className="p-2">
                    <Progress
                        percent={state.loading.percent}
                        status={state.loading.status}
                        theme={
                            {
                                error: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'pink',
                                    color: 'red'
                                },
                                default: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'lightblue',
                                    color: 'blue'
                                },
                                active: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'yellow',
                                    color: 'orange'
                                },
                                success: {
                                    symbol: state.loading.percent + '%',
                                    trailColor: 'lime',
                                    color: 'green'
                                }
                            }
                        }
                        type={'circle'}
                        width={200}
                        style={{margin: '0 0 0 10px'}}
                    />
                </div>
                <div className="p-2">
                    <span><strong>{state.loading.message}</strong></span>
                </div>
                <div className="p-2">
                    <span><strong>{state.loading.messageerror}</strong></span>
                </div>
            </div>

        </div>
    );
};

export default ReloadControll;
