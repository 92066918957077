import React from 'react';
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../../components/helpers";

/*Layout*/
import Header from '../../components/layout/header/'
import Footer from '../../components/layout/footer/'

/*Icons*/
import IconEdit from "../../components/Icons/icon-edit";
import ReloadControll from "../../components/reload-controll";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";

const Sacola = (props) => {

    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClickMinus = async (e, produto) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        if (produto.qtdSelected === 0) {
            return false;
        }
        produto.qtdSelected--;
        dispatch({type: 'UPDATE'});
    };

    const handleClickPlus = async (e, produto) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        produto.qtdSelected++;
        dispatch({type: 'UPDATE'});
    };

    // const handleChange = (e, produto) => {
    //     if (e.currentTarget.value > 0) {
    //         produto.qtdSelected = parseInt(e.currentTarget.value);
    //         dispatch({type: 'UPDATE'});
    //     }
    // };

    const handleClickEdit = async (e, produto) => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        await helpers.awaitForTime(500);
        history.push(`/${idestabelecimento}/produto`, {produto: produto, edit: true});
    };

    const finalizar = async e => {
        await helpers.animatedElement(e, 'bounceIn', 'fast');
        await helpers.awaitForTime(500);
        if (helpers.carrinho.getQuantidadeItens(store) === 0) {
            helpers.toast.info("Não há itens na sacola");
            return false;
        }
        if (!store.app.modules.user.login.authenticated && !helpers.isIndoor(store)) {
            history.push(`/${idestabelecimento}/login`);
            return false;
        }
        history.push(`/${idestabelecimento}/checkout`);
    };

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    return (
        <div className="d-flex flex-column h-100">
            <Header title={"Sacola"}/>

            <div className="pt-2 pr-2 pb-0 pl-2 flex-grow-1 scroll position-relative">

                {
                    store.app.modules.carrinho.pedidos.produtosIndividual.map(categoria => (
                        categoria.produtos.map((produto, key) => (
                            (produto.qtdSelected > 0) ?
                                <div key={key}>
                                    <div className="p-2 flex-fill d-flex align-items-start justify-content-between">
                                        <div className="flex-fill d-flex align-items-center">
                                            <div className="p-2">
                                                <div className="elevation-3" style={{
                                                    width: "95px",
                                                    height: "95px",
                                                    backgroundImage: !!produto.produtoFoto
                                                        ? `url(https://www.portalmenew.com.br/wservice/uploads/Temp/${produto.produtoFoto})`
                                                        : 'url(/images/sem-imagem.png)',
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: 'repeat',
                                                    backgroundColor: '#fff',
                                                    borderRadius: "10px"
                                                }}/>
                                            </div>
                                            <div className="p-2 flex-fill d-flex flex-column">
                                                <div
                                                    className="p-1 mb-n2 text-bold d-flex align-items-center justify-content-between">
                                                    <span>{produto.produtoNome}</span>
                                                    <div onClick={e => {
                                                        handleClickEdit(e, produto)
                                                    }}>
                                                        <IconEdit width={25} fill={"#d4d4d4"}/>
                                                    </div>
                                                </div>
                                                <div className="p-1 text-sm text-muted">
                                                    <span>
                                                        {helpers.collection().truncate(produto.produtoDescricao,
                                                            {length: 60, omission: ' ...'})}
                                                    </span>
                                                </div>
                                                <div className="p-1 d-flex align-items-center justify-content-between">
                                                    <div
                                                        className="width-100px d-flex align-items-center position-relative">
                                                        <i className="fas fa-minus position-absolute left-0 ml-2 p-1"
                                                           style={{
                                                               color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                           }}
                                                           onClick={e => {
                                                               handleClickMinus(e, produto)
                                                           }}/>
                                                        <div
                                                            className="form-control form-control-sm text-center border-radius-10">
                                                            {produto.qtdSelected}
                                                        </div>
                                                        <i className="fas fa-plus position-absolute right-0 mr-2 p-1"
                                                           style={{
                                                               color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                           }}
                                                           onClick={e => {
                                                               handleClickPlus(e, produto)
                                                           }}/>
                                                    </div>
                                                    <span className="text-lg" style={{
                                                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                    }}>
                                                        {helpers.converteMoeda(produto.preco * produto.qtdSelected)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="w-100 m-auto"/>
                                </div>
                                : ''
                        ))
                    ))
                }

                {
                    store.app.modules.carrinho.pedidos.produtosMontagem.map((categoria, categoriaKey) => (
                        categoria.produtos.map((produto, key) => {
                            return (produto.qtdSelected > 0) ?
                                <div key={key}>
                                    <div className="p-2 flex-fill d-flex align-items-start justify-content-between">
                                        <div className="flex-fill d-flex align-items-center">
                                            <div className="p-2">
                                                <div className="elevation-3" style={{
                                                    width: "95px",
                                                    height: "95px",
                                                    backgroundImage: !!produto.produtoFoto
                                                        ? `url(https://www.portalmenew.com.br/wservice/uploads/Temp/${produto.produtoFoto})`
                                                        : 'url(/images/sem-imagem.png)',
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: 'repeat',
                                                    backgroundColor: '#fff',
                                                    borderRadius: "10px"
                                                }}/>
                                            </div>
                                            <div className="p-2 flex-fill d-flex flex-column">
                                                <div
                                                    className="p-1 mb-n2 text-bold d-flex align-items-center justify-content-between">
                                                    <span>{produto.produtoNome}</span>
                                                    {/*<div onClick={e => {*/}
                                                    {/*    handleClickEdit(e, produto)*/}
                                                    {/*}}>*/}
                                                    {/*    <IconEdit width={25} fill={"#d4d4d4"}/>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="p-1 text-sm text-muted">
                                                    <span>
                                                        {helpers.collection().truncate(produto.produtoDescricao,
                                                            {length: 60, omission: ' ...'})}
                                                    </span>
                                                </div>

                                                {
                                                    (!!produto.complementos) ?
                                                        <div className="p-1 mb-1">
                                                            <label
                                                                htmlFor={`complementos-${produto.produtoCodigo + categoriaKey}`}
                                                                href={`#complementos-${produto.produtoCodigo + categoriaKey}`}
                                                                data-toggle="collapse" role="button"
                                                                aria-expanded="false"
                                                                aria-controls={`complementos-${produto.produtoCodigo + categoriaKey}`}>
                                                                Complementos: <i className="fas fa-arrow-circle-down"/>
                                                            </label>
                                                            <div className="collapse"
                                                                 id={`complementos-${produto.produtoCodigo + categoriaKey}`}>
                                                                {
                                                                    produto.complementos.map(complemento => (
                                                                        complemento.produtos.map(produto => (
                                                                            (produto.qtdSelected > 0) ?
                                                                                <div key={produto.produtoCodigo}
                                                                                     className="d-flex justify-content-between flex-fill">
                                                                                <span className="font-weight-light">
                                                                                    {produto.qtdSelected}X {produto.produtoNome}
                                                                                </span>
                                                                                    <span
                                                                                        className="ml-2  font-weight-light">
                                                                                    + {helpers.converteMoeda(produto.preco * produto.qtdSelected)}
                                                                                </span>
                                                                                </div>
                                                                                :
                                                                                ""
                                                                        ))
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        : <div/>
                                                }

                                                <div className="p-1 d-flex align-items-center justify-content-between">
                                                    <div
                                                        className="width-100px d-flex align-items-center position-relative">
                                                        <i className="fas fa-minus position-absolute left-0 ml-2 p-1"
                                                           style={{
                                                               color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                           }}
                                                           onClick={e => {
                                                               handleClickMinus(e, produto)
                                                           }}/>
                                                        <div
                                                            className="form-control form-control-sm text-center border-radius-10">
                                                            {produto.qtdSelected}
                                                        </div>
                                                        <i className="fas fa-plus position-absolute right-0 mr-2 p-1"
                                                           style={{
                                                               color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                           }}
                                                           onClick={e => {
                                                               handleClickPlus(e, produto)
                                                           }}/>
                                                    </div>
                                                    <span className="text-lg" style={{
                                                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                    }}>
                                                        {helpers.converteMoeda(helpers.produto.calcTotalProduto(produto))}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="w-100 m-auto"/>
                                </div>
                                : ''
                        })
                    ))
                }

            </div>


            <div className="p-4 mb-n4 flex-shrink-0 flex-grow-0 d-flex align-items-center justify-content-between"
                 style={{
                     color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                 }}>
                <div className="p-0 d-flex flex-column align-items-start justify-content-center">
                    {/*<span className="text-md mb-n2">Subtotal:</span>*/}
                    {/*<span className="text-md mb-n2">Frete:</span>*/}
                    <span className="text-lg mb-n2 text-bold">Total:</span>
                </div>
                <div className="p-0 d-flex flex-column align-items-end justify-content-center">
                    {/*<span className="text-md mb-n2">{helpers.carrinho.getTotalPedido(store)}</span>*/}
                    {/*<span className="text-md mb-n2">R$ 8,00</span>*/}
                    <span className="text-lg mb-n2 text-bold">{helpers.carrinho.getTotalPedido(store)}</span>
                </div>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-0 d-flex align-items-center justify-content-around">
                <div className="text-bold text-md btn btn-block"
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.push(`/${idestabelecimento}/home`);
                     }}>
                    {
                        helpers.isCardapio()
                            ? 'Voltar ao cardápio'
                            : 'Voltar às compras'
                    }
                </div>
                {
                    helpers.isCardapio() && !helpers.isIndoor(store)
                        ?
                        <div className="p-2 text-bold text-md btn btn-block border-radius-20" style={{
                            backgroundColor: 'gray',
                            color: '#fff',
                        }}>
                            <i className="fas fa-ban"/> Finalizar
                        </div>
                        :
                        <div className="p-2 text-bold text-md btn btn-block border-radius-20" style={{
                            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                            color: '#fff',
                        }}
                             onClick={finalizar}>
                            Finalizar
                        </div>
                }

            </div>

            <Footer/>
        </div>
    );
};

export default Sacola;
