import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../../components/helpers";
import Services from "../../services";

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Form*/
import {Form} from "@unform/web";
import Input from "../../components/Form/input";
import * as Yup from 'yup';

const Perfil = () => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const formRef = useRef();

    const handleSubmit = async (e) => {

        try {

            const formData = formRef.current.getData();

            const fields = {
                nome: Yup.string().required('Digite seu nome'),
                cpf: Yup.string().required('Digite seu cpf'),
                telefone: Yup.string().required('Digite seu celular'),
                email: Yup.string().email('Email inválido').required('Digite seu email'),
                senha: Yup.string().required('Por favor informe sua senha para salvar as alterações!'),
            };

            if (!!formData.nova_senha) {
                fields.confirme_nova_senha = Yup.string().required('Confirme sua senha')
                    .oneOf([Yup.ref('nova_senha'), null], 'Senhas divergentes');
            }

            const schema = Yup.object().shape(fields);

            await schema.validate(formData, {
                abortEarly: false
            });

            if (!helpers.toBrazilian.isCPF(formData.cpf)) {
                formRef.current.setErrors({cpf: 'CPF inválido'});
                return false;
            }

            const dataUpdate = {
                hash: store.app.modules.user.login.data.hash,
                nome: formData.nome,
                cpf: formData.cpf.replace(/\D/g, ""),
                telefone: formData.telefone.replace(/\D/g, ""),
                email: formData.email,
                senha: formData.senha,
            };

            if (!!formData.confirme_nova_senha) {
                dataUpdate.nova_senha = formData.nova_senha;
            }

            const usuarioUpdateResponse = await Services.usuarioUpdate(dataUpdate);

            if (!!usuarioUpdateResponse.data.info) {
                helpers.toast.info(`cod: ${usuarioUpdateResponse.data.cod} - ${usuarioUpdateResponse.data.info}`);
                return false;
            }

            dispatch({
                type: 'USER_DATA',
                user: {
                    address: {
                        ...store.app.modules.user.address,
                        ...formData
                    }
                }
            });

            store.app.modules.user.data.nome = formData.nome;
            store.app.modules.user.data.cpf = formData.cpf;
            store.app.modules.user.data.telefone = formData.telefone;
            store.app.modules.user.data.email = formData.email;
            dispatch({type: 'UPDATE'});

            helpers.toast.success('Suas informações foram alteradas com sucesso!');

            formRef.current.setFieldValue('senha', '');
            formRef.current.setFieldValue('nova_senha', '');
            formRef.current.setFieldValue('confirme_nova_senha', '');

            return true;

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errorMessages = {};
                err.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    const handleChange = () => {
        formRef.current.setErrors({});
        // const formData = formRef.current.getData();
        // dispatch({
        //     type: 'USER_DATA', user: {
        //         address: formData
        //     }
        // });
    };

    return (

        <div className="d-flex flex-column h-100">

            <Header title={'Editar Perfil'}/>

            <div className="p-4 flex-grow-1 scroll position-relative">
                <Form ref={formRef} initialData={store.app.modules.user.data}
                      onSubmit={handleSubmit}>
                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Nome Completo</span>
                        </div>
                        <div>
                            <Input type="text" name={"nome"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">CPF</span>
                        </div>
                        <div>
                            <Input type="text" name={"cpf"}
                                   mask={"999.999.999-99"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Celular</span>
                        </div>
                        <div>
                            <Input type="text" name={"telefone"}
                                   mask={"(99) 9 9999-9999"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Email</span>
                        </div>
                        <div>
                            <Input type="text" name={"email"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   readOnly={true}
                                   onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Senha</span>
                        </div>
                        <div>
                            <Input type="password" name={"senha"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Nova Senha</span>
                        </div>
                        <div>
                            <Input type="password" name={"nova_senha"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="p-2">
                        <div>
                            <span className="h6 text-bold">Confirmar Nova Senha</span>
                        </div>
                        <div>
                            <Input type="password" name={"confirme_nova_senha"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                    </div>
                </Form>

                <div className="p-2 flex-grow-0">
                    <div className="p-2 d-flex align-items-center justify-content-around">
                        <div
                            className="p-2 w-75 text-bold text-md btn btn-block border-radius-20
                            d-flex align-items-center justify-content-around" style={{
                            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                            color: '#fff',
                        }}
                            onClick={handleSubmit}>
                            <div className="p-2">Salvar</div>
                        </div>
                    </div>
                </div>


            </div>

            <Footer/>

        </div>

    );
};

export default Perfil;
