import React from 'react';

const IconTrophy = ({
                          style = {},
                          fill = "#ff1200",
                          width = "100%",
                          className = "",
                          x = "0px", y = "0px",
                          viewBox = "0 0 40 40",
                          xmlns = "http://www.w3.org/2000/svg",
                          xmlnsXlink = "http://www.w3.org/1999/xlink",
                      }) => (
    <svg

        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>
        <g>
            <g>
                <path d="M26.7,38.2c-4.5,0-8.9,0-13.4,0c-0.1,0-0.1-0.1-0.2-0.1c-0.7-0.3-1.1-0.8-1.1-1.6c0-0.8,0.4-1.3,1.1-1.6
			c2.1-1,3.4-2.6,4-4.8c0.1-0.4,0.1-0.7-0.3-0.9c-1.4-0.8-2.4-1.9-3.3-3.2c-0.2-0.3-0.5-0.6-0.9-0.8c-5.9-3.2-9.4-8.2-10.5-14.8
			C1.9,8.5,3,6.9,4.7,6.6c1-0.2,1.9-0.1,2.9-0.1c0.5,0,0.7-0.1,0.7-0.7c0-2.3,0.5-3,2.6-3.8C17,2,23.1,2,29.1,2
			c0.4,0.2,0.9,0.3,1.3,0.6c1,0.7,1.4,1.7,1.3,2.9c0,1.2-0.1,1,1,1c0.6,0,1.3,0,1.9,0c2.2,0.1,3.5,1.8,3.2,3.9
			C36.8,17,33.3,21.8,27.5,25c-0.4,0.2-0.6,0.5-0.9,0.8c-0.9,1.3-1.9,2.4-3.3,3.2c-0.4,0.2-0.5,0.5-0.3,0.9c0.6,2.2,1.9,3.8,3.9,4.8
			c0.6,0.3,1.1,0.7,1.2,1.4C28.2,37,27.8,37.6,26.7,38.2z M20.1,3.8c-2.8,0-5.6,0-8.4,0c-1.1,0-1.6,0.5-1.6,1.6
			c0.1,4.6,0.6,9.1,2,13.5c1,2.8,2.2,5.5,4.3,7.7c2.4,2.3,4.8,2.3,7.2,0c1.6-1.6,2.7-3.5,3.5-5.5C29.3,16,30,10.8,30,5.4
			c0-1.1-0.5-1.6-1.6-1.6C25.6,3.8,22.9,3.8,20.1,3.8z M11.4,22.2c-0.4-1.1-0.8-2.1-1.1-3.1C9.3,15.8,8.7,12.4,8.5,9
			c0-0.5-0.2-0.7-0.8-0.7c-0.7,0-1.5,0-2.2,0c-1.1,0-1.7,0.7-1.5,1.8c0.5,3.2,1.7,6,3.7,8.5C8.7,20,9.9,21.1,11.4,22.2z M28.8,22
			c4.2-3,6.6-7,7.4-12.1c0.1-0.9-0.5-1.6-1.4-1.6c-0.8,0-1.6,0-2.3,0c-0.5,0-0.7,0.1-0.8,0.7c-0.1,2.1-0.4,4.1-0.8,6.1
			C30.3,17.5,29.6,19.8,28.8,22z M25.8,36.4c-2-1.3-3.4-2.9-4.2-5C21.1,30,21.1,30,19.6,30c-0.5,0-0.7,0.1-0.8,0.6
			c-0.6,2.3-2,4.2-4.1,5.4c-0.1,0.1-0.4,0.1-0.4,0.4C18.1,36.4,22,36.4,25.8,36.4z"/>
                <path d="M20.1,8.4c0.8,0,1.4,0.4,1.7,1.1c0.4,1,0.9,1.5,2,1.5c0.8,0,1.3,0.5,1.6,1.3c0.2,0.7,0.1,1.4-0.4,1.9
			c-0.1,0.1-0.1,0.1-0.2,0.2c-0.9,0.6-1,1.3-0.7,2.3c0.2,0.8,0,1.5-0.7,2.1c-0.7,0.5-1.5,0.5-2.2,0c-0.8-0.5-1.4-0.5-2.2,0
			c-0.7,0.5-1.5,0.5-2.2,0c-0.7-0.5-1-1.3-0.7-2.1c0.3-1,0-1.7-0.8-2.3c-0.7-0.5-0.9-1.3-0.6-2.1c0.3-0.8,0.9-1.3,1.8-1.3
			c0.9,0,1.4-0.4,1.7-1.3C18.5,8.9,19.1,8.4,20.1,8.4z M17.8,17.4c1.5-1.2,3-1.1,4.6,0c-0.7-1.9-0.1-3.3,1.4-4.5
			c-2,0-3.2-0.9-3.7-2.8c-0.5,1.9-1.7,2.8-3.7,2.8C17.9,14.1,18.5,15.5,17.8,17.4z"/>
            </g>
        </g>
    </svg>
);

export default IconTrophy;
