import React from 'react';
import {useSelector} from "react-redux";
import {helpers} from "../../components/helpers";
import {useParams} from "react-router";

const CarrouselCategorias = (props) => {
    const store = useSelector(store => store);
    // const dispatch = useDispatch();
    // const history = useHistory();
    const {idestabelecimento} = useParams();
    // const slider = useRef();

    // const settings = {
    //     // initialSlide: 0,
    //     variableWidth: true,
    //     accessibility: false,
    //     adaptiveHeight: true,
    //     centerMode: true,
    //     className: "center",
    //     centerPadding: "0px",
    //     dots: false,
    //     arrows: false,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     swipeToSlide: true,
    //     // focusOnSelect: true,
    //     // autoplay: true,
    //     // autoplaySpeed: 3000,
    //     // pauseOnHover: true,
    //     // rtl: true,
    //     beforeChange: (current, next) => {
    //     },
    //     afterChange: (current, next) => {
    //     },
    //     ...props,
    // };

    const handleClick = (e) => {
        const el = e.currentTarget.closest('.carousel');
        window.location.href = `/${idestabelecimento}/home#${el.getAttribute('data-href')}`;
    };

    return (
        <div style={{
            width: "100%",
            display: "flex",
            position: "relative",
            overflow: "auto"
        }}>
            {
                helpers.collection().cloneDeep(store.app.services.produtosCategorias).map(categoria => {

                    if (categoria.categoriaOculto !== 'S' && categoria.eComplemento !== 'S') {
                        return <div style={{flex: "0 0 auto"}} key={categoria.categoriaCodigo} onClick={handleClick}
                                    className={'carousel'} id={`carousel_${helpers.collection().snakeCase(categoria.categoriaNome)}`} data-href={helpers.collection().snakeCase(categoria.categoriaNome)}>
                            <div
                                className="p-2 m-1 border-radius-20 align-items-center justify-content-center text-center"
                                style={{
                                    color: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                }}>
                                        <span style={{fontSize: "12px", fontWeight: "bold"}}>
                                            {helpers.collection().truncate(categoria.categoriaNome,
                                                {length: 60, omission: ' ...'})}
                                        </span>
                            </div>
                        </div>
                    }
                    return true;
                })
            }
        </div>
    );
};

export default CarrouselCategorias;
