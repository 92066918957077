import React from 'react'
import styled from "styled-components";

const LogoMenewCircleSvg = (props) => {
    return (
        <div className={props.className ? props.className : 'logo-menew-svg'}>
            <svg x="0px" y="0px" viewBox="0 0 2000 2000">
                <path className={props.className ? props.className : 'logo-menew-svg'} d="M1515,1214.7c0-43.6,0-79.9,0-116.2c0-89.4,0.4-178.9-0.4-268.3c-0.1-16.7,3.3-23.9,21.6-22.6
    c21.5,1.6,43.4,1.6,64.9,0c18.1-1.4,21.9,5.5,21.6,22.3c-1,57.1-0.4,114.2-0.4,178.4c37.4-31.6,70.1-59,102.6-86.8
    c31.1-26.6,30.9-26.7,63,0.4c31.4,26.5,62.9,52.9,95.4,80.3c18.7-269.9-166.9-696.1-607.9-839.8C776.6-0.4,241.4,326.1,145.4,803.2
    C116.8,780,89.5,758,62.4,735.7c-12.3-10.1-2.5-21.8,0.2-31.1c27.1-93.8,69.6-180.6,125.8-260.2C364.5,195.1,604.9,50.1,907.9,19
    c480.6-49.3,899.3,249.6,1037.1,673.4c51.8,159.3,64.1,322,36.9,487.4c-0.9,5.4-2.9,10.7-5.4,19.9c-36.8-31.2-71.5-60.9-106.3-90.3
    c-31.7-26.7-64.5-52.1-94.9-80.1c-15.9-14.7-25.7-10.9-39.9,1.3c-60.1,51.3-121,101.7-181.7,152.4
    C1543,1192.1,1532.1,1200.8,1515,1214.7z"/>
                <path className={props.className ? props.className : 'logo-menew-svg'} d="M1865.7,1216c28.7,23.9,54.3,45.8,80.6,66.9c10.8,8.7,7.1,17.3,3.7,27.5c-105,310.3-309.7,525.3-619,633.8
    c-290.3,101.8-573.3,73-835.3-86.5C205.9,1681.4,47.3,1417.6,18,1078.6c-6.7-77-1.5-154.2,10.9-230.7c1.2-7.3,3.4-14.4,6-25.2
    c51.9,42.8,101.5,84.2,151.8,124.9c23.8,19.2,45.5,55,72.2,52.8c24.7-2.1,46.8-34.5,69.9-53.8c54.6-45.6,109.1-91.3,163.4-136.8
    c7.5,6.4,4.9,12.5,4.9,17.6c0.2,120.5-0.3,241,0.6,361.6c0.1,18.6-4.8,25.2-23.5,23.6c-27-2.4-62.3,11.1-79.1-5
    c-16.9-16.2-4.3-51.6-5-78.6c-0.9-36.4-0.2-72.9-0.2-116.3c-38.5,32.6-73.5,60.1-106,90.5c-19.9,18.7-33.7,19.2-53.9,0.1
    c-32-30.2-67-57.2-101.5-86.2C105,1348.7,359.1,1725,711.2,1842.7c159.3,53.2,321.4,66.4,486.6,29.7
    c164-36.5,304.5-115.4,425.1-231.7C1744.4,1523.4,1821.7,1380.8,1865.7,1216z"/>
                <path className={props.className ? props.className : 'logo-menew-svg'} d="M1181.8,1214.2c-82.6-68.6-160.1-133.1-242.9-201.9c0,58.1,0.3,110.2-0.1,162.2c-0.3,42.6,6.1,37.5-37.1,37.7
    c-68.7,0.3-68.7,0.1-68.7-68.6c0-101.6,0-203.2,0-304.8c0-9.1,0-18.2,0-34.1c81.9,68.7,159.1,133.4,241.3,202.3
    c0-56.3,0-107.3,0-158.3c0-40.8,0-40.8,39.3-40.8c68.2,0,68.2,0,68.2,68c0,101.6,0,203.2,0,304.8
    C1181.8,1189.7,1181.8,1198.7,1181.8,1214.2z"/>
                <path className={props.className ? props.className : 'logo-menew-svg'} d="M536.7,1008.4c0-59.3,0.8-118.6-0.5-177.9c-0.4-19,5.7-23.3,23.7-23c70.6,1,141.2,0.9,211.8,0.1
    c16.5-0.2,22.7,3.1,23,21.4c1.1,72.6,1.8,72.6-71,72.6c-17.9,0-35.9,1.5-53.6-0.4c-21.5-2.3-26.1,5.5-26.8,26.4
    c-0.8,24.4,7.1,30.1,30,28.7c33.8-2,67.8,0.1,101.6-0.8c14.7-0.4,19,4.6,19.6,19.3c3.6,75.4,4,75.4-70.6,75.4c-12.2,0-24.5,0-36.7,0
    c-45,0-44.3,0-43.5,45.2c0.3,15,4.7,19.3,19.3,18.9c36.7-0.9,73.4,0.4,110.1-0.6c15.6-0.4,20.9,4.1,21.4,20.4
    c2.7,78.3,3.2,78.3-73.7,78.3c-54.6,0-109.2-0.5-163.8,0.3c-15.9,0.2-21-4.4-20.7-20.6C537.2,1130.7,536.7,1069.5,536.7,1008.4z"/>
                <path className={props.className ? props.className : 'logo-menew-svg'} d="M1477.8,1009.3c0,59.2-0.7,118.5,0.4,177.7c0.4,18.9-5.8,23.7-24.1,23.4c-70.5-1-141.1-0.8-211.6-0.1
    c-16.3,0.2-22.6-2.8-22.8-21.2c-0.6-71.5-1.4-71.5,70.9-71.5c16.9,0,34-1.3,50.8,0.3c21.5,2,28.9-4.4,29.2-27.5
    c0.3-24.3-7.2-30.2-30.2-28.8c-33.7,2-67.7,0-101.5,0.8c-13.6,0.3-19.5-2.2-20.1-18.1c-2.9-75.8-3.5-75.8,72.9-75.8
    c17.9,0,35.9-1.7,53.6,0.4c30.8,3.5,23.4-17.5,24.5-34.3c1.2-18.8,0.3-32.3-25.9-30.3c-33.7,2.5-67.7-0.2-101.5,1
    c-16.4,0.5-24.1-2.9-22.5-21.3c2-24.2-7.5-55.8,4.6-70.8c12.8-15.9,45.7-4.6,69.8-5c52.7-0.8,105.4,1,158-0.8
    c22.3-0.8,26.3,7,25.9,27.1C1476.9,892.7,1477.8,951,1477.8,1009.3z"/>
            </svg>
        </div>
    )
};

const LogoMenewCircle = styled(LogoMenewCircleSvg)`
  fill: ${props => props.fill ? props.fill : `#FFF`};
`;

export default LogoMenewCircle;
