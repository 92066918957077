import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {helpers} from "../../components/helpers";

/*Layout*/
import Header from '../../components/layout/header/'
import Footer from '../../components/layout/footer/'

/*Images*/
import IconSearch from "../../components/Icons/icon-search";
import IconFechar from "../../components/Icons/icon-fechar";
import ReloadControll from "../../components/reload-controll";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";

const Busca = (props) => {
    const store = useSelector(store => store);
    const history = useHistory();
    const {idestabelecimento} = useParams();
    const [state, setState] = useState({data: []});

    let produtos = [];

    const HandleFilterChange = e => {

        produtos = [];

        helpers.collection().cloneDeep(store.app.services.produtosCategorias).map(function (categ) {
            if (categ.categoriaOculto !== 'S' && categ.eComplemento !== 'S') {
                categ.produtos.map(function (prod) {
                    if ((prod.produtoNome && prod.produtoNome.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
                        || (prod.categoriaNome && prod.categoriaNome.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
                    ) {
                        produtos.push(prod);
                    }
                    return true;
                });
                return true;
            }
            return true;
        });

        const {value} = e.target;

        setState(state => ({
            ...state,
            str_filter_prods: value,
            data_filtered_prods: produtos
        }));

    };

    const clearSearch = () => {
        document.getElementById("buscaProduto").value = "";
        setState(state => ({
            ...state,
            str_filter_prods: "",
            data_filtered_prods: []
        }));
    };

    const produtoSelected = (produto) => {
        if (produto.temKit === 'S' || produto.temPizza === 'S' || (produto.pedeComplemento === 'S' && !!produto.cComplemento)) {
            history.push(`/${idestabelecimento}/produto-complemento`, {produto: produto});
            return false;
        }
        history.push(`/${idestabelecimento}/produto`, {produto: produto});
    };

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    return (

        <div className="d-flex flex-column h-100">

            <Header title={"Busca"}/>

            <div className="pt-4 pl-4 pr-4 pb-2 flex-grow-0 d-flex align-items-center">

                <input id="buscaProduto" type="text" className="p-3 form-control form-control-lg mr-n4 text-md"
                       onChange={HandleFilterChange} placeholder="Busque seu produto"/>

                {(state.str_filter_prods === undefined || state.str_filter_prods.length === 0) && (
                    <IconSearch className="ml-n3" width={25} fill={
                        !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                )}

                {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && (
                    <div onClick={clearSearch}>
                        <IconFechar className="ml-n3" width={25} fill={
                            !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                    </div>
                )}

            </div>

            <div className="pt-0 pl-4 pr-4 pb-0 flex-grow-1 scroll position-relative">

                {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && state.data_filtered_prods.length > 0 && (

                    <div>

                        {
                            state.data_filtered_prods.map(produto => (
                                <div key={produto.produtoCodigo}>
                                    <div
                                        className="pt-0 pr-0 pb-0 pl-0 d-flex align-items-center justify-content-between"
                                        onClick={e => {
                                            produtoSelected(produto)
                                        }}>
                                        <div className="d-flex align-items-center">
                                            <div className="pt-2 pr-1 pb-2 pl-0">
                                                <div className="elevation-3" style={{
                                                    width: "95px",
                                                    height: "95px",
                                                    backgroundImage: !!produto.produtoFoto
                                                        ? `url(https://www.portalmenew.com.br/wservice/uploads/Temp/${produto.produtoFoto})`
                                                        : 'url(/images/sem-imagem.png)',
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: 'repeat',
                                                    backgroundColor: '#fff',
                                                    borderRadius: "10px"
                                                }}/>
                                            </div>
                                            <div className="p-2 d-flex flex-column">
                                                <div className="p-1 mb-n2 text-bold">
                                                    <span>
                                                        {helpers.collection().truncate(produto.produtoNome,
                                                            {length: 50, omission: ' ...'})}
                                                    </span>
                                                </div>
                                                <div className="p-1 text-sm text-muted">
                                            <span>
                                                {helpers.collection().truncate(produto.produtoDescricao,
                                                    {length: 120, omission: ' ...'})}
                                            </span>
                                                </div>
                                                <div className="p-1">
                                                    <span className="" style={{
                                                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                    }}>
                                                        {
                                                            produto.temKit === 'S' || produto.temPizza === 'S' || produto.pedeComplemento === 'S'
                                                                ? helpers.produto.getMenorPrecoProdutoComplemento(produto, store)
                                                                : helpers.converteMoeda(produto.preco)
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                        <IconArrowRight width={35}
                                                        fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                        */}
                                    </div>
                                    <hr className="w-100"/>
                                </div>
                            ))
                        }

                    </div>

                )}

                {(state.str_filter_prods !== undefined && state.str_filter_prods.length > 0) && state.data_filtered_prods.length === 0 && (

                    <div>
                        <div className="p-2">
                            <div className="p-1 ml-2">
                                <strong>Nenhum produto encontrado...</strong>
                            </div>
                        </div>
                    </div>

                )}

            </div>

            <Footer/>

        </div>

    );
};

export default Busca;
