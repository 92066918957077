import React from 'react';
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';

/*Icons*/
import IconInicio from '../../Icons/icon-inicio';
import IconSearch from '../../Icons/icon-search';
import IconFavorito from '../../Icons/icon-favorito';
import IconTrophy from "../../Icons/icon-trophy";
import IconBag from '../../Icons/icon-bag';
import IconPerfil from '../../Icons/icon-perfil';
import {helpers} from "../../../components/helpers";
import IconFechar from "../../../components/Icons/icon-fechar";

if (localStorage.getItem('showPopupPesquisa')===null) {
    localStorage.setItem('showPopupPesquisa', 1);
}

const Footer = () => {
    const store = useSelector(store => store);
    const history = useHistory();
    const dispatch = useDispatch();
    const {idestabelecimento} = useParams();
    const path = window.location.href;

    const closePopup = () => {
        document.getElementById("popupPremioCantaloupe").style.display = "none";
        localStorage.setItem('showPopupPesquisa', 0);
        return false;
    };

    const goToPesquisa = () => {
        closePopup();
        history.push(`/${idestabelecimento}/pesquisa`);
    }

    var showPopupPesquisa = localStorage.getItem('showPopupPesquisa');

    return (
        <div className="flex-shrink-0 flex-grow-0 text-white border-top-radius-15" style={{
            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
        }}>
            <div className="d-flex align-items-center justify-content-around font-weight-light">
                <div className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center"
                     onClick={event => {
                         history.push(`/${idestabelecimento}/home`);
                         dispatch({
                             type: 'ANOTHERS', anothers: {
                                 anchorInicio: true
                             }
                         });
                     }}>
                    <IconInicio width={25}/>
                    <span className="text-xs">Início</span>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center"
                     onClick={event => {
                         history.push(`/${idestabelecimento}/busca`)
                     }}>
                    <IconSearch width={25}/>
                    <span className="text-xs">Buscar</span>
                </div>
                <div style={{position: "relative"}}
                     className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center"
                     onClick={event => {
                         history.push(`/${idestabelecimento}/sacola`)
                     }}>

                    {helpers.carrinho.getQuantidadeItens(store) > 0 ?
                        <span style={{
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                            right: "-1px",
                            top: "3px",
                            textAlign: "center",
                            backgroundColor: "#f00",
                            borderRadius: "15px"
                        }}>
                            {helpers.carrinho.getQuantidadeItens(store)}
                        </span> : ""
                    }

                    <IconBag width={25}/>
                    <span className="text-xs">Sacola</span>


                </div>
                {
                    helpers.isCardapio()
                        ?
                        <div
                            className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center">
                            <IconPerfil width={25} fill={'gray'}/>
                            <span className="text-xs text-gray">
                                <i className="fas fa-ban"/> {store.app.modules.user.login.authenticated ? 'Perfil' : 'Login'}
                            </span>
                        </div>
                        :
                        <div
                            className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center"
                            onClick={event => {
                                if (store.app.modules.user.login.authenticated) {
                                    history.push(`/${idestabelecimento}/perfil`);
                                } else {
                                    history.push(`/${idestabelecimento}/login`);
                                }
                            }}>
                            <IconPerfil width={25}/>
                            <span
                                className="text-xs">{store.app.modules.user.login.authenticated ? 'Perfil' : 'Login'}</span>
                        </div>
                }

                {
                    helpers.isCardapio() &&
                        store.app.services.estabelecimento.grupos &&
                        store.app.services.estabelecimento.grupos[0] &&
                        store.app.services.estabelecimento.grupos[0].pesquisas &&
                        store.app.services.estabelecimento.grupos[0].pesquisas[0]
                        ?
                        <div style={{maxWidth: "68px", textAlign: "center", backgroundColor:'#f17b4d', borderRadius:10,marginTop:5,marginBottom:5}}
                             className="pt-2 pr-2 pb-2 pl-2 d-flex flex-column align-items-center justify-content-center"
                             onClick={event => {
                                     history.push(`/${idestabelecimento}/pesquisa`);
                             }}>
                            <IconTrophy width={25} fill={'#FFFFFF'}/>
                            <span className="text-xs text-white" style={{lineHeight:1,marginTop:2}}>
                                {store.app.services.estabelecimento.grupos[0].pesquisas[0].nome}
                            </span>
                        </div>
                        :
                        null
                }

            </div>

            {
                helpers.isCardapio() &&
                path.indexOf('home') !== -1 &&
                !(path.indexOf('home#inicio') !== -1)&&
                store.app.services.estabelecimento.grupos &&
                store.app.services.estabelecimento.grupos[0] &&
                store.app.services.estabelecimento.grupos[0].pesquisas &&
                store.app.services.estabelecimento.grupos[0].pesquisas[0]
                ?

                <div id="popupPremioCantaloupe" style={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    left: '0px',
                    top: '0px',
                    zIndex: '40'
                }}>

                    <div style={{
                        position: 'fixed',
                        width: '340px',
                        height: '340px',
                        left: '50%',
                        top: '50%',
                        marginLeft: '-175px',
                        marginTop: '-175px',
                        backgroundColor: '#fff',
                        zIndex: '40',
                        border: '2px solid #fff'
                    }}>
                        <span onClick={closePopup}><IconFechar style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            }} className="ml-n3" width={25} fill={'#ff2200'}
                        /></span>

                        <img onClick={goToPesquisa} width="100%" height="100%" src="https://portalmenew.com.br/dist/img/pop-up-cantaloupe.jpg" />

                    </div>

                </div>
                :
                null
            }

        </div>
    );
};

export default Footer;
