window._ = require('lodash');


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
window.Toastr = require('toastr');
window.Moment = require('moment');
require('bootstrap');
require('fastclick');
require('admin-lte/build/js/AdminLTE');


window.appControll = {
    loaded: false,
    sessionTimeLimit: 60,
    sessionTime: window.Moment()
};
