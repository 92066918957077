import React from 'react';
import {useSelector} from "react-redux";

const PreLoad = () => {
    const store = useSelector(store => store);

    return (
        <>
            {
                store.app.modules.preLoad
                    ?
                    <div
                        className="overlay-custom d-flex justify-content-center align-items-center flex-column text-white">
                        <div className="p-1">
                            <i className="fas fa-2x fa-sync-alt fa-spin"/>
                        </div>
                    </div>
                    : ''
            }

        </>
    );
};

export default PreLoad;
