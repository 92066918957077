import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpers} from "../../helpers";
import $ from 'jquery';
import 'jquery-mask-plugin';

import Card from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss'

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './utils';


const NewCardForm = (setCard,props) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();

    /*
    const [state, setState] = useState({
        number: !!store.app.modules.user.card.number ? store.app.modules.user.card.number : '',
        name: !!store.app.modules.user.card.name ? store.app.modules.user.card.name : '',
        expiry: !!store.app.modules.user.card.expiry ? store.app.modules.user.card.expiry : '',
        cvc: !!store.app.modules.user.card.cvc ? store.app.modules.user.card.cvc : '',
        issuer: !!store.app.modules.user.card.issuer ? store.app.modules.user.card.issuer : '',
        focused: '',
        formData: null
    });
    */

    const [state, setState] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        formData: '',
        cpf: '',
        debito: false,
        credito: false,
        cardFavorite: false,
        editCard:false,
        cardId: ''
    });

    if(setCard.props.card){
        state.editCard = true;
        state.number = `${setCard.props.card.first_six_digits.substring(0,4)} .... .... ${setCard.props.card.last_four_digits || 'xxxx'}`
        state.name = setCard.props.card.holder_name
        state.cpf = setCard.props.card.holder_document
        state.cardId = setCard.props.card.id

        let mes;
        if(setCard.props.card.exp_month.toString().length === 2){
            mes = setCard.props.card.exp_month
        }else{
            mes = `0${setCard.props.card.exp_month}`
        }
        state.expiry = mes +'/'+ `${setCard.props.card.exp_year.toString().substring(2,4)}`

        if(setCard.props.card.tipo === 'Débito'){
            state.debito = true
        }else{
            state.credito = true
        }
    }

    const {name, number, expiry, cvc, focused, cpf} = state;
    const formRef = useRef(null);
    const cardFavorite = useRef(null);

    useEffect(() => {
        $('.use-mask').mask($('.use-mask').attr('mask'));
    }, []);

    const handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            setState({
                ...state,
                issuer
            });
        }
    };

    const handleInputFocus = ({target}) => {
        setState({
            ...state,
            focused: target.name,
        });
    };

    const handleInputChange = ({target}) => {
        $('.use-mask').mask($('.use-mask').attr('mask'));
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }

        handleBlur()

        setState({
            ...state,
            [target.name]: target.value
        });
    };

    const handleBlur = () => {
        const formData = [...formRef.current]
            .filter(d => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});
        setState({
            ...state,
            formData
        });


        const data = {
            "card": {
                "index": 0,
                "type": "credit_card",
                "holder_name": formData.name,
                "cvc": formData.cvc,
                "exp_month": helpers.moment(formData.expiry, 'MM/YY', true).format('MM'),
                "exp_year": helpers.moment(formData.expiry, 'MM/YY', true).format('YYYY'),
                "number": !state.editCard ? formData.number.replace(/\D/g, "") : formData.number,
                "debito": state.debito,
                "credito": state.credito,
                "cardFavorite": cardFavorite.current.checked,
                "editCard": state.editCard
            }
        };

        if(state.editCard){
            data.card.cardId = state.cardId
        }
        if (helpers.dot.get(formData, 'cpf')) {
            data.card.cpf = formData.cpf.replace(/\D/g, "");
            data.card.holder_document = formData.cpf.replace(/\D/g, "");
        }
        setCard.forwardRef(data.card);
    };


    const debito = () => {
        setState({
            ...state,
            debito: !state.debito,
            credito: false,
            paymentMethod: 'debit_card',
        });
    }
    const credito = () => {
        setState({
            ...state,
            debito: false,
            credito: !state.credito,
            paymentMethod: 'credit_card',
        });
    }

    const cardFavoriteChange = () => {
        setState({
            ...state,
            cardFavorite: !state.cardFavorite
        });
    }

    return (
        <div className="m-auto" key="Payment">
                    <Card
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        locale={{valid: 'válido até'}}
                        placeholders={{name: 'SEU NOME AQUI'}}
                        callback={handleCallback}
                    />

                    <form ref={formRef}>
                        <div className="d-flex flex-row align-items-center justify-content-start">
                            {
                                !state.editCard
                                    ?
                                    <>
                                <div className="p-2">
                                    <div className="icheck-danger d-inline">
                                    <input type="radio" name={'paymentMethod'} id="debito"
                                           defaultChecked={state.debito}
                                           onBlur={handleBlur}
                                           onChange={debito}/>
                                    <label className="" htmlFor="debito" style={{
                                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                    }}>Débito</label>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="icheck-danger d-inline">
                                    <input type="radio" name={'paymentMethod'} id="credito"
                                    defaultChecked={state.credito}
                                    onBlur={handleBlur}
                                    onChange={credito}/>
                                    <label className="" htmlFor="credito" style={{
                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                }}>Crédito</label>
                                    </div>
                                </div>
                                </>
                                    :
                                    <>
                                <div className="p-2">
                                <div className="icheck-danger d-inline">
                                <input type="radio" name={'paymentMethod'} id="debito"
                                defaultChecked={state.debito}
                                onBlur={handleBlur}
                                disabled={true}
                                onChange={debito}/>
                                <label className="" htmlFor="debito" style={{
                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                            }}>Débito</label>
                                </div>
                                </div>
                                <div className="p-2">
                                <div className="icheck-danger d-inline">
                                <input type="radio" name={'paymentMethod'} id="credito"
                                defaultChecked={state.credito}
                                onBlur={handleBlur}
                                disabled={true}
                                onChange={credito}/>
                                <label className="" htmlFor="credito" style={{
                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                            }}>Crédito</label>
                                </div>
                                </div>
                                </>
                            }
                        </div>
                        <div className="p-1">
                            {
                                !state.editCard
                                ?
                                    <input
                                        type="tel"
                                        name="number"
                                        className="form-control border-0 border-radius-10 text-sm"
                                        style={{backgroundColor: '#e6e6e6'}}
                                        placeholder="Número do Cartão"
                                        pattern="[\d| ]{16,22}"
                                        required
                                        onBlur={handleBlur}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        defaultValue={number}
                                    />
                                :
                                    <input
                                        type="tel"
                                        name="number"
                                        className="form-control border-0 border-radius-10 text-sm"
                                        style={{backgroundColor: '#e6e6e6'}}
                                        placeholder="Número do Cartão"
                                        pattern="[\d| ]{16,22}"
                                        required
                                        readOnly={true}
                                        onBlur={handleBlur}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        defaultValue={number}
                                    />
                            }

                        </div>
                        <div className="p-1">
                            <input
                                type="text"
                                name="name"
                                className="form-control border-0 border-radius-10 text-sm"
                                style={{backgroundColor: '#e6e6e6'}}
                                placeholder="Nome"
                                required
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                defaultValue={name}
                            />
                        </div>
                        <div className="p-1">
                            <input
                                type="tel"
                                name="cpf"
                                className="form-control border-0 border-radius-10 text-sm use-mask"
                                style={{backgroundColor: '#e6e6e6'}}
                                placeholder="CPF do Titular"
                                mask={"999.999.999-99"}
                                required
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                defaultValue={cpf}
                            />

                        </div>
                        <div className="p-1 d-flex align-items-start justify-content-between">
                            <div className="flex-grow-0 mr-1 w-50">
                                <input
                                    type="tel"
                                    name="expiry"
                                    className="form-control border-0 border-radius-10 text-sm"
                                    style={{backgroundColor: '#e6e6e6'}}
                                    placeholder="Validade"
                                    pattern="\d\d/[\d| ]{4}"
                                    required
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    defaultValue={expiry}
                                />
                            </div>
                            {
                                !state.editCard
                                ?
                                    <div className="flex-grow-0 ml-1 w-50">
                                        <input
                                            type="tel"
                                            name="cvc"
                                            className="form-control border-0 border-radius-10 text-sm"
                                            style={{backgroundColor: '#e6e6e6'}}
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            mask={"999.999.999-99"}
                                            required
                                            onBlur={handleBlur}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            defaultValue={cvc}
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-start">
                            <div className="p-1">
                                <input type="checkbox" name={'cardFavorite'} id="cardFavorite"
                                       defaultChecked={state.cardFavorite}
                                       ref={cardFavorite}
                                       onBlur={handleBlur}
                                       onChange={cardFavoriteChange} />
                                <label className="" htmlFor="cardFavorite" style={{
                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                }}>
                                    Adicionar como favorito?
                                </label>
                            </div>
                        </div>
                    </form>

        </div>
    );
};

export default NewCardForm;
