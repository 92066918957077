import React from 'react';

const IconFechar = ({
                        style = {},
                        fill = "#fff",
                        width = "100%",
                        className = "",
                        x = "0px", y = "0px",
                        viewBox = "0 0 100 100",
                        xmlns = "http://www.w3.org/2000/svg",
                        xmlnsXlink = "http://www.w3.org/1999/xlink",
                    }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M21.14,85a6.2,6.2,0,0,0,4.4-1.8l28.8-28.8a6.28,6.28,0,0,0,0-8.8L25.54,16.8a6.22,6.22,0,0,0-8.8,8.8L41.24,50,16.84,74.4a6.28,6.28,0,0,0,0,8.8A6.15,6.15,0,0,0,21.14,85Z"/>
        <path d="M78.86,85a6.2,6.2,0,0,1-4.4-1.8L45.66,54.4a6.28,6.28,0,0,1,0-8.8l28.8-28.8a6.22,6.22,0,0,1,8.8,8.8L58.76,50l24.4,24.4a6.28,6.28,0,0,1,0,8.8A6.15,6.15,0,0,1,78.86,85Z"/>



    </svg>
);

export default IconFechar;
