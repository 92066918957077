import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {helpers} from "../../components/helpers";

/*layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Icons*/
// import IconTarget from "../../components/Icons/icon-target";
import IconSearch from "../../components/Icons/icon-search";

/*Forms*/
import {Form} from "@unform/web";
import Input from "../../components/Form/input";
import * as Yup from "yup";
import Services from "../../services";
import estadosJson from "../../json/states";
import Select from "../../components/Form/select";

const NovoEndereco = (props) => {
        const store = useSelector(store => store);
        const formRef = useRef();
        const dispatch = useDispatch();
        const history = useHistory();

        const [endereco, setEndereco] = useState({
            endereco: !!props.location.state ? props.location.state.endereco : null,
        });

        const handleChange = (e) => {

        };

        const adicionar = async (e) => {

            try {

                await helpers.animatedElement(e, 'bounceIn', 'fast');
                await helpers.awaitForTime(500);
                const formData = formRef.current.getData();
                formData.endereco.cep = formData.endereco.cep.replace(/\D/g, "");
                const schema = Yup.object().shape({
                    endereco: Yup.object().shape({
                        cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um CEP.'),
                        numero: Yup.string().required('Digite o número'),
                        logradouro: Yup.string().required('Digite o endereço'),
                        bairro: Yup.string().required('Digite o bairro'),
                        cidade: Yup.string().required('Digite a cidade'),
                        estado: Yup.string().required('Digite o estado'),
                        ponto_referencia: Yup.string().max(255, 'Limite máximo de caracteres: 255 caracteres')
                    })
                });

                await schema.validate(formData, {abortEarly: false});

                const data = {
                    'deliverymenew_usuario': store.app.modules.user.login.data.hash,
                    'cep': formData.endereco.cep.replace(/\D/g, ""),
                    'pais': 'BR3',
                    'estado': formData.endereco.estado,
                    'cidade': formData.endereco.cidade,
                    'bairro': formData.endereco.bairro,
                    'logradouro': formData.endereco.logradouro,
                    'numero': formData.endereco.numero,
                    'complemento': formData.endereco.complemento,
                    'ponto_referencia': formData.endereco.ponto_referencia,
                    'latitude': endereco.endereco.latitude,
                    'longitude': endereco.endereco.longitude,
                    'tipo': 'c'
                };

                let update = false;
                if (helpers.dot.get(endereco, 'endereco.hash')) {
                    data.hash = helpers.dot.get(endereco, 'endereco.hash');
                    data.data_cadastro = helpers.dot.get(endereco, 'endereco.data_cadastro');
                    update = true;
                }

                const enderecoResponse = await Services.usuarioEnderecoUpdate(data);

                if (!!enderecoResponse.data.code) {
                    helpers.toast.error('Ocorreu um erro, verifique os dados e tente novamente.');
                    return false;
                }

                formRef.current.setErrors({});
                formRef.current.reset();

                if (update) {

                    helpers.toast.success('Endereço atualizado com sucesso!');
                    const enderecos = helpers.collection().filter(store.app.modules.user.data.enderecos, (end) => {
                        return end.hash !== enderecoResponse.data.hash
                    });

                    if (store.app.modules.user.data.endereco.hash === enderecoResponse.data.hash) {
                        store.app.modules.user.data.endereco = enderecoResponse.data;
                    }

                    enderecos.push(enderecoResponse.data);
                    store.app.modules.user.data.enderecos = enderecos;
                    dispatch({type: 'UPDATE'});

                    history.goBack();
                    return false;
                }

                helpers.toast.success('Endereço cadastrado com sucesso!');

                store.app.modules.user.data.enderecos.push(enderecoResponse.data);
                dispatch({type: 'UPDATE'});


            } catch (errors) {
                if (errors instanceof Yup.ValidationError) {
                    helpers.toast.error('Por favor verifique os campos em destaque');
                    const errorMessages = {};
                    errors.inner.forEach(error => {
                        errorMessages[error.path] = error.message;
                    });
                    const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                    nameInput.focus();
                    formRef.current.setErrors(errorMessages);
                    return false;
                }
            }
        };

        const buscarCep = async (e) => {

            try {

                await helpers.animatedElement(e, 'bounceIn', 'fast');
                await helpers.awaitForTime(500);
                const formData = formRef.current.getData();
                formData.endereco.cep = formData.endereco.cep.replace(/\D/g, "");
                const schema = Yup.object().shape({
                    endereco: Yup.object().shape({
                        cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um Cep.')
                    })
                });

                await schema.validate(formData, {abortEarly: false});
                formRef.current.setErrors({});
                const response = await Services.getCep(formData.endereco.cep);
                if (!response.data.ok) {
                    formRef.current.setErrors({
                        ...formRef.current.getErrors(),
                        endereco: {cep: 'Cep não encontrado'}
                    });
                    return false;
                }

                formRef.current.setData({
                    endereco: {
                        cep: !!response.data.code ? response.data.code : formData.endereco.cep,
                        logradouro: !!response.data.address ? response.data.address : formData.endereco.logradouro,
                        bairro: !!response.data.district ? response.data.district : formData.endereco.bairro,
                        estado: !!response.data.state ? response.data.state : formData.endereco.estado,
                        cidade: !!response.data.city ? response.data.city : formData.endereco.cidade,
                    }
                });

                const responseGeo = await Services.googleGeoLocation(response.data);

                if (!endereco.endereco) {
                    endereco.endereco = {};
                }

                endereco.endereco.latitude = responseGeo.location.lat;
                endereco.endereco.longitude = responseGeo.location.lng;

                if (!helpers.collection().isEmpty(responseGeo)) {
                    setEndereco({...endereco});
                }

                formRef.current.setErrors({});

            } catch (errors) {

                if (errors instanceof Yup.ValidationError) {
                    helpers.toast.error('Por favor verifique os dados de entrega');
                    const errorMessages = {};
                    errors.inner.forEach(error => {
                        errorMessages[error.path] = error.message;
                    });
                    const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                    nameInput.focus();
                    formRef.current.setErrors(errorMessages);
                    return false;
                }
            }
        };

        return (
            <div className="h-100 d-flex flex-column justify-content-between">
                <Header title={"Adicionar Endereço"}/>

                <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column justify-content-center">

                    {/*<div className="p-2 flex-grow-1 d-flex align-items-center justify-content-center">*/}
                    {/*    <IconTarget width={55} fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>*/}
                    {/*    <span className="" 	style={{*/}
                    {/*        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',*/}
                    {/*    }}>Usar minha localização</span>*/}
                    {/*</div>*/}

                    <div className="p-4 flex-grow-0">
                        <div className="p-2">
                            <Form ref={formRef} initialData={endereco}>

                                <div className="p-1 d-flex align-items-start justify-content-between">
                                    <div className="flex-grow-1">
                                        <Input type="text" name={"endereco.cep"}
                                               mask={"99999-999"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="CEP"
                                               style={{backgroundColor: '#e6e6e6'}}
                                               onChange={handleChange}/>
                                    </div>
                                    <div className="p-1 flex-grow-0 ml-2 btn border-radius-10"
                                         style={{
                                             backgroundColor: '#606060',
                                             width: '75px',
                                         }}
                                         onClick={buscarCep}>
                                        <IconSearch width={25}/>
                                    </div>
                                </div>

                                <div className="p-1">
                                    <Input type="text" name={"endereco.logradouro"}
                                           className="p-3 form-control border-0 border-radius-10 text-sm"
                                           placeholder="Endereço"
                                           style={{backgroundColor: '#e6e6e6'}}
                                           onChange={handleChange}/>
                                </div>

                                <div className="p-1 d-flex align-items-start justify-content-between">
                                    <div className="flex-grow-0 w-25 mr-2">
                                        <Input type="text" name={"endereco.numero"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="Nº"
                                               style={{backgroundColor: '#e6e6e6'}}
                                               onChange={handleChange}/>
                                    </div>
                                    <div className="flex-grow-1 w-75">
                                        <Input type="text" name={"endereco.bairro"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="Bairro"
                                               style={{backgroundColor: '#e6e6e6'}}
                                               onChange={handleChange}/>
                                    </div>
                                </div>

                                <div className="p-1 d-flex align-items-start justify-content-between">
                                    <div className="flex-grow-0 w-25 mr-2">
                                        <Select name={"endereco.estado"}
                                                options={estadosJson}
                                                style={{backgroundColor: '#e6e6e6'}}
                                                className={"form-control border-0 border-radius-10 text-sm"}
                                                onChange={handleChange}/>
                                    </div>
                                    <div className="flex-grow-1 w-75">
                                        <Input type="text" name={"endereco.cidade"}
                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                               placeholder="Cidade"
                                               style={{backgroundColor: '#e6e6e6'}}
                                               onChange={handleChange}/>
                                    </div>
                                </div>

                                <div className="p-1">
                                    <Input type="text" name={"endereco.complemento"}
                                           className="p-3 form-control border-0 border-radius-10 text-sm"
                                           placeholder="Complemento"
                                           style={{backgroundColor: '#e6e6e6'}}
                                           onChange={handleChange}/>
                                </div>

                                <div className="p-1">
                                    <Input type="text" name={"endereco.ponto_referencia"}
                                           className="p-3 form-control border-0 border-radius-10 text-sm"
                                           placeholder="Referência"
                                           maxLength={255}
                                           style={{backgroundColor: '#e6e6e6'}}
                                           onChange={handleChange}/>
                                </div>

                            </Form>
                        </div>
                    </div>

                    <div className="p-2 flex-grow-0">
                        <div className="p-2 d-flex align-items-center justify-content-around">
                            <div
                                className="p-2 w-75 text-bold text-md btn btn-block border-radius-20
                             d-flex align-items-center justify-content-around" style={{
                                backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                color: '#fff',
                            }}
                                onClick={adicionar}>
                                <div className="p-2">Adicionar Endereço</div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer/>

            </div>
        );
    }
;

export default NovoEndereco;
