import React from 'react';

const IconMesa = ({
                      style = {},
                      fill = "#fff",
                      width = "100%",
                      className = "",
                      x = "0px", y = "0px",
                      viewBox = "0 0 512.001 512.001",
                      xmlns = "http://www.w3.org/2000/svg",
                      xmlnsXlink = "http://www.w3.org/1999/xlink",
                  }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M149.803,244.83H85.777c-7.499,0-13.647-5.632-14.303-13.102l-7.396-84.279c-1.567-17.86-16.269-31.328-34.198-31.328
				h-6.837c-6.444,0-12.637,2.725-16.99,7.477c-4.353,4.752-6.527,11.16-5.965,17.581l10.242,116.711
				c1.209,13.773,7.489,26.501,17.684,35.841c6.405,5.868,14.01,10.077,22.206,12.397l-24.514,80.055
				c-1.213,3.961,1.015,8.154,4.975,9.367c0.732,0.224,1.472,0.331,2.199,0.331c3.209,0,6.18-2.077,7.168-5.306l8.712-28.451H151.63
				l8.712,28.451c0.988,3.229,3.958,5.306,7.168,5.306c0.728,0,1.468-0.107,2.199-0.331c3.96-1.213,6.188-5.407,4.975-9.367
				l-10.33-33.736c-0.01-0.033-0.02-0.066-0.03-0.099l-13.514-44.135h13.723c12.395,0,22.477-10.083,22.477-22.478v-3.697
				C187.01,261.521,170.319,244.83,149.803,244.83z M53.353,347.122l11.914-38.91h69.855l11.914,38.91H53.353z M172.01,285.734
				c0,4.124-3.354,7.478-7.477,7.478h-23.788c-0.03,0-0.059,0-0.089,0H65.263c-1.604,0-3.182-0.103-4.736-0.283
				c-0.001,0-0.003,0-0.005,0c-18.764-2.169-33.558-17.065-35.25-36.352L15.03,139.867c-0.2-2.275,0.54-4.454,2.082-6.137
				c1.541-1.683,3.647-2.61,5.93-2.61h6.837c10.095,0,18.373,7.583,19.255,17.64l7.396,84.279v-0.001
				c1.34,15.273,13.913,26.791,29.246,26.791h64.026c12.245,0,22.207,9.962,22.207,22.207V285.734z"/>
        <path d="M312.948,371.141c-3.095-11.897-13.841-20.206-26.134-20.206h-4.642V214.401h92.354c5.32,0,10.299-2.365,13.661-6.488
				c3.362-4.124,4.677-9.478,3.605-14.69c-3.226-15.684-17.188-27.067-33.199-27.067H202.665c-4.143,0-7.5,3.358-7.5,7.5
				s3.357,7.5,7.5,7.5h155.928c8.926,0,16.708,6.346,18.506,15.088c0.219,1.064-0.243,1.829-0.537,2.191
				c-0.295,0.361-0.951,0.967-2.036,0.967H137.472c-1.085,0-1.741-0.606-2.036-0.967c-0.294-0.361-0.756-1.127-0.537-2.19
				c1.798-8.743,9.58-15.089,18.506-15.089h14.084c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-14.084
				c-16.012,0-29.974,11.383-33.199,27.068c-1.071,5.212,0.243,10.566,3.605,14.689c3.362,4.123,8.341,6.488,13.661,6.488h92.355
				v136.535h-4.642c-12.293,0-23.039,8.309-26.134,20.205l-1.761,6.767c-1.127,4.335-0.203,8.852,2.536,12.396
				c2.74,3.543,6.88,5.576,11.358,5.576h89.63c4.479,0,8.618-2.032,11.358-5.576c2.739-3.543,3.663-8.061,2.536-12.396
				L312.948,371.141z M244.827,214.401L244.827,214.401h22.346v136.535h-22.346V214.401z M212.016,380.879l1.552-5.962
				c1.375-5.288,6.152-8.981,11.616-8.981h61.629c5.464,0,10.241,3.693,11.616,8.981l1.552,5.962H212.016z"/>

    </svg>
);

export default IconMesa;
