import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {helpers} from "../../components/helpers";
import Services from "../../services";

/*Layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Icons*/
import IconCheck from '../../components/Icons/icon-check';
import IconRefresh from '../../components/Icons/icon-refresh';
import IconArrowRight from '../../components/Icons/icon-arrow-right';

const Pedidos = () => {
    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [status] = useState({
        0: 'Pendente',
        1: 'Em preparo',
        3: 'Em preparo',
        4: 'Rejeitado',
        5: 'Despachado',
        8: 'Erro pagamento',
        11: 'Cancelado',
        12: 'Não despachado',
    });

    const getPedidosAndamento = useCallback(() => {
        return helpers.collection().filter(store.app.services.historicoPedidos.pedidos,
            pedido => pedido.status_integracao === 0 || pedido.status_integracao === 1 || pedido.status_integracao === 3);
    }, [store.app.services.historicoPedidos.pedidos]);

    const getPedidos = useCallback(async () => {
        const response = await Services.usuarioPedidosGet({
            "deliverymenew_usuario": store.app.modules.user.login.data.hash
        });

        setLoading(false);

        const data = helpers.collection().orderBy(response.data, ['status_data'], ['desc']);

        /*Esse trecho é só para poder agrupar por data na tela de "anteriores*/
        helpers.collection().map(data, (pedido) => {
            pedido.status_data_formatado = helpers.moment(pedido.status_data).format('YYYY-MM-DD');
        });

        dispatch({
            type: 'HISTORICO_PEDIDOS', historicoPedidos: {
                pedidos: {...data},
                atual: helpers.collection().first(getPedidosAndamento())
            }
        });
    }, [store.app.modules.user.login.data.hash, dispatch, getPedidosAndamento]);

    useEffect(() => {
        if (loading) {
            getPedidos();
        }
        return () => loading;
    }, [getPedidos, loading]);

    // const pedidoEmAndamento = () => {
    //     return !!helpers.dot.get(store, 'app.services.historicoPedidos.atual')
    //         && (helpers.dot.get(store, 'app.services.historicoPedidos.atual.status_integracao') === 0
    //             && helpers.dot.get(store, 'app.services.historicoPedidos.atual.status_integracao') === 3);
    //     // return !!helpers.dot.get(store, 'app.services.historicoPedidos.atual')
    //     //     && (helpers.dot.get(store, 'app.services.historicoPedidos.atual.status_integracao') !== 4
    //     //         && helpers.dot.get(store, 'app.services.historicoPedidos.atual.status_integracao') !== 11);
    // };

    return (
        <div className="h-100 d-flex flex-column justify-content-between scroll">

            <Header title={"Pedidos"}/>

            <div className="flex-shrink-0 flex-grow-1 d-flex flex-column">

                <div className="p-4 flex-shrink-0 flex-grow-0 d-flex align-items-center">
                    <div className="mr-3 elevation-3" style={{
                        width: "80px",
                        height: "80px",
                        backgroundImage: !!store.app.services.estabelecimento.logomarca
                            ? `url(${store.app.services.estabelecimento.logomarca})`
                            : 'url(/images/sem-imagem.png)',
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        transition: "width 1s, height 1s"
                    }}/>
                    <div className="d-flex flex-column">
                        <span className="text-lg text-bold">{store.app.services.estabelecimento.descricao}</span>
                        <span className="text-sm mt-1">
                                <span style={{
                                    float: "left",
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: store.app.services.estabelecimento.em_funcionamento ? "#0F0" : "#F00",
                                    marginTop: "2px",
                                    marginRight: "2px",
                                    border: "1px solid #fff",
                                    borderRadius: "10px"
                                }}/>
                            {(store.app.services.estabelecimento.em_funcionamento) ? <span>Aberto</span> :
                                <span>Fechado</span>}
                            </span>
                    </div>
                </div>

                <div className="flex-shrink-0 flex-grow-0 p-2 d-flex align-items-start justify-content-center">
                    <div className="p-3 flex-fill d-flex align-items-center justify-content-center"
                         style={{
                             color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                             borderBottom: '1px solid',
                         }}>
                        <span className="text-bold">
                            {loading ? <i className="fas fa-spinner fa-spin"/> : ''} Em andamento
                        </span>
                    </div>
                    <div className="p-3 flex-fill d-flex align-items-center justify-content-center border-bottom"
                         onClick={e => {
                             history.push(`/${idestabelecimento}/pedidos-anteriores`)
                         }}>
                        <span className="text-muted text-bold">Anteriores</span>
                    </div>
                </div>


                {/**/}

                {
                    getPedidosAndamento().length > 0
                        ?
                        <>
                            <div className="p-4 flex-shrink-0 flex-grow-0
                            d-flex align-items-start justify-content-between">
                                <div className="flex-fill d-flex flex-column">
                                    {
                                        getPedidosAndamento().map((pedido, key) => (
                                            <div key={key}>
                                                <div className="p-1">
                                                    <small>
                                                        <span className="text-muted text-bold">
                                                            Pedido nº {pedido.idpedido}
                                                        </span>
                                                    </small>
                                                </div>

                                                <div className="p-1 d-flex align-items-center justify-content-between">
                                                    <span className="h4">
                                                        {
                                                            getPedidosAndamento().length > 0
                                                                ? helpers.converteMoeda(pedido.valor_total)
                                                                : helpers.converteMoeda(0)
                                                        }
                                                    </span>
                                                    <div className="p-1"
                                                         onClick={async e => {
                                                             await helpers.animatedElement(e, 'bounceIn', 'fast');
                                                             await helpers.awaitForTime(100);
                                                             const status = document.getElementById(pedido.idpedido);
                                                             if (status.classList.contains('d-block')) {
                                                                 status.classList.add('d-none');
                                                                 status.classList.remove('d-block');
                                                             } else {
                                                                 status.classList.add('d-block');
                                                                 status.classList.remove('d-none');
                                                             }
                                                         }}>
                                                        <span className="" style={{
                                                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                        }}>
                                                            Andamento
                                                        </span>
                                                        <IconArrowRight width={15}
                                                                        fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                                    </div>
                                                </div>

                                                <div className="p-1">
                                                    <small>
                                                        <span className="text-muted text-bold">
                                                            {
                                                                getPedidosAndamento().length > 0
                                                                    ?
                                                                    <>
                                                                        {`${helpers.dot.get(pedido, 'itens.0.quantidade', '')} 
                                                                        ${helpers.dot.get(pedido, 'itens.0.nome_produto', '')}...`}
                                                                    </>
                                                                    : ''
                                                            }
                                                        </span>
                                                    </small>
                                                </div>

                                                {/*Bloco status*/}
                                                <div className="p-2 flex-shrink-0 flex-grow-1 bg-gray-light d-none"
                                                     id={pedido.idpedido}>
                                                    {
                                                        helpers.collection().map(helpers.dot.get(pedido, 'historico', []), (historico, key) => (
                                                            <div
                                                                className="p-1 d-flex align-items-center justify-content-start"
                                                                key={key}>
                                                                {
                                                                    historico.status === 0 || historico.status === 1 || historico.status === 3
                                                                        ?
                                                                        <>
                                                                            {
                                                                                helpers.dot.get(pedido, 'status_integracao') >= 0
                                                                                    ?
                                                                                    <IconCheck width={25}
                                                                                               fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                                                                    :
                                                                                    <IconRefresh width={25}
                                                                                                 fill={'gray'}/>
                                                                            }

                                                                            <div className="p-2 ml-4">
                                                                                <div className="p-0">
                                                                                    {status[historico.status]}
                                                                                </div>
                                                                                <span className="text-muted text-sm">
                                                                                    {helpers.moment(historico.data).format('H:m:s')}
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                        : ''
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                    {/*Endereço e forma de pagamento*/}

                                                    <div
                                                        className="flex-shrink-0 flex-grow-0 p-0">
                                                        <hr className="w-100"/>
                                                    </div>

                                                    {
                                                        getPedidosAndamento().length > 0
                                                            ?
                                                            <div
                                                                className="flex-shrink-0 flex-grow-1 p-4">
                                                                <div
                                                                    className="p-1 d-flex flex-column">
                                                                    {
                                                                        helpers.dot.get(pedido, 'entrega_noestabelecimento') === 0
                                                                            ?
                                                                            <>
                                                                                <span>Endereço de Entrega</span>
                                                                                <span
                                                                                    className="text-xs text-muted">
                                                                                    {`
                                                                                        ${helpers.dot.get(pedido, 'entrega_logradouro', '')},
                                                                                        ${helpers.dot.get(pedido, 'entrega_numero', '')}
                                                                                    `}
                                                                                </span>
                                                                                <span
                                                                                    className="text-xs text-muted">
                                                                                    {`
                                                                                        ${helpers.dot.get(pedido, 'entrega_bairronome', '')},
                                                                                        ${helpers.dot.get(pedido, 'entrega_cidade', '')},
                                                                                        ${helpers.dot.get(pedido, 'entrega_estado', '')} -
                                                                                        ${helpers.dot.get(pedido, 'entrega_cep', '')}
                                                                                    `}
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <span>Endereço para Retirada do pedido</span>
                                                                                <span
                                                                                    className="text-xs text-muted">
                                                                                    {`
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.logradouro', '')},
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.numero', '')}
                                                                                    `}
                                                                                </span>
                                                                                <span
                                                                                    className="text-xs text-muted">
                                                                                    {`
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.bairronome', '')},
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.cidade', '')},
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.estado', '')} -
                                                                                        ${helpers.dot.get(store, 'app.services.estabelecimento.cep', '')}
                                                                                    `}
                                                                                </span>
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div
                                                                    className="p-1 d-flex flex-column">
                                                                    <span>Forma de Pagamento</span>
                                                                    {
                                                                        helpers.dot.get(pedido, 'pagamentos.0.forma_nome')
                                                                            ?
                                                                            <span
                                                                                className="text-xs text-muted">
                                                                                {`
                                                                                    ${helpers.dot.get(pedido, 'pagamentos.0.forma_nome')}
                                                                                     ${
                                                                                    helpers.dot.get(pedido, 'pagamentos.0.bandeira')
                                                                                        ? ` - (${helpers.dot.get(pedido, 'pagamentos.0.bandeira')})`
                                                                                        : ''
                                                                                }
                                                                                `}
                                                                            </span>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }

                                                    {/*Endereço e forma de pagamento*/}

                                                    {/*Detalhes do pedido*/}
                                                    <div
                                                        className="flex-shrink-0 flex-grow-0 p-0">
                                                        <hr className="w-100"/>
                                                    </div>

                                                    <span className=""
                                                          style={{
                                                              color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                          }}
                                                          onClick={async e => {
                                                              await helpers.animatedElement(e, 'bounceIn', 'fast');
                                                              await helpers.awaitForTime(500);
                                                              history.push(`/${idestabelecimento}/pedido-detalhes`, {produto: pedido});

                                                          }}>
                                                            + Detalhes do pedido
                                                        </span>
                                                    {/*Detalhes do pedido*/}


                                                </div>

                                                <div className="flex-shrink-0 flex-grow-0 p-0">
                                                    <hr className="w-100"/>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </>
                        : ''
                }


                {/**/}

            </div>

            <Footer/>

        </div>

    );
};

export default Pedidos;
