import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {helpers} from '../components/helpers';

import rootReducer from './reducers';

const loggerMiddleware = createLogger({
    level: 'info',
    collapse: true,
});

const enhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware, loggerMiddleware)
);

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store;
if (helpers.isProduction()) {
    store = createStore(persistedReducer);
} else {
    store = createStore(persistedReducer, enhancer);
}
const persistor = persistStore(store);

export {store, persistor};
