import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {helpers} from "../../components/helpers";
import Swal from 'sweetalert2';

/*layout*/
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";

/*Icons*/
import IconLixeira from "../../components/Icons/icon-lixeira";
import IconEndereco from "../../components/Icons/icon-endereco";
import Services from "../../services";
import BrandLogo from "../../components/Icons/BrandLogo";

const Cartoes = (props) => {
    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const history = useHistory();
    const {idestabelecimento} = useParams();

    const [route,setRoute] = useState({
        route: !!props.location.state ? props.location.state.route : null,
    })

    const ends = (card) => {
        return <div key={`${card.id}-${card.tipo}`} className="p-2 mb-3 d-flex align-items-start justify-content-between"
                    onClick={null}>
            <div className="p-2 d-flex align-items-center justify-content-center" onClick={async e => {
                          await helpers.animatedElement(e, 'bounceIn', 'fast');
                          await helpers.awaitForTime(500);
                          if(route.route === 'Checkout'){
                              await dispatch({
                                  type: 'USER_DATA',
                                  user: {
                                      cardFavorite:[card]
                                  }
                              });

                              await dispatch({type: 'UPDATE'});

                              history.push(`/${idestabelecimento}/checkout`)
                          }else{
                              history.push(`/${idestabelecimento}/novo-cartao`, {card: card,edit:true});
                          }
                      }}>
                <div className="flex-fill">
                    <BrandLogo brand={card.brand}/>
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <span>
                        {`
                            ${card.brand || ''} - ${card.tipo || ''}
                        `}
                    </span>
                    <span>
                        {`
                            xxxx xxxx xxxx ${card.last_four_digits || 'xxxx'}
                        `}
                    </span>
                </div>
            </div>
            <div className="p-2 text-center d-flex align-items-center justify-content-center">
                <span className=""
                      onClick={async e => {
                          await helpers.animatedElement(e, 'bounceIn', 'fast');
                          await helpers.awaitForTime(500);

                          let arrayValor = store.app.modules.user.cards;
                          const swalWithBootstrapButtons = Swal.mixin({
                              customClass: {
                                  confirmButton: 'btn btn-success m-1',
                                  cancelButton: 'btn btn-danger m-1'
                              },
                              buttonsStyling: false
                          })

                          await swalWithBootstrapButtons.fire({
                              title: 'Deseja remover o cartão?',
                              showCancelButton: true,
                              allowOutsideClick: false,
                              confirmButtonText: 'Sim, desejo remover!',
                              cancelButtonText: 'Não, não desejo remover!'
                          }).then(async (result) => {
                              if (result.value) {
                                  let existe = arrayValor.filter(obj => obj.id === card.id);
                                  let excluirObjeto = arrayValor.filter(obj => obj !== card)

                                  await dispatch({
                                      type: 'USER_DATA',
                                      user: {
                                          cards:excluirObjeto
                                      }
                                  });

                                  if(card.cardFavorite || store.app.modules.user.cardFavorite[0] === card){
                                      await dispatch({
                                          type: 'USER_DATA',
                                          user: {
                                              cardFavorite: []
                                          }
                                      })
                                  }

                                  await dispatch({
                                      type: 'UPDATE'
                                  })

                                  if(!existe) {
                                      const responseMundiPaggRemoveCard = await Services.mundiPaggRemoveCards({
                                          mundipagg: store.app.services.estabelecimento.mundipagg_secret_key,
                                          cards: card.id,
                                          customers: store.app.modules.user.data.customers_id
                                      });
                                  }
                                  const envioPortal = await Services.saveCardsPortal({
                                      hash: store.app.modules.user.login.data.hash,
                                      cpf: store.app.modules.user.data.cpf,
                                      cards_json: excluirObjeto
                                  })
                              }
                          })

                      }}>
                    <IconLixeira width={24}
                     fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                </span>
            </div>
        </div>
    };

    return (
        <div className="h-100 d-flex flex-column justify-content-between">
            <Header title={"Meus Cartões"}/>

            <div className="p-2 mt-2 flex-grow-0 d-flex overflow-auto flex-column">

                {store.app.modules.user.cards ? store.app.modules.user.cards.map(card => (ends(card))) : ''}

                <hr className="w-100 m-auto"/>
            </div>

            <div className="p-2 flex-grow-1 scroll position-relative">

                <div className="p-2 flex-grow-1 d-flex align-items-center justify-content-center"
                     onClick={async e => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.push(`/${idestabelecimento}/novo-cartao`,{edit:false})
                     }}>
                    <span className="" style={{
                        color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                    }}> + Adicionar Cartão</span>
                </div>

            </div>

            <Footer/>

        </div>
    );
};

export default Cartoes;
