import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from "react-router";
import {helpers} from "../../components/helpers";
import Services from "../../services";
import $ from 'jquery';
import {cpf} from 'cpf-cnpj-validator';

import {Form} from "@unform/web";
import Input from "../../components/Form/input";
import CurrencyInput from 'react-currency-masked-input'
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import PaymentForm from '../../components/Form/payment';
import ReloadControll from "../../components/reload-controll";

/*Layout*/
import Header from "../../components/layout/header";

/*Icons*/
import IconEntrega from "../../components/Icons/icon-entrega";
import IconPagamento from "../../components/Icons/icon-pagamento";
import IconBag from "../../components/Icons/icon-bag";
import IconMesa from "../../components/Icons/icon-mesa";
import IconSearch from "../../components/Icons/icon-search";
import query from "querystring";
import EstabelecimentoIndisponivel from "../../components/estabelecimentoIndisponivel";
import BrandLogo from "../../components/Icons/BrandLogo";

const Checkout = (props) => {

    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const formRefCpf = useRef(null);
    const trocoRef = useRef(null);
    const cpfNotaRef = useRef(null);

    let cartaoAUtilizar;
    if (store.app.modules.user.cardFavorite && store.app.modules.user.cardFavorite.length > 0) {
        cartaoAUtilizar = store.app.modules.user.cardFavorite[0];
    } else {
        {
            store.app.modules.user.cards && store.app.modules.user.cards.length > 0
                ?
                cartaoAUtilizar = store.app.modules.user.cards[0]
                :
                cartaoAUtilizar = null
        }
    }

    const [user, setUser] = useState({
        ...store.app.modules.user,
        data: {
            ...store.app.modules.user.data,
            sem_troco: true,
            troco_valido: true,
            online: helpers.isIndoor(store),
            formaPagamento: helpers.isIndoor(store) ? store.app.services.estabelecimento.formas.online : {},
            formaPagamentoMaquineta: store.app.services.estabelecimento.formas.maquineta,
            tipoPagamentoAceite: store.app.services.estabelecimento.tipoPagAceito,
            cupom: {
                invalidMessage: ''
            },
            cpfNota: store.app.services.estabelecimento.cpf_obrigatorio == 1,
            cards: cartaoAUtilizar,
            customerId: store.app.modules.user.data.customers_id
        }
    });

    const [state, setState] = useState({
        loadingFinalizar: false,
        loading: false,
        whatsAppUrl: null,
        whatsAppEnviar: false
    });

    const [card, setCard] = useState({});

    const handleChange = (e) => {
        formRef.current.setErrors({});
        const formData = formRef.current.getData();
        let formDataCpf;
        if (formRefCpf.current.getData().cpf) {
            formRefCpf.current.setErrors({});
            formDataCpf = formRefCpf.current.getData();
            formData.cpf = formDataCpf.cpf;
        } else {
            formData.cpf = user.data.cpf;
        }

        setUser({
            ...user,
            data: {
                ...user.data,
                ...formData
            }
        });
    };

    const handleChangeCupom = e => {
        e.persist();
        helpers.removeClassesElement(e, 'is-invalid');
        setUser({
            ...user,
            data: {
                ...user.data,
                cupom: {}
            }
        });
        if (!!e.target.value) {
            helpers.waitSomeToRun(1000, async () => {
                const cupomResponse = await Services.cuponsGet({
                    "estabelecimento_hash": store.app.services.estabelecimento.estabelecimento_hash,
                    "codigo": e.target.value
                });

                let invalidMessage = null;

                if (!!helpers.dot.get(cupomResponse, 'data.0.0.limite_uso')
                    && (helpers.dot.get(cupomResponse, 'data.0.0.usado') >= helpers.dot.get(cupomResponse, 'data.0.0.limite_uso'))) {
                    invalidMessage = 'Cupom indisponível. O limite de uso foi atingido!';
                }

                if (invalidMessage === null && helpers.carrinho.getTotalPedido(store, false) < helpers.dot.get(cupomResponse, 'data.0.0.valor_minimo')) {
                    invalidMessage = `
                        O pedido mínimo é de ${helpers.converteMoeda(helpers.dot.get(cupomResponse, 'data.0.0.valor_minimo'))}
                        para usar este cupom, sem a taxa de entrega.
                    `;
                }

                if (!helpers.dot.get(cupomResponse, 'data.0.0')) {
                    invalidMessage = 'Cupom inválido';
                }

                if (helpers.dot.get(cupomResponse, 'data.0.0.cancelado') === 1) {
                    invalidMessage = 'Cupom cancelado';
                }

                if (invalidMessage && !!e.target.value) {
                    setUser({
                        ...user,
                        data: {
                            ...user.data,
                            cupom: {
                                invalidMessage: invalidMessage
                            }
                        }
                    });
                    helpers.addClassesElement(e, 'is-invalid');
                    return false;
                }

                setUser({
                    ...user,
                    data: {
                        ...user.data,
                        cupom: {
                            ...helpers.dot.get(cupomResponse, 'data.0.0'),
                        }
                    }
                });

            });
        }
    };

    const valorDesconto = () => {
        if (helpers.dot.get(user, 'data.cupom.valor')) {
            let desconto = 0;
            if (helpers.dot.get(user, 'data.cupom.metodo') === 'p') {
                desconto = helpers.carrinho.getTotalPedido(store, false) * parseFloat(helpers.dot.get(user, 'data.cupom.valor', 0));
            } else {
                desconto = parseFloat(helpers.dot.get(user, 'data.cupom.valor', 0));
            }
            return desconto;
        }
        return 0;
    };


    const setTaxaEntrega = useCallback(async () => {
        if (formRef.current) {
            const formData = formRef.current.getData();
            if (helpers.dot.get(formData, 'endereco.cep')) {
                const response = await Services.googleGeoLocation(formData.endereco);
                if (!helpers.collection().isEmpty(response)) {
                    const taxaData = {
                        "estabelecimento_hash": store.app.services.estabelecimento.estabelecimento_hash,
                        "lat": response.location.lat,
                        "lng": response.location.lng
                    };
                    const taxaEntrega = await Services.calcTaxaEntrega(taxaData);

                    if (!taxaEntrega.data.cobertura) {
                        helpers.toast.info('Endereço fora da cobertura de entrega!');
                    }

                    setUser(user => ({
                        ...user,
                        data: {
                            ...user.data,
                            taxaEntrega: taxaEntrega.data
                        }
                    }));
                }
            }
        }
    }, [store.app.services.estabelecimento.estabelecimento_hash]);

    const buscarCep = async (e) => {
        try {
            const formData = formRef.current.getData();
            formData.endereco.cep = formData.endereco.cep.replace(/\D/g, "");
            const schema = Yup.object().shape({
                endereco: Yup.object().shape({
                    cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um Cep.')
                })
            });

            await schema.validate(formData, {abortEarly: false});
            formRef.current.setErrors({});
            const response = await Services.getCep(formData.endereco.cep);
            if (!response.data.ok) {
                formRef.current.setErrors({
                    ...formRef.current.getErrors(),
                    endereco: {cep: 'Cep não encontrado'}
                });
                return false;
            }

            formRef.current.setData({
                endereco: {
                    cep: !!response.data.code ? response.data.code : formData.endereco.cep,
                    logradouro: !!response.data.address ? response.data.address : formData.endereco.logradouro,
                    bairro: !!response.data.district ? response.data.district : formData.endereco.bairro,
                    estado: !!response.data.state ? response.data.state : formData.endereco.estado,
                    cidade: !!response.data.city ? response.data.city : formData.endereco.cidade
                }
            });

            setUser({
                ...user,
                data: {
                    ...user.data,
                    ...formData
                }
            });

            setTaxaEntrega();

            handleChange();

        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    const sendWhatsApp = (dadosPedido) => {

        const separador = '--------------------------------------- ';
        //const titulo = '*Delivery Menew - Novo pedido*\n*'+store.app.services.estabelecimento.descricao+'*\n' + separador;
        const titulo = '*Delivery Menew - Novo pedido*\n' + separador;
        let content = '';
        content += titulo;

        content += `\n ${helpers.moment(dadosPedido.data).format('DD/MM/YYYY HH:mm:ss')} \n`;

        let stringPedido = '\n*Itens:*\n';

        /*Produtos Individuais*/
        store.app.modules.carrinho.pedidos.produtosIndividual.map(categorias => {
            categorias.produtos.map(produto => {
                if (produto.qtdSelected > 0) {
                    stringPedido += `\n*${produto.qtdSelected}x ${produto.produtoNome}(${produto.produtoCodigo})* ${helpers.converteMoeda(produto.preco)}`;
                    if (!!produto.produtoObservacao) {
                        stringPedido += '\n-----*Observação:* ' + produto.produtoObservacao;
                    }
                }
                return true;
            });
            return true;
        });

        /*Produtos Montagem*/
        store.app.modules.carrinho.pedidos.produtosMontagem.map(categorias => {
            categorias.produtos.map(produto => {
                if (produto.qtdSelected > 0) {
                    stringPedido += `\n\n*${produto.qtdSelected}x ${produto.produtoNome}(${produto.produtoCodigo})* ${helpers.montagem.getTotalComplementos(produto)}`;

                    if (!!produto.complementos) {
                        produto.complementos.map(complemento => {
                            complemento.produtos.map(produto => {
                                if (produto.qtdSelected > 0) {
                                    stringPedido += `\n-----*${produto.qtdSelected}x ${produto.produtoNome}(${produto.produtoCodigo})*`;
                                }
                                return true;
                            });
                            return true;
                        });
                        if (!!produto.produtoObservacao) {
                            stringPedido += '\n-----*Observação:* ' + produto.produtoObservacao;
                        }
                    }

                }
                return true;
            });
            return true;
        });
        const valortaxa = (!user.data.retirada) ? isento() ? 0 : user.data.taxaEntrega.valor_taxa : 0;
        const totalPedido = valortaxa + helpers.carrinho.getTotalPedido(store, false);

        stringPedido += ' \n\n\n*Total:* ' + helpers.carrinho.getTotalPedido(store);
        stringPedido += ' \n*Taxa de Entrega:* ' + helpers.converteMoeda(valortaxa);
        stringPedido += ' \n*Total Pedido:* ' + helpers.converteMoeda(totalPedido);
        stringPedido += ' \n*Descontos:* ' + helpers.converteMoeda(valorDesconto());
        stringPedido += ' \n*Total a pagar:* ' + helpers.converteMoeda(
            (helpers.carrinho.getTotalPedido(store, false) - valorDesconto()) + valortaxa
        ) + '\n\n';

        content += stringPedido;
        content += separador;

        content += '\n\n*Entrega ou Retirada na loja?*';
        const retirada = !user.data.retirada ? 'Entrega' : 'Retirada';
        content += '\n' + retirada;
        if (user.data.retirada) {
            content += `\n *Previsão de retirada:* ${user.data.hora_retirada || "Não informado"}`;
        }

        if (store.app.services.estabelecimento.prazo_entrega) {
            content += `\n\n *Prazo para ${retirada}*`;
            content += '\n' + store.app.services.estabelecimento.prazo_entrega;
        }

        if (!user.data.retirada) {
            content += '\n\n*Qual é o seu endereço?* \n';
            content += user.data.endereco.logradouro + ' ' + user.data.endereco.numero
                + '\n' + user.data.endereco.bairro + ', ' + user.data.endereco.cidade + '/' + user.data.endereco.estado
                + '\nComplemento: ' + user.data.endereco.complemento
                + '\nReferência: ' + user.data.endereco.ponto_referencia;
        }

        let textFormaPagamento;
        if (user.data.formaPagamento.texto.toUpperCase() === 'DINHEIRO') {
            textFormaPagamento = user.data.formaPagamento.texto.toUpperCase()
        } else {
            textFormaPagamento = user.data.formaPagamento.texto.toUpperCase() + ' - ' + user.data.formaPagamento.forma_tipo
        }

        content += '\n\n*Como vai pagar?* \n' + textFormaPagamento;
        if (user.data.dinheiro && !user.data.sem_troco) {
            content += ' Troco Para: ' + helpers.converteMoeda(user.data.troco_para);
        }
        content += '\n\n*Dados cliente:*';
        content += '\n*Nome:* ' + user.data.nome;
        if (!!user.data.cpfNota) {
            content += '\n*CPF:* ' + user.data.cpf;
        }

        if (!user.semCadastro) {
            content += '\n*Telefone:* ' + user.data.telefone;
        } else {
            content += '\n*Telefone:* ' + formRef.current.getData().telefone;
        }

        if (!user.semCadastro) {
            content += '\n*E-mail:* ' + user.data.email;
        }

        content += '\n\n_Pedido recebido pela plataforma Menew';

        const message = query.stringify({
            phone: `55${store.app.services.estabelecimento.telefone}`,
            text: content
        });

        const url = 'https://api.whatsapp.com/send?' + message;

        /*Dispara useEffect*/
        setState(state => ({
            ...state,
            whatsAppUrl: url,
            whatsAppEnviar: true
        }));
    };

    const submitPedidoApiPortal = async () => {

        try {
            let valortaxa = 0;
            if (!helpers.isIndoor(store)) {
                valortaxa = (!user.data.retirada) ? user.data.taxaEntrega.valor_taxa : 0;
            }

            if (isento()) {
                valortaxa = 0.00;
            }

            const dadosCliente = {
                "estabelecimento_hash": store.app.services.estabelecimento.estabelecimento_hash,
                "cliente_cpf": !helpers.isIndoor(store) ? !!user.data.cpfNota ? user.data.cpf.replace(/\D/g, "") : '' : card.cpf,
                "cliente_fone": (!helpers.isIndoor(store) ? user.data.telefone.replace(/\D/g, "") : '') ||
                                ((!helpers.isIndoor(store) && !!user.semCadastro && !!user.data.retirada) ? formRef.current.getData().telefone.replace(/\D/g, "") : ''),
                "cliente_nome": !helpers.isIndoor(store) ? user.data.nome : card.holder_name,
                "customerId": !helpers.isIndoor(store) ? user.data.customerId : null,

                "valor_produtos": helpers.carrinho.getTotalPedido(store, false),
                "valor_total": (helpers.carrinho.getTotalPedido(store, false) - valorDesconto()) + valortaxa,
                "valor_desconto": helpers.collection().toNumber(valorDesconto()),
                "valor_taxa": valortaxa,
                "troco_para": user.data.dinheiro && !user.data.sem_troco ? user.data.troco_para : 0,

                "entrega_noestabelecimento": !!user.data.retirada ? 1 : 0,
                "entrega_estado": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.estado : '',
                "entrega_cidade": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.cidade : '',
                "entrega_bairronome": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.bairro : '',
                "entrega_cep": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.cep.replace(/\D/g, "") : '',
                "entrega_logradouro": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.logradouro : '',
                "entrega_numero": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.numero : '',
                "entrega_complemento": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.complemento : '',
                "entrega_referencia": (!helpers.isIndoor(store) && !user.semCadastro) || (!!user.semCadastro && !user.data.retirada) ? user.data.endereco.ponto_referencia : '',
            };

            if (store.app.modules.user.login.authenticated) {
                dadosCliente.usuario_hash = store.app.modules.user.login.data.hash;
            }

            if (!!helpers.dot.get(user, 'data.cupom.id', null)) {
                dadosCliente.mvdelivery_cupom_id = helpers.dot.get(user, 'data.cupom.id');
            }

            if (helpers.isIndoor(store)) {
                dadosCliente.indoor = 1;
                dadosCliente.mesa = store.app.services.indoor.mesa;
            }

            const itens = {itens: []};

            store.app.modules.carrinho.pedidos.produtosIndividual.map(categorias => {
                categorias.produtos.map(produto => {
                    if (produto.qtdSelected > 0) {

                        const item = {
                            "codigo_produto": produto.produtoCodigo,
                            "observacao": produto.produtoObservacao ? produto.produtoObservacao : '',
                            "valor": produto.preco,
                            "desconto": 0,
                            "qnt": produto.qtdSelected,
                            "total": produto.preco * produto.qtdSelected
                        };
                        itens.itens.push(item);

                    }
                    return true;
                });
                return true;
            });

            store.app.modules.carrinho.pedidos.produtosMontagem.map(categorias => {
                categorias.produtos.map(produto => {
                    if (produto.qtdSelected > 0) {
                        let item;
                        if(produto.temLansec === 'S'){
                            item = {
                                "codigo_produto": produto.produtoCodigo,
                                "observacao": produto.produtoObservacao ? produto.produtoObservacao : '',
                                "valor": parseFloat(helpers.produto.calcTotalProduto(produto).toFixed(2)),
                                "desconto": 0,
                                "qnt": produto.qtdSelected,
                                "total": parseFloat(helpers.produto.calcTotalProduto(produto).toFixed(2)),
                                "itens_montagem": []
                            };
                        }else{
                            item = {
                                "codigo_produto": produto.produtoCodigo,
                                "observacao": produto.produtoObservacao ? produto.produtoObservacao : '',
                                "valor": helpers.montagem.getTotalComplementos(produto, false),
                                "desconto": 0,
                                "qnt": produto.qtdSelected,
                                "total": helpers.montagem.getTotalComplementos(produto, false),
                                "itens_montagem": []
                            };
                        }

                        if (!!produto.complementos) {
                            produto.complementos.map(complemento => {
                                complemento.produtos.map(produto => {
                                    if (produto.qtdSelected > 0) {
                                        const complemento = {
                                            "codigo_produto": produto.produtoCodigo,
                                            "observacao": produto.produtoObservacao,
                                            "valor": produto.preco,
                                            "qnt": produto.qtdSelected,
                                            "desconto": 0,
                                            "total": produto.preco * produto.qtdSelected,
                                        };
                                        item.itens_montagem.push(complemento);
                                    }
                                    return true;
                                });
                                return true;
                            });
                        }
                        itens.itens.push(item);

                    }
                    return true;
                });
                return true;
            });

            const pagamento = {
                "pagamento": {
                    "valor": (helpers.carrinho.getTotalPedido(store, false) - valorDesconto()) + valortaxa,
                    "forma": user.data.formaPagamento.cod,
                    "forma_nome": user.data.formaPagamento.forma_nome,
                    "troco_para": user.data.dinheiro && !user.data.sem_troco ? user.data.troco_para : 0,
                    "cod_tipo_catoes": user.data.formaPagamento.cod_tipo_catoes,
                }
            };

            if (user.data.online) {


                if (user.data.cards) {
                    pagamento.pagamento.online_operadora = "mundiPagg";
                    pagamento.pagamento.cardId = user.data.cards.id;
                    pagamento.pagamento.online_operadora_cod1 = user.data.cards.id;
                    pagamento.pagamento.nomecartao = user.data.cards.holder_name;
                    pagamento.pagamento.identificador = `${user.data.cards.first_six_digits}${user.data.cards.last_four_digits}`;
                    pagamento.pagamento.cartao_exp = `${user.data.cards.exp_year}${user.data.cards.exp_month}`;
                    pagamento.pagamento.bandeira = user.data.cards.brand;
                    if (user.data.cards.tipo === 'Crédito') {
                        pagamento.pagamento.paymentMethod = 'credit_card';
                        user.data.formaPagamento.forma_tipo = 'CRÉDITO';
                    } else {
                        pagamento.pagamento.paymentMethod = 'debit_card';
                        user.data.formaPagamento.forma_tipo = 'DÉBITO';
                    }
                } else {
                    if (card.cartaoTerceiro && !card.holder_document) {
                        helpers.toast.warn('Por favor informe o CPF do Titular do Cartão');
                        setState({
                            ...state,
                            loading: false
                        });
                        return false;
                    }

                    if (!card.holder_document) {
                        card.holder_document = user.data.cpf.replace(/\D/g, "");
                    }

                    const mundiPaggResponse = await Services.mundiPagg({card: helpers.collection().omit(card, ['cartaoTerceiro'])});

                    if (!!mundiPaggResponse.data.errors) {
                        setState({
                            ...state,
                            loading: false
                        });
                        helpers.toast.warn('Verifique os dados do cartão');
                        return false;
                    }

                    if (helpers.isIndoor(store)) {
                        if (!card.cpf) {
                            setState({
                                ...state,
                                loading: false
                            });
                            helpers.toast.error('O CPF é obrigatório!');
                            return false;
                        }

                        if (!helpers.toBrazilian.isCPF(card.cpf)) {
                            setState({
                                ...state,
                                loading: false
                            });
                            helpers.toast.error('CPF inválido!');
                            return false;
                        }
                    }

                    pagamento.pagamento.online_operadora = "mundiPagg";
                    pagamento.pagamento.online_operadora_cod1 = mundiPaggResponse.data.id;
                    pagamento.pagamento.nomecartao = card.holder_name;
                    pagamento.pagamento.identificador = `${mundiPaggResponse.data.card.first_six_digits}${mundiPaggResponse.data.card.last_four_digits}`;
                    pagamento.pagamento.cartao_exp = `${card.exp_year}${card.exp_month}`;
                    pagamento.pagamento.bandeira = mundiPaggResponse.data.card.brand;
                    pagamento.pagamento.paymentMethod = card.type;

                    if (card.type === 'credit_card') {
                        user.data.formaPagamento.forma_tipo = 'CRÉDITO';
                    } else {
                        user.data.formaPagamento.forma_tipo = 'DÉBITO';
                    }

                    if (!user.semCadastro) {
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success m-1',
                                cancelButton: 'btn btn-danger m-1'
                            },
                            buttonsStyling: false
                        })

                        await swalWithBootstrapButtons.fire({
                            title: 'Deseja salvar o cartão?',
                            text: "Ao salvar o cartão ficará disponivel em sua carteira no sistema.",
                            showCancelButton: true,
                            allowOutsideClick: false,
                            confirmButtonText: 'Sim, desejo salvar!',
                            cancelButtonText: 'Não, não desejo salvar!'
                        }).then(async (result) => {
                            if (result.value) {
                                const data = {
                                    "token": mundiPaggResponse.data.id
                                }

                                const responseMundiPaggAddCard = await Services.mundiPaggAddCards({
                                    mundipagg: store.app.services.estabelecimento.mundipagg_secret_key,
                                    data: data,
                                    customers: store.app.modules.user.data.customers_id
                                });

                                if (card.type === 'credit_card') {
                                    responseMundiPaggAddCard.data.tipo = 'Crédito'
                                } else {
                                    responseMundiPaggAddCard.data.tipo = 'Débito'
                                }

                                responseMundiPaggAddCard.data.cardFavorite = true;
                                var addCard = [responseMundiPaggAddCard.data];

                                dispatch({
                                    type: 'USER_DATA',
                                    user: {
                                        cards: addCard,
                                        cardFavorite: addCard
                                    }
                                });


                                const envioPortal = await Services.saveCardsPortal({
                                    hash: store.app.modules.user.login.data.hash,
                                    cpf: store.app.modules.user.data.cpf.replace(/\D/g, ""),
                                    cards_json: addCard
                                })

                                helpers.toast.success('Cartão cadastrado com sucesso!');

                                pagamento.pagamento.cardId = responseMundiPaggAddCard.data.id;

                            }
                        })
                    }

                }
            }
            const pedido = {...dadosCliente, ...itens, ...pagamento};
            const responsePortal = await Services.pedidoSendPortal(pedido);
            const response = responsePortal.data;

            let status = null;
            if (!response) {
                status = 'error';
            } else if (!response.status_transacao) {
                if (response.db_result && response.id) {
                    status = 'autorizado';
                }
            } else {
                status = response.status_transacao;
            }
            let time = 2000;

            dispatch({type: 'UPDATE'})
            switch (status) {
                case "autorizado":
                    if (user.data.online) {
                        time = 4000;
                        helpers.toast.success('Pagamento autorizado!', {autoClose: 2000});
                        if (!helpers.isIndoor(store)) {
                            setTimeout(() => {
                                helpers.toast.success('Preparando envio do pedido', {autoClose: 2000});
                            }, 2000);
                        }
                    } else {
                        helpers.toast.success('Preparando envio do pedido', {autoClose: 2000});
                    }

                    if (!helpers.isIndoor(store)) {
                        setTimeout(() => {
                            sendWhatsApp(response.pedido_dados);
                        }, time);
                    } else {
                        setTimeout(() => {
                            helpers.toast.success('Pedido finalizado com sucesso! Obrigado!', {autoClose: 2000});
                            dispatch({
                                type: 'ANOTHERS', anothers: {
                                    anchorInicio: true
                                }
                            });
                            setTimeout(() => {
                                setState(state => ({
                                    ...state,
                                    loading: false
                                }));
                                history.push(`/${idestabelecimento}/home`);
                                dispatch({type: 'FINALIZAR_PEDIDO'});
                                return false;
                            }, time);
                        }, time);
                        return false;
                    }

                    break;
                case "nao_autorizado":
                case "error":
                    if (user.data.online) {
                        helpers.toast.warn('Não foi possível finalizar o pagamento, verifique os dados do cartão');
                    } else {
                        helpers.toast.warn('Não foi possível confirmar seu pedido. Por favor tente novamente ou entre em contato com o suporte técnico.');
                    }
                    setTimeout(() => {
                        setState(state => ({
                            ...state,
                            loading: false
                        }));
                    }, time);
                    break;
                default:
                    break;
            }
        } catch (e) {
            helpers.toast.error('Ocorreu uma falha ao finalizar o pedido. Tente novamente ou entre em contato com o suporte técnico.');
            setState(state => ({
                ...state,
                loading: false
            }));
        }
    };

    const finalizar = async (e) => {
        try {

            setState({
                ...state,
                loadingFinalizar: true
            });

            await helpers.animatedElement(e, 'bounceIn', 'fast');
            await helpers.awaitForTime(500);

            const estabelecimento = await Services.getEstabelecimento(idestabelecimento);
            dispatch({type: 'ESTABELECIMENTO_SERVICE', response: estabelecimento.data});
            const formasPagamento = await Services.getFormasPagamantos(estabelecimento.data);
            dispatch({
                type: 'FORMAS_PAGAMENTO_SERVICE',
                response: {estabelecimento: estabelecimento.data, formasPagamento: formasPagamento.data}
            });
            const produtosRecarregados = await Services.listProdutosCategorias(estabelecimento.data);
            dispatch({
                type: 'PRODUTOSCATEGORIAS_SERVICE',
                response: produtosRecarregados.data
            });

            const produtosCarrinho = [];
            store.app.modules.carrinho.pedidos.produtosIndividual.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    if (produto.qtdSelected > 0) {
                        produtosCarrinho.push(produto);
                    }
                });
            });

            store.app.modules.carrinho.pedidos.produtosMontagem.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    if (produto.qtdSelected > 0) {
                        produtosCarrinho.push(produto);

                        produto.complementos.forEach(opcaoComplemento => {
                            opcaoComplemento.produtos.forEach(produtoComplemento => {
                                if (produtoComplemento.qtdSelected > 0) {
                                    produtosCarrinho.push(produtoComplemento)
                                }
                            });
                        });
                    }
                });
            });

            const produtosComPrecoAtualizadoOuForaDeEstoque = [];
            produtosCarrinho.map(produto => {
                const produtoAtualizado = produtosRecarregados.data.find(produtoRecarregado => {
                    return produtoRecarregado.produtoCodigo == produto.produtoCodigo;
                });

                if (produtoAtualizado) {
                    produtoAtualizado.qtdSelected = produto.qtdSelected;
                }

                if ((produtoAtualizado && produtoAtualizado.preco != produto.preco) || !produtoAtualizado) {
                    produtosComPrecoAtualizadoOuForaDeEstoque.push(produto);
                }
                return true;
            });

            if (produtosComPrecoAtualizadoOuForaDeEstoque.length > 0) {
                let nomeProdutosInconsistentes = produtosComPrecoAtualizadoOuForaDeEstoque.map(produto => {
                    return produto.produtoNome;
                });

                nomeProdutosInconsistentes = nomeProdutosInconsistentes.join(', ');

                // dispatch({type: 'PEDIDO_PRODUTOS_CLEAR', pedidos: {
                //     produtosIndividual: store.app.modules.carrinho.pedidos.produtosIndividual
                // }});
                // dispatch({type: 'PEDIDO_PRODUTOS_CLEAR', pedidos: {
                //     produtosMontagem: store.app.modules.carrinho.pedidos.produtosMontagem
                // }});
                // dispatch({type:'UPDATE'});

                helpers.toast.error(`Os seguintes produtos estão com preços desatualizados ou fora do estoque e devem ser retirados da sua sacola: ${nomeProdutosInconsistentes}.`, {
                    autoClose: 12000,
                });

                history.goBack();
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!helpers.isIndoor(store)) {
                const formData = formRef.current.getData();
                const formDataCpf = formRefCpf.current.getData();
                if (!!formDataCpf.cpf) {
                    formDataCpf.cpf = formDataCpf.cpf.replace(/\D/g, "");
                    formData.cpf = formDataCpf.cpf;
                }
                if (!!formData.telefone) {
                    formData.telefone = formData.telefone.replace(/\D/g, "");
                }

                if (!user.data.retirada) {
                    formData.endereco.cep = formData.endereco.cep.replace(/\D/g, "");
                }

                let fields = {
                    nome: Yup.string().required('Digite seu nome'),
                    telefone: Yup.string().min(11, 'Telefone inválido').required('Digite seu telefone.'),
                    email: Yup.string().email('E-mail inválido'),
                };

                if (!!formData.cpf) {
                    fields.cpf = Yup.string().min(11, 'CPF inválido').required('Digite seu CPF');
                }

                if ((store.app.services.estabelecimento.cpf_obrigatorio == 1 && !formData.cpf) || (!!user.data.online && !formData.cpf)) {
                    formRefCpf.current.setFieldError('cpf', 'O CPF é obrigatório');
                    formRefCpf.current.getFieldRef('cpf').focus();
                    setState({
                        ...state,
                        loadingFinalizar: false
                    });
                    return false;
                }

                if ((!!formData.cpf && !cpf.isValid(formData.cpf)) || (!formData.cpf && user.data.cpfNota)) {
                    fields.cpf = formRefCpf.current.setFieldError('cpf', 'CPF inválido');
                    formRefCpf.current.getFieldRef('cpf').focus();
                    setState({
                        ...state,
                        loadingFinalizar: false
                    });
                    return false;
                }

                if (!user.data.retirada) {
                    fields = {
                        ...fields,
                        endereco: Yup.object().shape({
                            cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um CEP.'),
                            numero: Yup.string().required('Digite o número'),
                            logradouro: Yup.string().required('Digite o endereço'),
                            bairro: Yup.string().required('Digite o bairro'),
                            cidade: Yup.string().required('Digite a cidade'),
                            estado: Yup.string().required('Digite o estado'),
                            ponto_referencia: Yup.string().max(255, 'Limite máximo de caracteres: 255 caracteres')
                        })
                    };
                }

                const schema = Yup.object().shape(fields);
                await schema.validate(formData, {abortEarly: false});
            }

            // if (!store.app.services.taxaEntrega.cobertura) {
            //     helpers.toast.warn('No momento não realizamos entregamos nesta região.');
            //     return false;
            // }

            if (!!user.data.maquineta && helpers.collection().isEmpty(user.data.formaPagamento) && user.data.formaPagamentoMaquineta.length !== 0) {
                helpers.toast.warn('Escolha um tipo de cartão para pagamento');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (helpers.collection().isEmpty(user.data.formaPagamento)) {
                helpers.toast.warn('Escolha uma forma de pagamento');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!!user.data.online && !card.type && !user.data.cards) {
                helpers.toast.warn('Escolha: Débito ou Crédito');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!!user.data.maquineta && (!user.data.maquinetaDebito && !user.data.maquinetaCredito) && user.data.formaPagamentoMaquineta.length === 0) {
                helpers.toast.warn('Escolha: Débito ou Crédito');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if ((!!user.data.dinheiro && !user.data.sem_troco) && !user.data.troco_valido) {
                /*Não precisa de mensagem já esta no HTML*/
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (store.app.services.estabelecimento.entrega !== 1 && store.app.services.estabelecimento.retira_loja !== 1) {
                helpers.toast.warn('O pedido não pode ser finalizado no momento.');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!user.data.retirada && !!user.data.taxaEntrega && !user.data.taxaEntrega.cobertura) {
                helpers.toast.info('Endereço fora da cobertura de entrega!');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!!store.app.services.estabelecimento.valor_minimo && store.app.services.estabelecimento.valor_minimo > 0
                && helpers.carrinho.getTotalPedido(store, false) < store.app.services.estabelecimento.valor_minimo
                && !helpers.isIndoor(store)) {
                helpers.toast.warn(`O pedido mínimo é de ${helpers.converteMoeda(store.app.services.estabelecimento.valor_minimo)} para este estabelecimento, não incluso a taxa de entrega.`);
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            if (!store.app.services.estabelecimento.em_funcionamento) {
                helpers.toast.warn('O pedido não pode ser finalizado no momento. Verifique nosso horário de funcionamento');
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }

            // debugger;

            setState({
                ...state,
                loading: true
            });

            await submitPedidoApiPortal();

        } catch (errors) {
            //console.log(errors);
            if (errors instanceof Yup.ValidationError) {
                helpers.toast.error('Por favor verifique os dados de entrega');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                setState({
                    ...state,
                    loadingFinalizar: false
                });
                return false;
            }
        }
    };

    useEffect(() => {
        if (store.app.services.estabelecimento.entrega === 0) {
            setUser(user => ({
                ...user,
                data: {
                    ...user.data,
                    retirada: true,
                }
            }))
        }
    }, [store.app.services.estabelecimento.entrega]);

    useEffect(() => {
        if (state.whatsAppEnviar) {
            window.document.getElementById('send-whatsapp').href = state.whatsAppUrl;
        }
    }, [state.whatsAppEnviar, state.whatsAppUrl]);

    useEffect(() => {
        setTaxaEntrega();
    }, [setTaxaEntrega]);

    const login = useCallback(() => {
        if (!store.app.modules.user.login.authenticated && !helpers.isIndoor(store) && !store.app.modules.user.semCadastro) {
            history.push(`/${idestabelecimento}/login`);
            return false;
        }
    }, [history, idestabelecimento, store]);
    useEffect(() => {
        login();
    }, [login]);

    if (!store.app.services.loaded || store.app.services.estabelecimento.slug !== idestabelecimento) {
        history.push(`/${idestabelecimento}`);
        return false;
    }

    if (helpers.isCardapio() && !helpers.isIndoor(store)) {
        history.push(`/${idestabelecimento}/categorias`);
        return false;
    }

    if (helpers.carrinho.getQuantidadeItens(store) === 0) {
        history.goBack();
        return false;
    }

    const trocoPara = e => {
        helpers.removeClassesElement(e, 'is-invalid');
        let troco_valido = true;

        const total = !user.data.retirada && !isento()
            ? (helpers.carrinho.getTotalPedido(store, false) - valorDesconto()) + helpers.dot.get(user, 'data.taxaEntrega.valor_taxa', 0)
            : (helpers.carrinho.getTotalPedido(store, false) - valorDesconto());

        if (e.target.value !== '' &&
            parseFloat(e.target.value.replace(',', '.')) <= total) {
            helpers.addClassesElement(e, 'is-invalid');
            troco_valido = false;
        }

        setUser({
            ...user,
            data: {
                ...user.data,
                troco_para: parseFloat(e.target.value.replace(',', '.')),
                troco_valido: troco_valido
            }
        });
    };

    const retirada = e => {
        let troco_valido = true;
        if (!user.data.sem_troco) {
            const total = user.data.retirada
                ? helpers.carrinho.getTotalPedido(store, false) + helpers.dot.get(user, 'data.taxaEntrega.valor_taxa', 0)
                : helpers.carrinho.getTotalPedido(store, false);

            $('#troco_para').removeClass('is-invalid');
            if (trocoRef.current.state.value !== '' &&
                parseFloat(trocoRef.current.state.value.replace(',', '.')) <= total) {
                $('#troco_para').addClass('is-invalid');
                troco_valido = false;
            }
        }

        setUser({
            ...user,
            data: {
                ...user.data,
                retirada: !user.data.retirada,
                troco_valido: troco_valido
            }
        })
    };
    const cpfNota = (e) => {
        if (user.data.online) {
            helpers.toast.info('O CPF é obrigatório para pagamentos Online!');
            cpfNotaRef.current.checked = true;
            return false;
        }

        if (store.app.services.estabelecimento.cpf_obrigatorio) {
            helpers.toast.info('O CPF é obrigatório!');
            cpfNotaRef.current.checked = true;
            return false;
        }

        setUser({
            ...user,
            data: {
                ...user.data,
                cpfNota: !user.data.cpfNota,
            }
        });
    };

    if (helpers.reloadControl()) {
        return (
            <ReloadControll verificaPedirSemCadastro={true}/>
        );
    }

    if (store.app.services.estabelecimento.status !== 1) {
        return (
            <EstabelecimentoIndisponivel/>
        );
    }

    const isento = () => {
        if (!store.app.services.estabelecimento.mvdelivery_valor_taxa_isento || store.app.services.estabelecimento.mvdelivery_valor_taxa_isento === 0) {
            return false;
        } else {
            return store.app.services.estabelecimento.mvdelivery_valor_taxa_isento <= helpers.carrinho.getTotalPedido(store, false);
        }
    };

    return (
        <div className="d-flex flex-column justify-content-between scroll">
            <Header history={'sacola'} title={"Check Out"}/>

            {
                user.semCadastro && (
                    <div className="d-flex flex-row align-items-center justify-content-center w-100 p-3" style={{backgroundColor: '#e6e6e6'}}>
                        <span className="text-bold">Você está na opção sem login</span>
                    </div>
                )
            }

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">

                {
                    !helpers.isIndoor(store)
                        ?
                        /*Entrega*/
                        <div className="p-2">
                            <div className="p-2">
                                <IconEntrega width={35}
                                             fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                <span className="ml-2 text-bold" style={{
                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                }}>Entrega</span>
                            </div>
                            <div className="p-2 border-radius-15" style={{
                                border: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                            }}>
                                <div className="p-2">
                                    <Form ref={formRef} initialData={user.data}>

                                        {
                                            (store.app.services.estabelecimento.retira_loja === 1)
                                                ?
                                                <div className="p-2">
                                                    {
                                                        store.app.services.estabelecimento.entrega === 1
                                                            ?
                                                            <div className="icheck-danger d-inline">
                                                                <input type="checkbox" name={'retirada'} id="retirada"
                                                                       defaultChecked={user.data.retirada}
                                                                       onChange={retirada}/>
                                                                <label className="" htmlFor="retirada" style={{
                                                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                }}>Retirada na Loja</label>
                                                            </div>
                                                            :
                                                            <div className="icheck-danger d-inline">
                                                                <input type="checkbox" name={'retirada'} id="retirada"
                                                                       defaultChecked={true}
                                                                       onClick={e => {
                                                                           e.preventDefault();
                                                                       }}/>
                                                                <label className="" htmlFor="retirada" style={{
                                                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                                }}>Retirada na Loja</label>
                                                            </div>
                                                    }
                                                </div>
                                                : ''
                                        }

                                        <div className="p-1">
                                            <Input name={"nome"}
                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                   placeholder="Nome"
                                                   type={!!user.semCadastro ? 'text' : 'hidden'}
                                                   style={{backgroundColor: '#e6e6e6'}}
                                                   onChange={handleChange}/>
                                        </div>
                                        <div className="p-1">
                                            <Input name={"telefone"}
                                                   mask={"(99) 9 9999-9999"}
                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                   placeholder="Telefone"
                                                   type={!!user.semCadastro ? 'text' : 'hidden'}
                                                   style={{backgroundColor: '#e6e6e6'}}
                                                   onChange={handleChange}/>
                                        </div>

                                        <div className="p-1">
                                            <Input type="hidden" name={"email"}
                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                   placeholder="E-mail"
                                                   style={{backgroundColor: '#e6e6e6'}}
                                                   onChange={handleChange}/>
                                        </div>

                                        {
                                            !user.data.retirada && store.app.services.estabelecimento.entrega === 1
                                                ?
                                                <>
                                                    <div
                                                        className="p-1 d-flex align-items-start justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <Input type="text" name={"endereco.cep"}
                                                                   mask={"99999-999"}
                                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                                   placeholder="CEP"
                                                                   style={{backgroundColor: '#e6e6e6'}}
                                                                   onChange={handleChange} onBlur={buscarCep}/>
                                                        </div>
                                                        <div className="p-1 flex-grow-0 ml-2 btn border-radius-10"
                                                             style={{
                                                                 backgroundColor: '#606060',
                                                                 width: '75px',
                                                             }}
                                                             onClick={async e => {
                                                                 await helpers.animatedElement(e, 'bounceIn', 'fast');
                                                                 await helpers.awaitForTime(500);
                                                                 buscarCep()
                                                             }}>
                                                            <IconSearch width={25}/>
                                                        </div>
                                                    </div>

                                                    <div className="p-1">
                                                        <Input type="text" name={"endereco.logradouro"}
                                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                                               placeholder="Endereço"
                                                               style={{backgroundColor: '#e6e6e6'}}
                                                               onChange={handleChange}/>
                                                    </div>

                                                    <div
                                                        className="p-1 d-flex align-items-start justify-content-between">
                                                        <div className="flex-grow-0 w-25 mr-2">
                                                            <Input type="text" name={"endereco.numero"}
                                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                                   placeholder="Nº"
                                                                   style={{backgroundColor: '#e6e6e6'}}
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="flex-grow-1 w-75">
                                                            <Input type="text" name={"endereco.bairro"}
                                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                                   placeholder="Bairro"
                                                                   style={{backgroundColor: '#e6e6e6'}}
                                                                   onChange={handleChange}/>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="p-1 d-flex align-items-start justify-content-between">
                                                        <div className="flex-grow-0 w-25 mr-2">
                                                            <Input type="text" name={"endereco.estado"}
                                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                                   placeholder="UF"
                                                                   style={{backgroundColor: '#e6e6e6'}}
                                                                   onChange={handleChange}/>
                                                        </div>
                                                        <div className="flex-grow-1 w-75">
                                                            <Input type="text" name={"endereco.cidade"}
                                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                                   placeholder="Cidade"
                                                                   style={{backgroundColor: '#e6e6e6'}}
                                                                   onChange={handleChange}/>
                                                        </div>
                                                    </div>

                                                    <div className="p-1">
                                                        <Input type="text" name={"endereco.complemento"}
                                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                                               placeholder="Complemento"
                                                               style={{backgroundColor: '#e6e6e6'}}
                                                               onChange={handleChange}/>
                                                    </div>

                                                    <div className="p-1">
                                                        <Input type="text" name={"endereco.ponto_referencia"}
                                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                                               placeholder="Referência"
                                                               maxLength={255}
                                                               style={{backgroundColor: '#e6e6e6'}}
                                                               onChange={handleChange}/>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="p-1">
                                                        <label className="" htmlFor="hora_retirada" style={{
                                                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                                        }}>Horário previsto para a retirada</label>
                                                        <Input type="text" name={"hora_retirada"}
                                                               className="p-3 form-control border-0 border-radius-10 text-sm"
                                                               placeholder="Ex: 09:30"
                                                               mask={'99:99'}
                                                               maxLength={255}
                                                               style={{backgroundColor: '#e6e6e6'}}
                                                               onChange={handleChange}/>
                                                    </div>
                                                </>
                                        }

                                    </Form>

                                </div>
                            </div>
                        </div>
                        : ''
                }

                {/*Sacola/Mesa*/}
                <div className="p-2">
                    <div className="p-2">
                        {
                            helpers.isIndoor(store)
                                ? <IconMesa width={35}
                                            fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                                : <IconBag width={35}
                                           fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        }
                        <span className="ml-2 text-bold" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}>
                            {
                                helpers.isIndoor(store)
                                    ? `Mesa ${helpers.collection().padStart(store.app.services.indoor.mesa, '2', '0')}`
                                    : 'Sacola'
                            }
                        </span>
                    </div>
                    <div className="p-1 border-radius-15" style={{
                        border: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}>
                        <div className="p-0">
                            <div className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                <span>Quantidade de itens:</span>
                                <span className="" style={{
                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                }}>{helpers.carrinho.getQuantidadeItens(store)}</span>
                            </div>
                            <hr className="w-100 m-auto"/>

                            {
                                !helpers.isIndoor(store)
                                    ?
                                    <>
                                        <div
                                            className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                            <span>Taxa de entrega:</span>
                                            <span className="" style={{
                                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                            }}>{
                                                !user.data.retirada
                                                    ? isento() ? 'Grátis' : helpers.converteMoeda(helpers.dot.get(user, 'data.taxaEntrega.valor_taxa'))
                                                    : helpers.converteMoeda(0)
                                            }</span>
                                        </div>
                                        < hr className="w-100 m-auto"/>
                                    </>
                                    : ''
                            }

                            {
                                !helpers.isIndoor(store)
                                    ?
                                    <div className="p-3 ml-2 mr-2 d-flex flex-column">
                                        <div className="flex-fill">
                                            <span className="text-bold">Cupom de desconto:</span>
                                        </div>
                                        <div
                                            className="flex-fill ml-n1 mt-2 mb-3 w-50 d-flex flex-column">
                                            <input type="text" name={"cupom"}
                                                   className="form-control border-0 border-radius-10 text-sm"
                                                   placeholder="Cupom"
                                                   style={{backgroundColor: '#e6e6e6'}}
                                                   onChange={handleChangeCupom}/>
                                            <span
                                                className="p-1 error invalid-feedback">{user.data.cupom.invalidMessage}</span>
                                        </div>
                                        <div className="flex-fill d-flex align-items-center justify-content-between">
                                            <span>Valor a ser descontado:</span>
                                            <span className="" style={{
                                                color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                            }}>
                                                {helpers.converteMoeda(valorDesconto())}
                                            </span>
                                        </div>
                                    </div>
                                    : ''
                            }

                            <hr className="w-100 m-auto"/>

                            <div className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                <span>Total a pagar:</span>
                                <span className="" style={{
                                    color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                }}>
                                    {
                                        helpers.converteMoeda(
                                            !user.data.retirada && !isento()
                                                ? (helpers.carrinho.getTotalPedido(store, false) - valorDesconto()) + helpers.dot.get(user, 'data.taxaEntrega.valor_taxa', 0)
                                                : (helpers.carrinho.getTotalPedido(store, false) - valorDesconto())
                                        )
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Pagamento*/}
                <div className="p-2">
                    <div className="p-2">
                        <IconPagamento width={35}
                                       fill={!!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}/>
                        <span className="ml-2 text-bold" style={{
                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                        }}>
                            {
                                helpers.isIndoor(store)
                                    ? 'Pagamento Online'
                                    : 'Forma de Pagamento'
                            }
                        </span>
                    </div>
                    <div className="p-1 border-radius-15" style={{
                        border: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}>
                        <div className="p-0">

                            <Form ref={formRefCpf} initialData={user.data}>
                                <div className="p-2">
                                    <div className="icheck-danger d-inline">
                                        <input type="checkbox" name={'cpfNota'} id="cpfNota"
                                               ref={cpfNotaRef}
                                               defaultChecked={user.data.cpfNota}
                                               onChange={cpfNota}/>
                                        <label className="" htmlFor="cpfNota" style={{
                                            color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200',
                                        }}>
                                            Incluir CPF na nota?
                                        </label>
                                        {
                                            store.app.services.estabelecimento.cpf_obrigatorio == 1 &&
                                            <small className="text-muted" style={{
                                                fontSize: 8
                                            }}>(obrigatório)</small>
                                        }
                                    </div>
                                </div>

                                {
                                    user.data.cpfNota
                                        ? <div className="px-4 py-1">
                                            <Input type="text" name={"cpf"}
                                                   mask={"999.999.999-99"}
                                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                                   placeholder="CPF"
                                                   style={{backgroundColor: '#e6e6e6'}}
                                                   onChange={handleChange}/>
                                        </div>
                                        : ""
                                }
                            </Form>

                            {
                                helpers.isIndoor(store)
                                    ? ''
                                    :
                                    <>
                                        {/*Dinheiro*/}
                                        <div className="d-flex flex-column">
                                            {
                                                !!store.app.services.estabelecimento.formas.dinheiro
                                                    ?
                                                    <div
                                                        className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                                        <span>Dinheiro:</span>
                                                        <div className="icheck-danger d-inline">
                                                            <input type="radio" name={'formaPagamento'} id="dinheiro"
                                                                   defaultChecked={user.data.dinheiro}
                                                                   onClick={e => {
                                                                       setUser({
                                                                           ...user,
                                                                           data: {
                                                                               ...user.data,
                                                                               dinheiro: e.target.checked,
                                                                               maquineta: false,
                                                                               online: false,
                                                                               formaPagamento: store.app.services.estabelecimento.formas.dinheiro
                                                                           }
                                                                       })
                                                                   }}/>
                                                            <label className="menew__text-red" htmlFor="dinheiro"/>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div/>
                                            }

                                            {
                                                user.data.dinheiro
                                                    ?
                                                    <div className="p-3 ml-2 mt-n3 d-flex flex-column">
                                                        <div className="flex-fill">
                                                            <span>Troco para quanto?</span>
                                                        </div>
                                                        <div
                                                            className="flex-fill d-flex align-items-start justify-content-between">
                                                            <div
                                                                className="p-1 w-25 flex-fill d-flex align-items-start">
                                                                R$
                                                                {!user.data.sem_troco &&
                                                                <div className="d-flex flex-column">
                                                                    <CurrencyInput
                                                                        ref={trocoRef}
                                                                        type="text"
                                                                        separator=","
                                                                        name={"troco_para"}
                                                                        id={'troco_para'}
                                                                        className="p-3 ml-1 form-control border-0 border-radius-10 text-sm"
                                                                        style={{backgroundColor: '#e6e6e6'}}
                                                                        onBlur={trocoPara}
                                                                        onChange={e => {
                                                                            helpers.removeClassesElement(e, 'is-invalid');
                                                                        }}
                                                                    />
                                                                    <span className="p-1 error invalid-feedback">Informe um valor maior que o total</span>
                                                                </div>
                                                                }
                                                                {user.data.sem_troco &&
                                                                <CurrencyInput
                                                                    ref={trocoRef}
                                                                    readOnly={true}
                                                                    type="text"
                                                                    name={"troco_para"}
                                                                    id={'troco_para'}
                                                                    className="p-3 ml-1 form-control border-0 border-radius-10 text-sm"
                                                                    style={{backgroundColor: '#e6e6e6'}}
                                                                />
                                                                }
                                                            </div>
                                                            <div
                                                                className="p-1 ml-3 w-50 flex-fill d-flex align-items-start">
                                                                <div className="icheck-danger d-inline">
                                                                    <input type="checkBox" name={'sem_troco'}
                                                                           id="sem_troco"
                                                                           defaultChecked={user.data.sem_troco}
                                                                           onChange={e => {
                                                                               setUser({
                                                                                   ...user,
                                                                                   data: {
                                                                                       ...user.data,
                                                                                       troco_para: "",
                                                                                       sem_troco: e.currentTarget.checked
                                                                                   }
                                                                               });
                                                                           }}/>
                                                                    <label className="menew__text-red"
                                                                           htmlFor="sem_troco"/>
                                                                </div>
                                                                <span>Não preciso de troco</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                        <hr className="w-100 m-auto"/>
                                    </>
                            }

                            {
                                helpers.isIndoor(store)
                                    ? ''
                                    :
                                    <>
                                        {/*Maquineta*/}
                                        <div className="d-flex flex-column">
                                            {
                                                !!store.app.services.estabelecimento.formas.maquineta
                                                    ?
                                                    <div
                                                        className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                                        <span>Maquineta:</span>
                                                        <div className="icheck-danger d-inline">
                                                            <input type="radio" name={'formaPagamento'} id="maquineta"
                                                                   defaultChecked={user.data.maquineta}
                                                                   onClick={e => {
                                                                       setUser({
                                                                           ...user,
                                                                           data: {
                                                                               ...user.data,
                                                                               dinheiro: false,
                                                                               maquineta: e.target.checked,
                                                                               online: false,
                                                                               maquinetaCredito: false,
                                                                               maquinetaDebito: false,
                                                                               formaPagamento: false,
                                                                           }
                                                                       })
                                                                   }}/>
                                                            <label className="menew__text-red" htmlFor="maquineta"/>
                                                        </div>
                                                    </div>
                                                    : <div/>
                                            }
                                            {
                                                user.data.maquineta && user.data.tipoPagamentoAceite.maquinetaDebito
                                                    ?
                                                    <div className="p-3 mt-n3 d-flex flex-column">
                                                        <span>DÉBITO:</span>
                                                        {
                                                            user.data.formaPagamentoMaquineta.filter(forma => forma.forma_tipo === 'DÉBITO').length !== 0
                                                                ?
                                                                user.data.formaPagamentoMaquineta.filter(forma => forma.forma_tipo === 'DÉBITO').map(filtrado => (
                                                                    <div key={filtrado.cod_tipo_catoes}
                                                                         className="p-1 ml-3 flex-fill d-flex align-items-center justify-content-start">
                                                                        <div className="p-1">
                                                                            <div className="icheck-danger d-inline">
                                                                                <input type="radio" name={'maquineta'}
                                                                                       id={`${filtrado.cod_tipo_catoes}-${filtrado.descricao}`}
                                                                                       defaultChecked={filtrado.checked}
                                                                                       onClick={e => {
                                                                                           user.data.formaPagamentoMaquineta.map(item => {
                                                                                               item.checked = false;
                                                                                               if (item.cod_tipo_catoes === filtrado.cod_tipo_catoes) {
                                                                                                   item.checked = true
                                                                                               }
                                                                                           });
                                                                                           setUser({
                                                                                               ...user,
                                                                                               data: {
                                                                                                   ...user.data,
                                                                                                   formaPagamento: filtrado,
                                                                                                   formaPagamentoMaquineta: user.data.formaPagamentoMaquineta
                                                                                               }
                                                                                           })
                                                                                       }}
                                                                                />
                                                                                <label className="menew__text-red"
                                                                                       htmlFor={`${filtrado.cod_tipo_catoes}-${filtrado.descricao}`}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-1">
                                                                            {
                                                                                filtrado.arquivo && (
                                                                                    <img width='65px'
                                                                                         src={filtrado.arquivo}
                                                                                         alt={filtrado.descricao}/>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="p-1">
                                                                            <span>{`${filtrado.texto} - ${filtrado.forma_tipo}`}</span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                <div className="p-3 mt-n3 d-flex flex-column">
                                                                    {
                                                                        <div
                                                                            className="p-1 ml-3 w-50 flex-fill d-flex align-items-center">
                                                                            <div className="icheck-danger d-inline">
                                                                                <input type="radio" name={'maquineta'}
                                                                                       id="cartao_debito"
                                                                                       defaultChecked={user.data.maquinetaDebito}
                                                                                       onClick={e => {
                                                                                           setUser({
                                                                                               ...user,
                                                                                               data: {
                                                                                                   ...user.data,
                                                                                                   maquinetaCredito: false,
                                                                                                   maquinetaDebito: e.target.checked,
                                                                                                   formaPagamento: {
                                                                                                       id: '',
                                                                                                       tipo: 'DEBITO',
                                                                                                       cod: 30,
                                                                                                       forma: 30,
                                                                                                       forma_nome: 'MAQUINETA DEBITO',
                                                                                                       cod_tipo_catoes: null,
                                                                                                       forma_tipo: 'DÉBITO',
                                                                                                       texto: 'MAQUINETA'
                                                                                                   }
                                                                                               }
                                                                                           })
                                                                                       }}/>
                                                                                <label className="menew__text-red"
                                                                                       htmlFor="cartao_debito"/>
                                                                            </div>
                                                                            <span>Cartão de Débito</span>
                                                                        </div>

                                                                    }

                                                                </div>

                                                        }
                                                    </div>
                                                    : ''

                                            }

                                            {
                                                user.data.maquineta && user.data.tipoPagamentoAceite.maquinetaCredito
                                                    ?
                                                    <div className="p-3 mt-n3 d-flex flex-column">

                                                        <span>CRÉDITO:</span>
                                                        {
                                                            user.data.formaPagamentoMaquineta.filter(forma => forma.forma_tipo === 'CRÉDITO').length !== 0
                                                                ?
                                                                user.data.formaPagamentoMaquineta.filter(forma => forma.forma_tipo === 'CRÉDITO').map(filtrado => (
                                                                    <div key={filtrado.cod_tipo_catoes}
                                                                         className="p-1 ml-3 flex-fill d-flex align-items-center justify-content-start">
                                                                        <div className="p-1">
                                                                            <div className="icheck-danger d-inline">
                                                                                <input type="radio" name={'maquineta'}
                                                                                       id={`${filtrado.cod_tipo_catoes}-${filtrado.descricao}`}
                                                                                       defaultChecked={filtrado.checked}
                                                                                       onClick={e => {
                                                                                           user.data.formaPagamentoMaquineta.map(item => {
                                                                                               item.checked = false;
                                                                                               if (item.cod_tipo_catoes === filtrado.cod_tipo_catoes) {
                                                                                                   item.checked = true
                                                                                               }
                                                                                           });
                                                                                           setUser({
                                                                                               ...user,
                                                                                               data: {
                                                                                                   ...user.data,
                                                                                                   formaPagamento: filtrado,
                                                                                                   formaPagamentoMaquineta: user.data.formaPagamentoMaquineta
                                                                                               }
                                                                                           })
                                                                                       }}
                                                                                />
                                                                                <label className="menew__text-red"
                                                                                       htmlFor={`${filtrado.cod_tipo_catoes}-${filtrado.descricao}`}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-1">
                                                                            {
                                                                                filtrado.arquivo && (
                                                                                    <img width='65px'
                                                                                         src={filtrado.arquivo}
                                                                                         alt={filtrado.descricao}/>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="p-1">
                                                                            <span>{`${filtrado.texto} - ${filtrado.forma_tipo}`}</span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                <div className="p-3 mt-n3 d-flex flex-column">
                                                                    {
                                                                        <div
                                                                            className="p-1 ml-3 w-50 flex-fill d-flex align-items-center">
                                                                            <div className="icheck-danger d-inline">
                                                                                <input type="radio" name={'maquineta'}
                                                                                       id="cartao_credito"
                                                                                       defaultChecked={user.data.maquinetaCredito}
                                                                                       onClick={e => {
                                                                                           setUser({
                                                                                               ...user,
                                                                                               data: {
                                                                                                   ...user.data,
                                                                                                   maquinetaCredito: e.target.checked,
                                                                                                   formaPagamento: {
                                                                                                       id: '',
                                                                                                       tipo: 'CRÉDITO',
                                                                                                       cod: 3,
                                                                                                       forma: 3,
                                                                                                       forma_nome: 'MAQUINETA CREDITO',
                                                                                                       cod_tipo_catoes: null,
                                                                                                       forma_tipo: 'CRÉDITO',
                                                                                                       texto: 'MAQUINETA'
                                                                                                   }
                                                                                               }
                                                                                           })
                                                                                       }}/>
                                                                                <label className="menew__text-red"
                                                                                       htmlFor="cartao_credito"/>
                                                                            </div>
                                                                            <span>Cartão de Crédito</span>
                                                                        </div>

                                                                    }

                                                                </div>

                                                        }
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                        <hr className="w-100 m-auto"/>
                                    </>
                            }

                            <div className="d-flex flex-column">
                                {
                                    !!store.app.services.estabelecimento.formas.online
                                        ?
                                        <div
                                            className="p-3 ml-2 mr-2 d-flex align-items-center justify-content-between">
                                            <span>Online:</span>
                                            <div className="icheck-danger d-inline">
                                                <input type="radio" name={'formaPagamento'} id="online"
                                                       defaultChecked={user.data.online}
                                                       onClick={e => {
                                                           setUser({
                                                               ...user,
                                                               data: {
                                                                   ...user.data,
                                                                   dinheiro: false,
                                                                   maquineta: false,
                                                                   online: e.target.checked,
                                                                   cpfNota: true,
                                                                   formaPagamento: store.app.services.estabelecimento.formas.online,
                                                               }
                                                           });
                                                           if (!helpers.isIndoor(store)) {
                                                               cpfNotaRef.current.checked = true;
                                                           }
                                                       }}/>
                                                <label className="menew__text-red" htmlFor="online"/>
                                            </div>
                                        </div>
                                        : <div/>
                                }
                                {
                                    user.data.online
                                        ?

                                        <div className="p-2">
                                            {
                                                !!user.data.cards
                                                    ?
                                                    <div
                                                        className="p-2 mb-3 d-flex align-items-start justify-content-between"
                                                        onClick={null}>
                                                        <div
                                                            className="p-2 d-flex align-items-center justify-content-center"
                                                        >
                                                            <div className="flex-fill">
                                                                <BrandLogo brand={user.data.cards.brand}/>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-between">
                                                                <span>
                                                                    {`
                                                                        ${user.data.cards.brand || ''} - ${user.data.cards.tipo || ''}
                                                                    `}
                                                                </span>
                                                                <span>
                                                                    {`
                                                                        xxxx xxxx xxxx ${user.data.cards.last_four_digits || 'xxxx'}
                                                                    `}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="p-2 text-center d-flex align-items-center justify-content-center">
                                                        <span className=""
                                                              onClick={async e => {
                                                                  await helpers.animatedElement(e, 'bounceIn', 'fast');
                                                                  await helpers.awaitForTime(500);
                                                                  history.push(`/${idestabelecimento}/cartoes`, {route: 'Checkout'})
                                                              }}
                                                              style={{color: !!store.app.services.estabelecimento.cor2 ? store.app.services.estabelecimento.cor2 : '#ff2200'}}>
                                                            Trocar
                                                        </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <PaymentForm forwardRef={setCard}/>

                                            }
                                        </div>

                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-around">
                <div className="text-bold text-md btn btn-block"
                     onClick={async (e) => {
                         await helpers.animatedElement(e, 'bounceIn', 'fast');
                         await helpers.awaitForTime(500);
                         history.push(`/${idestabelecimento}/sacola`);
                     }}>
                    Cancelar
                </div>
                {
                    !state.loadingFinalizar
                        ?
                        <div className="p-2 text-bold text-md btn btn-block border-radius-20
                                    d-flex align-items-center justify-content-around" style={{
                            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                            color: '#fff',
                        }}
                             onClick={finalizar}>
                            <div className="p-2">Finalizar</div>
                        </div>
                        :
                        <div className="p-2 text-bold text-md btn btn-block border-radius-20
                                    d-flex align-items-center justify-content-around" style={{
                            backgroundColor: !!store.app.services.estabelecimento.cor1 ? store.app.services.estabelecimento.cor1 : '#ff2200',
                            color: '#fff',
                        }}>
                            <div className="p-2">Processando...</div>
                        </div>
                }
            </div>

            {
                !!state.loading
                    ?
                    <div
                        className="overlay-custom d-flex justify-content-center align-items-center flex-column text-white">
                        {
                            !state.whatsAppEnviar
                                ?
                                <div className="p-1">
                                    <i className="fas fa-2x fa-sync-alt fa-spin"/>
                                </div>
                                :
                                <div/>
                        }
                        <div className="p-1">
                            {
                                !!user.data.online && !state.whatsAppEnviar
                                    ?

                                    <span>Confirmando pagamento</span>
                                    :
                                    !!state.whatsAppEnviar
                                        ?
                                        <div/>
                                        :
                                        <span>Analisando pedido</span>
                            }
                        </div>
                        {
                            !!state.whatsAppEnviar
                                ?
                                <>
                                    <div className="p-1 d-flex flex-column align-items-center justify-content-center">
                                        {
                                            !!user.data.online
                                                ? <span>Pagamento confirmado!</span>
                                                : <span>Pedido confirmado!</span>
                                        }

                                        <span>Clique para enviar o pedido para o whatsapp da loja</span>
                                    </div>
                                    <div className="p-1 d-flex flex-row flex-wrap align-items-center justify-content-center ">
                                        <a className="m-2 btn btn-block btn-success" style={{width: 210}} id="send-whatsapp"
                                           target="_blank" href="#javascript" onClick={e => {
                                            setState({...state, loading: false});
                                            history.push(`/${idestabelecimento}/home`);
                                            dispatch({type: 'FINALIZAR_PEDIDO'});
                                        }}>
                                            Enviar Pedido
                                        </a>
                                        <a className="btn btn-block btn-danger" style={{width: 210, marginTop: 1}} id="noSend-whatsapp"
                                            onClick={e => {
                                            setState({...state, loading: false});
                                            store.app.modules.user.login.authenticated ? history.push(`/${idestabelecimento}/pedidos`) : history.push(`/${idestabelecimento}/home`);
                                            dispatch({type: 'FINALIZAR_PEDIDO'});
                                        }}>
                                            Não Enviar Pedido
                                        </a>
                                    </div>
                                </>
                                :
                                <div className="p-1">
                                    <span>Aguarde...</span>
                                </div>
                        }
                    </div>
                    :
                    <div/>
            }

        </div>
    );
};

export default Checkout;
