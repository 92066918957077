import React, {useEffect, useRef, useState} from 'react';
import Services from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {helpers} from "../../components/helpers";

/*Form*/
import {Form} from "@unform/web";
import * as Yup from "yup";
import Input from "../../components/Form/input";

/*Icons*/
import IconSearch from "../../components/Icons/icon-search";

const Passo2 = (props) => {
    const store = useSelector(store => store);
    const [animate, setAnimate] = useState('animated fadeInRight faster');
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const pedidos = helpers.carrinho.getQuantidadeItens(store);

    useEffect(() => {
        if (store.app.modules.user.login.authenticated) {
            if (pedidos > 0) {
                history.push(`/${idestabelecimento}/checkout`);
            } else {
                history.goBack();
            }
        }
    }, [store.app.modules.user.login.authenticated, history, idestabelecimento, pedidos]);

    const handleChange = (input_name = '') => {

        if (input_name === '') {
            formRef.current.setErrors({});
        } else {
            formRef.current.setFieldError(input_name, '');
        }

        const formData = formRef.current.getData();

        const data = {
            ...formData,
            endereco: {
                ...formData.endereco,
                latitude: helpers.dot.get(store, 'app.modules.user.login.data.endereco.latitude', null),
                longitude: helpers.dot.get(store, 'app.modules.user.login.data.endereco.longitude', null),
            }
        };

        dispatch({
            type: 'USER_DATA',
            user: {
                login: {
                    ...store.app.modules.user.login,
                    data: {
                        ...store.app.modules.user.login.data,
                        ...data
                    }
                }
            }
        });
    };

    const buscarCep = async () => {
        try {
            let formData = formRef.current.getData();
            formData.endereco.cep = formData.endereco.cep.replace(/\D/g, "");
            const schema = Yup.object().shape({
                endereco: Yup.object().shape({
                    cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um Cep para continuar!'),
                })
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            const response = await Services.getCep(formData.endereco.cep);
            if (!response.data.ok) {
                formRef.current.setErrors({
                    ...formRef.current.getErrors(),
                    endereco: {cep: 'Cep não encontrado'}
                });
                return false;
            }

            formRef.current.setData({
                endereco: {
                    cep: !!response.data.code ? response.data.code : formData.endereco.cep,
                    logradouro: !!response.data.address ? response.data.address : formData.endereco.logradouro,
                    bairro: !!response.data.district ? response.data.district : formData.endereco.bairro,
                    estado: !!response.data.state ? response.data.state : formData.endereco.estado,
                    cidade: !!response.data.city ? response.data.city : formData.endereco.cidade
                }
            });

            const responseGeo = await Services.googleGeoLocation(response.data);
            if (!helpers.collection().isEmpty(responseGeo)) {
                formData = formRef.current.getData();
                formData.endereco.latitude = responseGeo.location.lat;
                formData.endereco.longitude = responseGeo.location.lng;
            }

            dispatch({
                type: 'USER_DATA',
                user: {
                    login: {
                        data: {
                            ...store.app.modules.user.login.data,
                            ...formData
                        }
                    }
                }
            });

            formRef.current.setErrors({});
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errorMessages = {};
                err.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }

    };

    const handleSubmit = async (e) => {
        try {
            await helpers.animatedElement(e, 'bounceIn', 'fast');
            const formData = formRef.current.getData();
            const schema = Yup.object().shape({
                nome: Yup.string().required('Digite seu Nome'),
                cpf: Yup.string().required('Digite seu CPF'),
                telefone: Yup.string().required('Digite seu Telefone'),
                endereco: Yup.object().shape({
                    cep: Yup.string().min(8, 'Cep inválido').required('Por favor informe um CEP.'),
                    numero: Yup.string().required('Digite o número'),
                    logradouro: Yup.string().required('Digite o endereço'),
                    bairro: Yup.string().required('Digite o bairro'),
                    cidade: Yup.string().required('Digite a cidade'),
                    estado: Yup.string().required('Digite o estado'),
                    ponto_referencia: Yup.string().max(255, 'Limite máximo de caracteres: 255 caracteres')
                })
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            if (!helpers.toBrazilian.isCPF(formData.cpf)) {
                formRef.current.setErrors({cpf: 'CPF inválido'});
                return false;
            }

            let customerId = null;
            if(store.app.services.estabelecimento.mundipagg_secret_key) {
                const responseMundiPagg = await Services.mundiPaggAddCustomers({
                    mundipagg: store.app.services.estabelecimento.mundipagg_secret_key,
                    data: {
                        "name": store.app.modules.user.login.data.nome,
                        "email": store.app.modules.user.login.data.email,
                        "document": store.app.modules.user.login.data.cpf.replace(/\D/g, ""),
                        "type": "individual",
                        "document_type": "CPF"
                    }
                });

                customerId = responseMundiPagg.data.id;
            }



            const usuarioUpdateResponse = await Services.usuarioUpdate({
                nome: store.app.modules.user.login.data.nome,
                telefone: store.app.modules.user.login.data.telefone.replace(/\D/g, ""),
                email: store.app.modules.user.login.data.email,
                senha: store.app.modules.user.login.data.senha,
                cpf: store.app.modules.user.login.data.cpf.replace(/\D/g, ""),
                sistema_operacional: helpers.browser.getOSName(),
                navegador: helpers.browser.getBrowserName(),
                customers_id: customerId
            });

            if (!!usuarioUpdateResponse.data.info) {
                helpers.toast.info(`cod: ${usuarioUpdateResponse.data.cod} - ${usuarioUpdateResponse.data.info}`);
                return false;
            }

            if (usuarioUpdateResponse.data) {

                const data = {
                    'deliverymenew_usuario': usuarioUpdateResponse.data.usuario[0].hash,
                    'cep': formData.endereco.cep.replace(/\D/g, ""),
                    'pais': 'BR3',
                    'estado': formData.endereco.estado,
                    'cidade': formData.endereco.cidade,
                    'bairro': formData.endereco.bairro,
                    'logradouro': formData.endereco.logradouro,
                    'numero': formData.endereco.numero,
                    'complemento': formData.endereco.complemento,
                    'ponto_referencia': formData.endereco.ponto_referencia,
                    'latitude': store.app.modules.user.login.data.endereco.latitude,
                    'longitude': store.app.modules.user.login.data.endereco.longitude,
                    'tipo': 'c'
                };

                await Services.usuarioEnderecoUpdate(data);

                const response = await Services.login({
                    email: store.app.modules.user.login.data.email,
                    senha: store.app.modules.user.login.data.senha
                });

                helpers.toast.success('Cadastrado realizado com sucesso!');
                formRef.current.setErrors({});

                dispatch({
                    type: 'USER_DATA',
                    user: {
                        semCadastro: false,
                        data: {
                            ...store.app.modules.user.login.data,
                            confirme: null,
                            senha: null,
                            customers_id: customerId
                        },
                        login: {
                            authenticated: true,
                            data: {
                                ...store.app.modules.user.login.data,
                                ...response.data[0],
                                confirme: null,
                                senha: null
                            }
                        }
                    }
                });
            }

        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                helpers.toast.error('Por favor, verifique os dados e tente novamente');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    return (
        <div className={`h-100 d-flex flex-column ${animate}`}>
            <div className="p-2 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center">
                <span className="h3 m-0 font-weight-bolder">Agora falta pouco...</span>
                <span className="text-muted">Preencha os campos abaixo para continuar</span>
            </div>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column">
                <div className="p-2">
                    <Form ref={formRef} initialData={store.app.modules.user.login.data} onSubmit={handleSubmit}>

                        <div className="p-1">
                            <Input type="text" name={"nome"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Nome"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("nome")}/>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"cpf"}
                                   mask={"999.999.999-99"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="CPF"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("cpf")}/>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"telefone"}
                                   mask={"(99) 9 9999-9999"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Telefone"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("telefone")}/>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"email"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="E-mail"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("email")}/>
                        </div>

                        <div className="p-1 d-flex align-items-start justify-content-between">
                            <div className="flex-grow-1">
                                <Input type="text" name={"endereco.cep"}
                                       mask={"99999-999"}
                                       className="p-3 form-control border-0 border-radius-10 text-sm"
                                       placeholder="CEP"
                                       style={{backgroundColor: '#e6e6e6'}}
                                       onChange={() => handleChange("endereco.cep")}/>
                            </div>
                            <div className="p-1 flex-grow-0 ml-2 btn border-radius-10"
                                 style={{
                                     backgroundColor: '#606060',
                                     width: '75px',
                                 }}
                                 onClick={buscarCep}>
                                <IconSearch width={25}/>
                            </div>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"endereco.logradouro"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Endereço"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("endereco.logradouro")}/>
                        </div>

                        <div className="p-1 d-flex align-items-start justify-content-between">
                            <div className="flex-grow-0 w-25 mr-2">
                                <Input type="text" name={"endereco.numero"}
                                       className="p-3 form-control border-0 border-radius-10 text-sm"
                                       placeholder="Nº"
                                       style={{backgroundColor: '#e6e6e6'}}
                                       onChange={() => handleChange("endereco.numero")}/>
                            </div>
                            <div className="flex-grow-1 w-75">
                                <Input type="text" name={"endereco.bairro"}
                                       className="p-3 form-control border-0 border-radius-10 text-sm"
                                       placeholder="Bairro"
                                       style={{backgroundColor: '#e6e6e6'}}
                                       onChange={() => handleChange("endereco.bairro")}/>
                            </div>
                        </div>

                        <div className="p-1 d-flex align-items-start justify-content-between">
                            <div className="flex-grow-0 w-25 mr-2">
                                <Input type="text" name={"endereco.estado"}
                                       className="p-3 form-control border-0 border-radius-10 text-sm"
                                       placeholder="UF"
                                       style={{backgroundColor: '#e6e6e6'}}
                                       onChange={() => handleChange("")}/>
                            </div>
                            <div className="flex-grow-1 w-75">
                                <Input type="text" name={"endereco.cidade"}
                                       className="p-3 form-control border-0 border-radius-10 text-sm"
                                       placeholder="Cidade"
                                       style={{backgroundColor: '#e6e6e6'}}
                                       onChange={() => handleChange("endereco.cidade")}/>
                            </div>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"endereco.complemento"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Complemento"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={() => handleChange("endereco.complemento")}/>
                        </div>

                        <div className="p-1">
                            <Input type="text" name={"endereco.ponto_referencia"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Referência"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   maxLength={255}
                                   onChange={() => handleChange("endereco.ponto_referencia")}/>
                        </div>
                        <button type="submit" className="d-none"/>
                    </Form>
                </div>
            </div>

            <div className="flex-grow-1 p-1 d-flex flex-column align-items-center justify-content-center">
                <button type="button" className="w-50 menew__btn-danger btn btn-block btn-lg"
                        onClick={handleSubmit}>
                    <span className="text-bold text-md">CRIAR MINHA CONTA</span>
                </button>
                <button type="button" className="w-50 btn btn-block btn-lg"
                        onClick={() => {
                            setAnimate('animated fadeOutRight faster');
                            setTimeout(() => {
                                props.passo(1)
                            }, 200);
                        }}>
                    <span className="text-bold text-md">VOLTAR</span>
                </button>
            </div>
        </div>
    );
};

export default Passo2;
