import React, {useCallback, useEffect, useRef, useState} from 'react';
import Services from "../../services";
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {helpers} from "../../components/helpers";

/*Layout*/
import Footer from "../../components/layout/footer";

/*Form*/
import {Form} from "@unform/web";
import Input from "../../components/Form/input";
import * as Yup from 'yup';

/*Icons*/
import deliverymenewlogo from '../../assets/images/deliverymenew-logo.svg'
import Header from "../../components/layout/header";
import ReloadControll from "../../components/reload-controll";

const Login = () => {

    const store = useSelector(store => store);
    const {idestabelecimento} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        email: '',
        senha: '',
        lembrar: false
    });
    const formRef = useRef();

    const handleChange = () => {
        formRef.current.setErrors({});
        const formData = formRef.current.getData();
        setUser({
            ...user,
            email: formData.email,
            senha: formData.senha
        });
    };

    const handleSubmit = async (e) => {

        try {

            await helpers.animatedElement(e, 'bounceIn', 'fast');
            await helpers.awaitForTime(500);
            const formData = formRef.current.getData();
            const schema = Yup.object().shape({
                email: Yup.string().email('Email inválido').required('Digite seu email'),
                senha: Yup.string().required('Digite sua senha')
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            const response = await Services.login(user);
            if (helpers.collection().isEmpty(response.data)) {
                helpers.toast.error('Credenciais inválidas!');
                return false;
            }

            const userAddress = await Services.usuarioEnderecoGet({
                'deliverymenew_usuario': response.data[0].hash
            });

            // if (helpers.collection().isEmpty(userAddress.data)) {
            //     helpers.toast.info('Deve redirecionar para a tela de completar o cadastro');
            //     return false;
            // }

            if(!response.data[0].customers_id && store.app.services.estabelecimento.mundipagg_secret_key){
                const responseMundiPaggAddCustomers = await Services.mundiPaggAddCustomers({
                    mundipagg:store.app.services.estabelecimento.mundipagg_secret_key,
                    data:{
                        "name": response.data[0].nome,
                        "email": response.data[0].email,
                        "document": response.data[0].cpf,
                        "type": "individual",
                        "document_type": "CPF"
                    }
                });

                const updateCustomersId = await Services.usuarioUpdate({
                    nome: response.data[0].nome,
                    telefone: response.data[0].telefone,
                    email: response.data[0].email,
                    senha: user.senha,
                    cpf: response.data[0].cpf,
                    customers_id: responseMundiPaggAddCustomers.data.id,
                    hash: response.data[0].hash
                })

                response.data[0].customers_id = responseMundiPaggAddCustomers.data.id
            }

            const getCardsWallet =  await Services.mundiPaggGetAllCards({
                'deliverymenew_usuario': response.data[0].hash
            })

            let cardFavorite = null;
            if(JSON.parse(getCardsWallet.data[0].cards_json)) {
                cardFavorite = JSON.parse(getCardsWallet.data[0].cards_json).filter(card => card.cardFavorite === true);
            }
            dispatch({
                type: 'USER_DATA',
                user: {
                    data: {
                        nome: response.data[0].nome,
                        email: response.data[0].email,
                        cpf: response.data[0].cpf,
                        telefone: response.data[0].telefone,
                        enderecos: [...userAddress.data],
                        endereco: {...helpers.collection().first(userAddress.data)},
                        customers_id: response.data[0].customers_id
                    },
                    login: {
                        authenticated: true,
                        lembrar: user.lembrar,
                        data: response.data[0]
                    },
                    cards:JSON.parse(getCardsWallet.data[0].cards_json),
                    cardFavorite:cardFavorite ? cardFavorite : null,
                    semCadastro: false
                }
            });

            formRef.current.setErrors({});

        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                helpers.toast.error('Por favor verifique os dados e tente novamente');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    const esqueceuSenha = async (e) => {

        try {

            await helpers.animatedElement(e, 'bounceIn', 'fast');
            await helpers.awaitForTime(500);

            dispatch({type: 'PRE_LOAD', preLoad: true});

            const formData = formRef.current.getData();
            const schema = Yup.object().shape({
                email: Yup.string().email('Email inválido').required('Por favor informe seu email para recuperar sua senha'),
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            await Services.usuarioPasswordRecovery({
                email: formData.email,
                estabelecimento: store.app.services.estabelecimento.slug
            });

            helpers.toast.success('As instruções para redefinição de senha foram enviadas para o email informado.');
            dispatch({type: 'PRE_LOAD', preLoad: false});

            formRef.current.setErrors({});
        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                dispatch({type: 'PRE_LOAD', preLoad: false});
                helpers.toast.error('Por favor verifique os dados e tente novamente');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    const load = useCallback(() => {
        if (!store.app.services.loaded) {
            history.push(`/${idestabelecimento}`);
            return false;
        } else if (store.app.modules.user.login.authenticated && helpers.carrinho.getQuantidadeItens(store) > 0) {
            history.push(`/${idestabelecimento}/checkout`);
            return false;
        } else if (store.app.modules.user.login.authenticated) {
            history.push(`/${idestabelecimento}/home`);
            return false;
        }
    }, [history, idestabelecimento, store]);

    useEffect(() => {
        load();
    }, [load]);

    if (helpers.isCardapio()) {
        history.push(`/${idestabelecimento}/home`);
        dispatch({
            type: 'ANOTHERS', anothers: {
                anchorInicio: true
            }
        });
        return false;
    }

    if (helpers.reloadControl()) {
        return (
            <ReloadControll/>
        );
    }

    return (
        <div className="h-100 d-flex flex-column justify-content-between scroll scroll-position-initial">

            <Header title={"Login"}/>

            <div className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column justify-content-center">

                <div className="p-2 flex-shrink-0 flex-grow-1 d-flex align-items-center justify-content-center">
                    <img className="img-fluid" src={deliverymenewlogo} alt="deliverymenew-logo"
                         width={"200px"}/>
                </div>

                <div className="p-2 flex-shrink-0 d-flex flex-column align-items-center justify-content-center">
                    <Form ref={formRef}
                          initialData={user}
                          onSubmit={handleSubmit}
                          className="w-75">
                        <div className="p-1">
                            <Input type="text" name={"email"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Email"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                        <div className="p-1">
                            <Input type="password" name={"senha"}
                                   className="p-3 form-control border-0 border-radius-10 text-sm"
                                   placeholder="Senha"
                                   style={{backgroundColor: '#e6e6e6'}}
                                   onChange={handleChange}/>
                        </div>
                        <button type="submit" className="d-none"/>
                    </Form>
                </div>

                <div
                    className="p-2 flex-shrink-0 flex-grow-0 d-flex flex-column align-items-center justify-content-center">
                    <div
                        className="p-1 icheck-danger d-inline d-flex align-items-center justify-content-center position-relative">
                        <input type="checkbox"
                               name={"lembrar"}
                               id="checkbox-lembrar"
                               defaultValue={user.lembrar}
                               className="form-control form-control-sm"
                               onClick={() => {
                                   setUser({
                                       ...user,
                                       lembrar: !user.lembrar
                                   });
                               }}/>
                        <label htmlFor="checkbox-lembrar" className="form-check-label">
                            <span className="form-text text-muted">Continuar conectado</span>
                        </label>
                    </div>
                </div>

                <div
                    className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                    <div className="p-1 w-50">
                        <button type="button" className="btn btn-block menew__btn-danger btn-lg"
                                onClick={handleSubmit}>
                            <strong>Entrar</strong>
                        </button>
                    </div>
                    <div className="p-1" onClick={esqueceuSenha}>
                        <span>Esqueceu a senha?</span>
                    </div>
                </div>

                <div
                    className="p-2 flex-shrink-0 flex-grow-1 d-flex flex-column align-items-center justify-content-end">
                    <span>Ainda não é cadastrado?</span>
                    <span className="menew__text-red"
                          onClick={() => {
                              history.push(`/${idestabelecimento}/cadastro`)
                          }}>
                        <strong>Cadastre-se agora!</strong>
                    </span>

                    {
                        (helpers.carrinho.getQuantidadeItens(store) > 0 && !!store.app.services.estabelecimento.mvdelivery_pedir_sem_cadastro) && (
                            <div className="text-center">
                                <span>ou</span>
                                <br/>
                                <span className="menew__text-red text-bold" onClick={() => {
                                    dispatch({
                                        type: 'USER_DATA',
                                        user: {
                                            semCadastro: true,
                                            cardFavorite: [],
                                            cards: []
                                        }
                                    });
                                    setTimeout(() => {
                                        history.push(`/${idestabelecimento}/checkout`);
                                    }, 200);
                                }}>Continue sem cadastro.</span>
                            </div>
                        )
                    }
                    
                </div>

            </div>

            <Footer/>

        </div>
    );
};

export default Login;
