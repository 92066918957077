import React from 'react';

const IconPagamento = ({
                  style = {},
                  fill = "#ff1200",
                  width = "100%",
                  className = "",
                  x = "0px", y = "0px",
                  viewBox = "0 0 100 100",
                  xmlns = "http://www.w3.org/2000/svg",
                  xmlnsXlink = "http://www.w3.org/1999/xlink",
              }) => (
    <svg
        style={style}
        fill={fill}
        width={width}
        className={`svg-icon ${className || ""}`}
        x={x}
        y={y}
        viewBox={viewBox}
        xmlns={xmlns}
        xmlnsXlink={xmlnsXlink}>

        <path d="M-13.7,44.4c-6.1-5.9-12.3-12-18.3-17.8l-6-5.9c-0.8-0.8-1.6-1.5-2.3-2.3c-1.3-1.3-2.6-2.6-3.9-3.9
	c-3.3-3.1-8.3-3.1-11.6,0.2l-9.8,9.5c-6.9,6.7-13.9,13.3-20.8,20c-1.6,1.5-3,3.1-3.6,5.4l0,2.9l0.2,0.2c0,0,0,0,0,0
	c0.8,2.9,2.5,4.8,5.1,5.6c0,7.1,0,14.3,0,21.4l0,3.2c0,5.5,3.9,9.4,9.4,9.4c2,0,4.1,0,6.1,0c0.6,0,1.2,0,1.8-0.1
	c4.6-0.9,7.5-4.5,7.5-9.2l0-5.4c0-2.6,0-5.2,0-7.8c0-3.5,2.5-6.3,5.9-6.6c2.3-0.2,4.6-0.2,6.7,0c3.6,0.2,6.4,3.3,6.3,7
	c-0.1,2.9-0.1,5.9,0,8.7c0,1.3,0,2.7,0,4c0,5.4,3.9,9.4,9.3,9.4c0.8,0,1.6,0,2.5,0c1.2,0,2.4,0,3.6,0c0.1,0,0.2,0,0.2,0
	c2.4,0,4.8-1,6.5-2.7c1.8-1.8,2.8-4.2,2.7-6.8c-0.1-5.1-0.1-10.3-0.1-15.4c0-3,0-6,0-8.9c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0
	c2.9-0.9,4.7-3,5.2-6C-10.5,49.3-11.4,46.7-13.7,44.4z M-22.8,73.4l0,3.1c0,1.6,0,3.1,0,4.7l0,2.2c0,1.3-0.6,2-1.9,2
	c-1.8,0-3.7,0-5.5,0l-0.7,0c-0.7,0-1.2-0.3-1.4-0.6c-0.4-0.4-0.6-1-0.6-1.5c0.1-3.2,0.1-6.5,0-9.7l0-3.3c0-7.7-6-13.9-13.7-14
	l-1.4,0c-0.9,0-1.8-0.1-2.7-0.1c-1.4,0-2.7,0.1-4.1,0.3c-6.6,1-11.6,6.9-11.7,13.6l0,0.7c0,4.1-0.1,8.4,0,12.6
	c0,0.7-0.2,1.2-0.5,1.6c-0.4,0.4-0.9,0.6-1.6,0.6c0,0,0,0,0,0l-0.9,0c-1.8,0-3.6,0-5.5,0c-0.6,0-1.2-0.2-1.6-0.6
	c-0.3-0.3-0.6-0.8-0.6-1.5c0.1-3.1,0.1-6.2,0-9.2c0-0.8,0-1.7,0-2.5l0.1-14.2c0-3-2-5.4-4.9-5.9c-0.3,0-0.4-0.2-0.7-0.6
	C-72.1,40.7-61.6,30.5-51,20.3l0.3-0.3c0.4-0.4,0.5-0.5,0.6-0.5c0.4,0,0.6,0.1,0.8,0.3C-38.7,30-28.1,40.2-17.5,50.4
	c0.1,0.1,0.2,0.2,0,0.8c-0.1,0.4-0.2,0.4-0.4,0.5c-3,0.5-5,3-5,6C-22.8,62.9-22.8,68.1-22.8,73.4z"/>
        <g>
            <path d="M172.4,45.4c0-11.7-9.6-21.3-21.3-21.3c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7c7.6,0,13.8,6.2,13.8,13.8
		c0,2.1,1.7,3.7,3.7,3.7C170.7,49.1,172.4,47.5,172.4,45.4z"/>
            <path d="M196.8,85.2l-20-20c4.3-5.5,6.8-12.4,6.8-19.8c0-17.9-14.6-32.5-32.5-32.5c-17.9,0-32.5,14.6-32.5,32.5
		s14.6,32.5,32.5,32.5c7.8,0,14.9-2.7,20.5-7.3l19.9,19.9c0.7,0.7,1.7,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1
		C198.2,89,198.2,86.7,196.8,85.2z M126.1,45.4c0-13.8,11.2-25,25-25s25,11.2,25,25s-11.2,25-25,25S126.1,59.2,126.1,45.4z"/>
        </g>
        <g>
            <path d="M183.8,90c-1.4-0.6-2.8-1.1-3.8-2.3c-1.8-1.9-2.3-4.1-2.1-6.6c1.7-15.1,3.3-30.1,5-45.2
		c0.5-4.7,3.5-7.2,8.2-7.2c1.6,0,3.1-0.1,4.7,0c1.4,0.1,1.7-0.4,1.6-1.7c-0.1-1.5,0-3,0.1-4.5c0.5-7.1,6.7-12.7,13.9-12.5
		c7.2,0.2,13.1,6.1,13.3,13.2c0,1.4,0.1,2.7,0,4.1c-0.1,1.1,0.2,1.6,1.4,1.5c1.2-0.1,2.4,0,3.6,0c4.6,0,7.5,2.6,8.1,7.2
		c0.7,5.7,1.3,11.5,1.9,17.2c1,8.9,1.9,17.8,2.9,26.7c0.6,5.6-0.5,7.8-5.7,10.1C219.2,90,201.5,90,183.8,90z M210.4,85
		c7.9,0,15.9,0,23.8,0c3.3,0,4-0.9,3.6-4.2c-1-9.1-2-18.1-3-27.2c-0.6-5.8-1.3-11.6-1.9-17.4c-0.2-1.7-1.1-2.5-2.8-2.5
		c-1.4,0-2.8,0-4.2,0c-0.3,0-0.7-0.1-1,0.3c-0.5,0.8,0.2,4,0.9,4.5c2.4,1.7,3.4,4.4,2.5,7.1c-0.8,2.5-3.2,4.3-5.9,4.3
		c-2.7,0-5.2-1.8-6-4.4c-0.8-2.7,0.1-5.4,2.6-7.1c0.7-0.5,1.3-3.9,0.8-4.5c-0.1-0.2-0.5-0.2-0.7-0.2c-5.2,0-10.4,0-15.6,0
		c-0.3,0-0.7-0.1-0.9,0.4c-0.5,1.3,0.2,3.9,1.3,4.8c2.8,2.3,3,6.4,0.5,9c-2.4,2.5-6.5,2.6-9,0.1c-2.5-2.6-2.5-6.9,0.4-9
		c1.8-1.2,1.8-2.6,1.8-4.4c0-0.8-0.2-1.1-1.1-1.1c-1.8,0.1-3.6,0-5.5,0c-1.9,0-2.8,0.8-3,2.7c-0.2,1.4-0.3,2.9-0.4,4.4
		c-1.5,13.6-3,27.1-4.6,40.7c-0.3,2.6,0.6,3.5,3.2,3.6C194.2,85,202.3,85,210.4,85z M211.1,28.7c2.5,0,5,0,7.5,0
		c1,0,1.3-0.3,1.2-1.2c-0.1-1.4,0-2.8,0-4.2c-0.2-4.5-4-8.2-8.6-8.3c-4.5-0.1-8.4,3.4-8.7,7.9c-0.1,1.5,0,3-0.1,4.5
		c-0.1,1.1,0.4,1.3,1.3,1.3C206.2,28.7,208.6,28.7,211.1,28.7z M201,43.7c-0.1-0.7-0.5-1.2-1.2-1.2c-0.8,0-1.3,0.4-1.3,1.2
		c0,0.7,0.4,1.2,1.1,1.2C200.4,45,200.8,44.5,201,43.7z M222.1,42.5c-0.6,0.2-1.1,0.5-1.1,1.2c0,0.8,0.5,1.3,1.3,1.2
		c0.7,0,1.2-0.5,1.2-1.2C223.5,42.9,223,42.5,222.1,42.5z"/>
        </g>
        <g>
            <path d="M186.6,7.3c1.6,0,3.2-0.6,4.4-1.8l28.8-28.8c2.4-2.4,2.4-6.4,0-8.8L191-60.9c-2.4-2.4-6.4-2.4-8.8,0
		c-2.4,2.4-2.4,6.4,0,8.8l24.4,24.4L182.2-3.3c-2.4,2.4-2.4,6.4,0,8.8C183.4,6.7,185,7.3,186.6,7.3z"/>
        </g>
        <g>
            <g>
                <path d="M93.6-14.7c-1,1.6-2.6,1.2-4,1.1c-1.2-0.1-1.8,0.3-2.4,1.4c-1.2,2.3-3.2,3.5-5.8,3.4c-2.6,0-4.5-1.2-5.6-3.5
			c-0.5-1.1-1.1-1.3-2.2-1.3c-6.6,0-13.2,0.1-19.8,0c-1.2,0-1.9,0.3-2.5,1.4c-1.1,2.3-3.1,3.3-5.7,3.3c-2.6,0-4.6-1.1-5.7-3.4
			c-0.6-1.2-1.4-1.4-2.5-1.4c-2.9,0.1-2.9,0-3.5-2.7c-0.2-0.9-0.8-0.7-1.3-0.7c-2.7,0-5.4,0-8.1,0c-1.2,0-2.3-0.2-2.3-1.7
			c0-1.5,1-1.8,2.2-1.8c2.7,0,5.4,0,8.1,0c1,0,1.3-0.3,1.3-1.3c0-6.7-0.1-13.3,0-20c0-1.3-0.7-1.3-1.6-1.3c-4.8,0-9.6,0-14.3,0
			c-1.1,0-2.1-0.1-2.4-1.4c-0.3-1.3,0.5-2.1,2.2-2.1c4.8,0,9.6-0.1,14.3,0c1.3,0,1.9-0.2,1.8-1.7c-0.2-1.7,0.7-2.4,2.4-2.4
			c11.5,0,23,0,34.5,0c1.8,0,2.4,0.8,2.4,2.5c0,1.7,0,3.4,0,5c0,1,0.3,1.4,1.3,1.3c2-0.1,4,0.1,6,0c1.4-0.1,2.3,0.4,3.2,1.4
			c3.2,3.8,6.5,7.5,9.8,11.2C93.6-24.3,93.6-19.5,93.6-14.7z M53.7-47.2c-4.7,0-9.4,0.1-14.1,0c-1.6,0-2.2,0.2-2.2,2.1
			c0.1,8.8,0,17.6,0.1,26.3c0,0.6-0.5,1.5,0.5,1.8c1,0.3,1.4-0.3,1.9-1.1c1.2-2.4,3.2-3.6,5.9-3.6c2.6,0.1,4.6,1.3,5.7,3.6
			c0.4,0.9,0.9,1.2,1.9,1.2c5,0,9.9-0.1,14.9,0c1.2,0,1.7-0.3,1.7-1.6c-0.1-9-0.1-17.9,0-26.9c0-1.4-0.4-1.8-1.7-1.7
			C63.4-47.1,58.5-47.2,53.7-47.2z M73.3-27.8c0,3,0,6.1,0,9.1c0,0.6-0.4,1.5,0.7,1.7c0.8,0.1,1.2-0.3,1.6-1
			c1.1-2.4,3.1-3.7,5.8-3.7c2.8,0,4.8,1.3,6,3.8c0.2,0.3,0.4,0.7,0.7,0.9c1,0.6,2-0.1,2-1.2c0-3.2,0.6-6.5-0.3-9.4
			c-0.7-2.4-3.3-4.2-5.1-6.2c-3.9-4.5-3.9-4.5-9.9-4.4c-1.3,0-1.6,0.5-1.6,1.7C73.3-33.7,73.3-30.7,73.3-27.8z M48.7-15.2
			c0-1.7-1.3-3.1-3-3.2c-1.7-0.1-3.3,1.4-3.3,3.1c0,1.6,1.6,3.1,3.1,3.1C47.3-12.1,48.7-13.5,48.7-15.2z M81.4-12.1
			c1.6,0,3.1-1.4,3.2-3c0.1-1.6-1.3-3.1-2.9-3.2c-1.8-0.1-3.2,1.3-3.3,3C78.5-13.6,79.8-12.2,81.4-12.1z"/>
                <path d="M6.4-32.5c0.7-0.9,1.6-1.2,2.7-1.2c5.6,0,11.2,0,16.8,0c1.3,0,2.5,0.3,2.5,1.8c0,1.5-1.2,1.7-2.5,1.7
			c-5.6,0-11.2,0-16.8,0c-1.1,0-2-0.2-2.7-1.2C6.4-31.8,6.4-32.2,6.4-32.5z"/>
                <path
                    d="M17.6-17c-1.1-0.1-2-0.5-2-1.8c0-1.3,0.9-1.7,2.1-1.7c1.2,0,2.1,0.5,2.1,1.7C19.9-17.5,18.9-17,17.6-17z"/>
            </g>
        </g>
        <g>
            <path d="M50,87.8c-20.8,0-37.8-17-37.8-37.8c0-20.7,17-37.7,37.7-37.7c20.9,0,37.8,17,37.8,37.8
		C87.7,70.8,70.7,87.8,50,87.8z M81,50.2C80.8,32.5,67,18.9,49.7,19.1c-17.5,0.2-31.1,14.2-30.9,31.8c0.2,16.8,14.7,30.6,32,30.4
		C67.4,81.1,81.1,67,81,50.2z"/>
            <path d="M61.1,58.6c-0.3,4.2-2.3,7.6-6.5,9.6c-1.1,0.5-1.6,1-1.6,2.3c0,2.2-1.4,3.7-3.4,3.7c-1.8,0-3.2-1.4-3.3-3.6
		c0-1.4-0.5-2-1.7-2.6c-3.7-1.9-6-4.9-6.4-9.2c-0.2-2.4,0.9-3.9,2.8-4.2c2.1-0.3,3.4,1,3.8,3.4c0.5,3,2.1,4.6,4.8,4.5
		c2.6,0,4.5-1.9,4.6-4.5c0.1-2.7-1.6-4.7-4.5-4.8c-5.1-0.3-8.8-2.7-10.7-7.5c-2-5.4,0.5-11.3,5.8-13.8c1.2-0.5,1.6-1.1,1.6-2.4
		c0-2.3,1.5-3.7,3.5-3.6c1.9,0,3.3,1.4,3.3,3.6c0,1.4,0.6,2,1.7,2.6c3.7,1.9,6,4.9,6.3,9.2c0.2,2.4-1.1,4-3.1,4.1
		c-1.9,0.1-3.2-1.2-3.5-3.6c-0.3-2.1-1.2-3.7-3.4-4.3c-1.9-0.6-3.6-0.1-4.9,1.4c-1.4,1.6-1.6,3.4-0.7,5.3c0.9,1.9,2.6,2.5,4.7,2.6
		C56.5,47.3,61.2,52.3,61.1,58.6z"/>
        </g>

    </svg>
);

export default IconPagamento;
