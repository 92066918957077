import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {helpers} from "../../components/helpers";

/*Form*/
import {Form} from '@unform/web';
import Input from '../../components/Form/input';
import * as Yup from 'yup';

const Passo1 = (props) => {
    const store = useSelector(store => store);
    const [animate, setAnimate] = useState('animated fadeInLeft faster');
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const {idestabelecimento} = useParams();

    const handleSubmit = async () => {
        try {
            const formData = formRef.current.getData();
            const schema = Yup.object().shape({
                email: Yup.string().email('Email inválido').required('Digite seu email'),
                senha: Yup.string().max(6, 'A senha de ter no máximo 6 caracteres').required('Digite sua senha'),
                confirme: Yup.string()
                    .required('Confirme sua senha')
                    .max(6, 'A senha de ter no máximo 6 caracteres')
                    .oneOf([Yup.ref('senha'), null], 'Senhas divergentes')
            });

            await schema.validate(formData, {
                abortEarly: false
            });

            dispatch({
                type: 'USER_DATA',
                user: {
                    cardFavorite: [],
                    cards: [],
                    login: {
                        data: {
                            ...store.app.modules.user.login.data,
                            ...formData
                        }
                    }
                }
            });

            setAnimate('animated fadeOutLeft faster');
            await helpers.awaitForTime(200);
            props.passo(2);
            return true;
        } catch (errors) {
            if (errors instanceof Yup.ValidationError) {
                helpers.toast.error('Por favor verifique os dados e tente novamente');
                const errorMessages = {};
                errors.inner.forEach(error => {
                    errorMessages[error.path] = error.message;
                });
                const nameInput = formRef.current.getFieldRef(helpers.dot.get(errors, 'inner.0.path'));
                nameInput.focus();
                formRef.current.setErrors(errorMessages);
                return false;
            }
        }
    };

    const handleSubmitClearErrors = (input) => {
        formRef.current.setFieldError(input, '');
    };

    return (
        <div className={`flex-grow-1 d-flex flex-column ${animate}`}>
            <div className="p-2 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center">
                <span className="h3 m-0 font-weight-bolder">Seja Bem-vindo ao</span>
                <span className="menew__text-red h3 font-weight-bolder">Delivery Menew</span>
                <span>Preencha os campos abaixo para continuar</span>
            </div>

            <Form ref={formRef}
                  initialData={store.app.modules.user.login.data}
                  onSubmit={handleSubmit}
                  className="flex-grow-1 p-2">

                <div className="flex-grow-1 p-2 d-flex flex-column align-items-center justify-content-center">
                    <div className="w-75 form-group">
                        <label className="form-text text-muted" htmlFor="email">Email</label>
                        <Input type="email"
                               name={"email"}
                               placeholder="Email"
                               style={{backgroundColor: '#e6e6e6'}}
                               onClick={() => handleSubmitClearErrors("email")}
                               className="p-3 form-control border-0 border-radius-10 text-sm"/>
                    </div>
                    <div className="w-75 form-group">
                        <label className="form-text text-muted" htmlFor="senha">Senha</label>
                        <Input type="password"
                               name={"senha"}
                               placeholder="Senha"
                               style={{backgroundColor: '#e6e6e6'}}
                               onClick={() => handleSubmitClearErrors("senha")}
                               className="p-3 form-control border-0 border-radius-10 text-sm"/>
                    </div>
                    <div className="w-75 form-group">
                        <label className="form-text text-muted" htmlFor="confirme">Confirme sua senha</label>
                        <Input type="password"
                               name={"confirme"}
                               placeholder="Confirme"
                               style={{backgroundColor: '#e6e6e6'}}
                               onClick={() => handleSubmitClearErrors("confirme")}
                               className="p-3 form-control border-0 border-radius-10 text-sm"/>
                    </div>
                </div>

                <div className="flex-grow-1 p-1 d-flex flex-column align-items-center justify-content-center">
                    <button type="submit" className="w-50 menew__btn-danger btn btn-block btn-lg">
                        <span className="text-bold text-md">CONTINUAR</span>
                    </button>
                    <button type="button" className="w-50 btn btn-block btn-lg"
                            onClick={() => {
                                setAnimate('animated fadeOutLeft faster');
                                setTimeout(() => {
                                    history.goBack()
                                }, 200);
                            }}>
                        <span className="text-bold text-md">VOLTAR</span>
                    </button>

                </div>

            </Form>

        </div>
    );
};

export default Passo1;
