import React, {useEffect, useRef} from 'react';
import {useField} from '@unform/core';
// import Rselect from 'react-select';

const Select = ({name, options = {}, ...rest}) => {
    const inputRef = useRef(null);
    const {fieldName, registerField, defaultValue, error} = useField(name);
    const invalid = error ? 'is-invalid' : '';

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <>
            <select ref={inputRef} defaultValue={defaultValue}
                    {...rest} className={rest.className + " " + invalid}>
                {
                    options.map((option, key) => (
                        <option key={key} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
            {/*<Rselect ref={inputRef} defaultValue={defaultValue}*/}
            {/*        {...rest} className={rest.className + " " + invalid}*/}
            {/*        options={options}/>*/}
            {error &&
            <span id="exampleInputEmail1-error" className="error invalid-feedback">{error}</span>}
        </>
    );
};

export default Select;
