import React, {useEffect} from 'react';
import 'jquery-mask-plugin';

const AdmRedirect = () => {

    if (window.location.hostname == 'localhost'){
        window.location.href = `http://localhost:8080`;
    }
    
    if (window.location.hostname == 'delivery-menew.dev.menew.cloud'){
        window.location.href = `https://delivery-adm.dev.menew.cloud`;
    }
    
    if (window.location.hostname == 'delivery-menew.hom.menew.cloud'){
        window.location.href = `https://delivery-adm.hom.menew.cloud`;
    }
    
    if (window.location.hostname == 'delivery-menew.prod.menew.cloud' || window.location.hostname == 'delivery.menew.com.br'){
        window.location.href =  "https://delivery-adm.menew.com.br";
    }    
  
    return (
        <div>
            <br/><br/><br/><center>Carregando...</center>
        </div>
    );
};

export default AdmRedirect;
