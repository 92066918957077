import React, {useEffect, useState} from 'react';
import {Body} from "../home/styles";
import logo from "../../assets/images/logo-menew.svg";
import logoDeliveryAzul from '../../assets/images/delivery-azul-logo.png';
import marca from "../../assets/images/marca.svg";
import {useDispatch} from "react-redux";
import {helpers} from "../../components/helpers";

const Inexistente = (props) => {
    const dispatch = useDispatch();

    const [state] = useState({
        deliveryAzul: helpers.isDeliveryAzul() ? logoDeliveryAzul : marca,
        deliveryAzulEscondeLogo: helpers.isDeliveryAzul() ? '' : <img width={"10%"} src={logo} alt="logo"/>,
        deliveryAzulBackGround: helpers.isDeliveryAzul() ? 'background-inexistente-delivery-azul' : '',
        deliveryAzulTextWhite: helpers.isDeliveryAzul() ? 'text-red-delivery-azul' : 'text-white',

    });

    useEffect(() => {
        dispatch({type: 'LIMPAR'})
    }, [dispatch]);

    return (
        <Body className={`w-100 h-100 d-flex align-items-center flex-column text-center scroll ${state.deliveryAzulBackGround}`}>

            <div className="p-4 w-100 d-flex justify-content-end flex-shrink-0">
                {state.deliveryAzulEscondeLogo}
            </div>

            <div className="p-4 flex-shrink-1">
                <img width={"80%"} src={state.deliveryAzul} alt="marca"/>
            </div>

            <div className="p-4 flex-shrink-1 m-auto">
                <span className={state.deliveryAzulTextWhite}>
                    Esta loja não existe ou ainda não foi cadastrada.
                </span>
            </div>

        </Body>
    );
};

export default Inexistente;
