import {helpers} from "../../components/helpers";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

if (!localStorage.getItem('menew_uuid')) {
    localStorage.setItem('menew_uuid', uuidv4());
}
let uuid = localStorage.getItem('menew_uuid');

const INITIAL_STATE = {
    activeModule: {},
    modules: {
        produtos: {
            categoriaSelected: null,
            data: []
        },
        montagem: {
            data: []
        },
        carrinho: {
            pedidos: {
                produtosIndividual: [],
                produtosMontagem: []
            },
        },
        user: {
            data: {},
            login: {
                authenticated: false,
                data: []
            },
            cards: [],
            formaPagamento: {},
            semCadastro: false,
            cardFavorite: {}
        },
        anothers: {
            data: []
        },
        preLoad: false,
        pesquisa: {}
    },
    services: {
        uuid: uuid,
        loaded: false,
        estabelecimento: {},
        produtos: {},
        categorias: {},
        produtosObservacoes: {},
        touchGroup: {},
        kits: {},
        pizza: {},
        lansec: {},
        produtosCategorias: {},
        payment: {
            validateCard: false,
            cardValidated: false
        },
        taxaEntrega: {
            valor_taxa: 0,
            cobertura: true
        },
        historicoPedidos: {},
        indoor: {}
    }
};

const reducerStore = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ESTABELECIMENTO_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    estabelecimento: hydrateEstabelecimentoData(action.response)
                }
            };
        case 'FORMAS_PAGAMENTO_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    estabelecimento: hydrateFormasPagamentoData(action.response)
                }
            };
        case 'LOADED_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    loaded: action.loaded
                }
            };
        case 'LISTPRODUTOS_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    produtos: action.response
                }
            };
        case 'LISTCATEGORIAS_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    categorias: action.response
                }
            };
        case 'PRODUTOSOBSERVACOES_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    produtosObservacoes: action.response
                }
            };
        case 'TOUCHGROUP_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    touchGroup: action.response
                }
            };
        case 'KITS_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    kits: action.response
                }
            };
        case 'PIZZA_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    pizza: action.response
                }
            };
        case 'LANSEC_SERVICE':
            return{
                ...state,
                services: {
                    ...state.services,
                    lansec: action.response
                }
            };
        case 'PRODUTOSCATEGORIAS_SERVICE':
            return {
                ...state,
                services: {
                    ...state.services,
                    produtosCategorias: hydrateData(action.response)
                }
            };
        case 'PAYMENT':
            return {
                ...state,
                services: {
                    ...state.services,
                    payment: {
                        ...state.services.payment,
                        ...action.payment
                    }
                }
            };
        case 'PAYMENT_CLEAR':
            return {
                ...state,
                services: {
                    ...state.services,
                    payment: {
                        validateCard: false,
                        cardValidated: false
                    }
                }
            };
        case 'TAXA_ENTREGA':
            return {
                ...state,
                services: {
                    ...state.services,
                    taxaEntrega: {
                        ...state.services.taxaEntrega,
                        ...action.taxaEntrega
                    }
                }
            };
        case 'HISTORICO_PEDIDOS':
            return {
                ...state,
                services: {
                    ...state.services,
                    historicoPedidos: {
                        ...state.services.historicoPedidos,
                        ...action.historicoPedidos
                    }
                }
            };
        case 'INDOOR':
            return {
                ...state,
                services: {
                    ...state.services,
                    indoor: {
                        ...state.services.indoor,
                        ...action.indoor
                    }
                }
            };
        case 'FOOTER_DATA':
            return {
                ...state,
                modules: {
                    footer: {
                        totais: action.total
                    }
                }
            };
        case 'USER_DATA':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    user: {
                        ...state.modules.user,
                        ...action.user
                    }
                }
            };
        case 'PRODUTOS_DATA':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    produtos: {
                        ...state.modules.produtos,
                        data: [...action.data]
                    }
                }
            };
        case 'PRODUTOS':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    produtos: {
                        ...state.modules.produtos,
                        ...action.data
                    }
                }
            };
        case 'MONTAGEM_DATA':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    montagem: {
                        ...state.modules.montagem,
                        data: [...action.data]
                    }
                }
            };
        case 'PEDIDO_PRODUTOS_INDIVIDUAL':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    carrinho: {
                        ...state.modules.carrinho,
                        pedidos: {
                            ...state.modules.carrinho.pedidos,
                            produtosIndividual: [
                                ...state.modules.carrinho.pedidos.produtosIndividual,
                                ...action.produtos
                            ]
                        }
                    }
                }
            };
        case 'PEDIDO_PRODUTOS_MONTAGEM':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    carrinho: {
                        ...state.modules.carrinho,
                        pedidos: {
                            ...state.modules.carrinho.pedidos,
                            produtosMontagem: [
                                ...state.modules.carrinho.pedidos.produtosMontagem,
                                ...action.produtos
                            ]
                        }
                    }
                }
            };
        case 'PEDIDO_PRODUTOS_CLEAR':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    carrinho: {
                        ...state.modules.carrinho,
                        pedidos: {
                            ...state.modules.carrinho.pedidos,
                            ...action.pedidos
                        }
                    }
                }
            };
        case 'PRE_LOAD':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    preLoad: action.preLoad
                }
            };
        case 'PESQUISA':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    pesquisa: action.pesquisa
                }
            };
        case 'LIMPAR':
            return {
                ...state,
                activeModule: {},
                modules: {
                    produtos: {
                        categoriaSelected: null,
                        data: []
                    },
                    montagem: {
                        data: []
                    },
                    carrinho: {
                        pedidos: {
                            produtosIndividual: [],
                            produtosMontagem: []
                        },
                    },
                    user: helpers.dot.get(state, 'modules.user.login.lembrar') ? {...state.modules.user} : {
                        data: {},
                        login: {
                            authenticated: false,
                            data: []
                        },
                        card: {},
                        formaPagamento: {},
                        semCadastro: false,
                        cardFavorite: {}
                    },
                    pesquisa: {},
                    anothers: {
                        data: []
                    },
                    preLoad: false,
                },
                services: {
                    ...state.services,
                    payment: {
                        validateCard: false,
                        cardValidated: false
                    },
                    taxaEntrega: {
                        valor_taxa: 0,
                        cobertura: true
                    },
                    historicoPedidos: {},
                    indoor: {}
                }
            };
        case 'FINALIZAR_PEDIDO':
            return {
                ...state,
                activeModule: {},
                modules: {
                    produtos: {
                        categoriaSelected: null,
                        data: []
                    },
                    montagem: {
                        data: []
                    },
                    carrinho: {
                        pedidos: {
                            produtosIndividual: [],
                            produtosMontagem: []
                        },
                    },
                    user: {
                        ...state.modules.user
                    },
                    pesquisa: {},
                    anothers: {
                        data: []
                    },
                    preLoad: false,
                },
                services: {
                    ...state.services,
                    payment: {
                        validateCard: false,
                        cardValidated: false
                    },
                    taxaEntrega: {
                        valor_taxa: 0,
                        cobertura: true
                    },
                    historicoPedidos: {},
                    indoor: {
                        ...state.services.indoor
                    }
                }
            };
        case 'UPDATE':
            return {
                ...state
            };
        case 'ANOTHERS':
            return {
                ...state,
                modules: {
                    ...state.modules,
                    anothers: {
                        ...state.modules.anothers,
                        data: {
                            ...state.modules.anothers.data,
                            ...action.anothers
                        }
                    }
                }
            };
        default:
            return state;
    }
};

const groupByCategoria = (data) => {
    const result = data.reduce((reduce, item) => {
        if (!reduce[item.categoriaCodigo]) {
            reduce[item.categoriaCodigo] = {
                cOrdem: item.c_ordem,
                categoriaCodigo: item.categoriaCodigo,
                categoriaNome: item.categoriaNome,
                'eComplemento': item.eComplemento,
                categoriaOculto: item.categoriaOculto,
                produtos: []
            };
        }

        if (item.categoriaCodigo === reduce[item.categoriaCodigo].categoriaCodigo) {
            item.qtdSelected = 0;
            reduce[item.categoriaCodigo].produtos.push(item);
        }
        return reduce;
    }, []);

    const resultValues = Object.values(result);
    return helpers.collection().orderBy(resultValues, ['cOrdem'], ['asc']);
};

const hydrateData = (data) => {
    data = groupByCategoria(data);

    /**
     * Remove as categorias "pedeComplementos" que não tem complementos para exibir
     * provavelmente não cadastrados ou pausados
     */
    data.map(item => {
        item.produtos.map(produto => {
            if (produto.pedeComplemento === 'S' && !!produto.cComplemento) {
                const complementos = helpers.collection().filter(data, {categoriaCodigo: produto.cComplemento});
                if (complementos.length === 0) {
                    helpers.collection().remove(data, {categoriaCodigo: produto.categoriaCodigo});
                }
            }
        });
    });
    return data;
};

const hydrateEstabelecimentoData = (data) => {
    const formasPagamento = (formas) => {
        const tipos = {
            dinheiro: null,
            maquineta: null,
            online: null
        };

        if (helpers.isDeliveryAzul()) {
            data.cor1 = 'blue';
            data.cor2 = 'blue';
            data.cor3 = 'blue';
        }

        if (!!formas && formas.length > 0) {
            formas.map(forma => {
                const values = {
                    cod: forma.cod,
                    descricao: forma.descricao,
                };
                switch (forma.descricao) {
                    case "dinheiro":
                        values.texto = 'Dinheiro';
                        values.forma_nome = 'DINHEIRO';
                        tipos.dinheiro = values;
                        break;
                    case "cartao_credito":
                        values.texto = 'Cartão de Crédito';
                        values.forma_nome = 'MAQUINETA CREDITO';
                        if (!tipos.maquineta) {
                            tipos.maquineta = [];
                        }
                        tipos.maquineta.push(values);
                        break;
                    case "cartao_debito":
                        values.texto = 'Cartão de Débito';
                        values.forma_nome = 'MAQUINETA DEBITO';
                        if (!tipos.maquineta) {
                            tipos.maquineta = [];
                        }
                        tipos.maquineta.push(values);
                        break;
                    case "online":
                        values.texto = 'Online';
                        values.forma_nome = 'CARTAO ONLINE';
                        tipos.online = values;
                        break;
                    default:
                        break;
                }
                return true;
            });
        }
        return tipos;
    };

    const tipoPagamentoAceite = (formas) => {
        const tiposAceites = {
            dinheiro: null,
            maquinetaCredito: null,
            maquinetaDebito: null,
            online: null
        };

        if (!!formas && formas.length > 0) {
            formas.map(forma => {
                switch (forma.descricao) {
                    case "dinheiro":
                        tiposAceites.dinheiro = true;
                        break;
                    case "cartao_credito":
                        tiposAceites.maquinetaCredito = true;
                        break;
                    case "cartao_debito":
                        tiposAceites.maquinetaDebito = true;
                        break;
                    case "online":
                        tiposAceites.online = true;
                        break;
                    default:
                        break;
                }
                return true;
            });
        }
        return tiposAceites;
    }

    const estabelecimentoAberto = (horario) => {
        return (horario.length === 0) ||
            (helpers.moment(horario[0].hora_abertura, 'HH:mm:ss', true).isBefore(helpers.moment()) &&
                helpers.moment(horario[0].hora_fechamento, 'HH:mm:ss', true).isAfter(helpers.moment()));
    };

    const horarioFuncionamento = (horario) => {
        if (!!horario && horario.length > 0) {
            horario[0].aberto = estabelecimentoAberto(horario);
        }
        return horario;
    };

    data.tipoPagAceito = tipoPagamentoAceite(data.formas);
    data.formas = formasPagamento(data.formas);
    data.horario = horarioFuncionamento(data.horario);

    return data;
};

const hydrateFormasPagamentoData = (data) => {
    let formasPagamento = [];
    if (!!data.estabelecimento.formas.maquineta) {
        data.formasPagamento.map(forma => {
            /**
             * DÉBITO = 30
             * CRÉDITO = 3
             */
            let newForm = {
                cod: 30,
                cod_tipo_catoes: forma.id,
                descricao: "cartao_debito",
                texto: forma.descricao,
                forma_tipo: forma.tipo,
                forma_nome: `${forma.descricao} MAQUINETA DEBITO`,
                arquivo: forma.arquivo,
                checked: false
            };
            if (forma.tipo === 'CRÉDITO') {
                newForm = {
                    cod: 3,
                    cod_tipo_catoes: forma.id,
                    descricao: "cartao_credito",
                    texto: forma.descricao,
                    forma_tipo: forma.tipo,
                    forma_nome: `${forma.descricao} MAQUINETA CREDITO`,
                    arquivo: forma.arquivo,
                    checked: false
                };
            }
            formasPagamento.push(newForm);
        });
        data.estabelecimento.formas.maquineta = formasPagamento;
    }
    return data.estabelecimento
};

export default reducerStore;
;
